import React from "react";

export const InputBoxComponent = ({leadingIcon, value, placeholder, type = 'text', onChange}) => {
    return (
        <div className="inputbox-component input-box">
            <div className="inputbox-container input-box">
                {
                    leadingIcon && (
                        <div style={{ fill: "rgb(197, 203, 215)" }}>
                            <div dangerouslySetInnerHTML={{ __html: leadingIcon }}></div>
                        </div>
                    )
                }
                <input type={type} 
                    className="search-text " 
                    placeholder={placeholder} 
                    value={value}  
                    onChange={(e) => onChange(e.target.value)} />
            </div>
            <div className="under-dot inputbox-bg"></div>
        </div>
    )
}