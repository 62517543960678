import React, { useEffect, useState } from "react";
import './style.scss';
import logo from 'src/Assets/Images/Home/logo.svg';
import close from 'src/Assets/Images/Home/Close.svg';
import menu from 'src/Assets/Images/Home/HeadMenu.svg';
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideMenu } from "src/Redux/Actions";

export default function HeaderComponent({onAction, showLogin }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [transform, setTransform] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    const {
        sideMenuOpen
    } = useSelector(state => state.Home)

    const handleScroll = () => {
        setTransform(window.scrollY > 0 ? true : false)
    }

    return (
        <div class={`header-component ${transform ? 'active' : ''}`}>
            <div class="container normal-container">
                <ReactSVG src={logo} className="logo" onClick={() => navigate('/')}/>
                <div className="signin-btn" onClick={() => onAction()}>{ showLogin ? "Register" : "Continue" }</div>
                <img className="menu" src={sideMenuOpen ? close : menu } onClick={() => dispatch(toggleSideMenu(!sideMenuOpen))}/>
            </div>
        </div>
    )
}