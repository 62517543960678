import React, { useState } from "react";
import Select from 'react-select';

export default function SelectComponent({value, options, multiple=false, searchable=true, onChange, placeholder = "", disabled = false, showLabel = false}) {
  
    return (
        <>
          { showLabel && <label className="input-comp-label">{placeholder}</label>}
          <Select
            defaultValue={value}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            isSearchable={searchable}
            isMulti={multiple}
            isDisabled={disabled}
            className="custom-select"
          />
        </>
    );
  }