import React from "react";
import './style.scss';
import logo from 'src/Assets/Images/Home/logo.svg';
import { footerSocial } from "src/Constant";
import footerCall from 'src/Assets/Images/Home/FooterCall.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleCalendlyModal } from "src/Redux/Actions";

export default function Footer() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const excludePaths = [
        '/virtual-medical-receptionist', 
        '/virtual-dental-receptionist', 
        '/virtual-medical-billing', 
        '/virtual-medical-coder', 
        '/virtual-mental-health-assistant',
        '/remote-patient-monitoring-assistant',
        '/telehealth-medical-assistant',
        '/telephone-triage-medical-assistant',
        '/virtual-patient-care-coordinator',
        '/remote-mds-coordinator',
        '/remote-clinical-chat-auditor',
        '/virtual-dental-assistant',
        '/complete-profile'
    ]
    const showClass = () => excludePaths.includes(location.pathname) ? 'd-none' : ''

    return (
        <div className={`footer-wrapper ${showClass()}`}>
            <div className="footer">
                <div className="container">
                    <div className="row row-sm">
                        <div className="col-lg-4 col-md-6 left-sec">
                            <div className="social-wrapper">
                                <img src={logo} className="logo" onClick={() => navigate('/')}/>
                                <div className="social-icons">
                                    {
                                        footerSocial.map((item, index) => (
                                            <img src={item.icon} key={index} onClick={() => window.open(item.url, '_blank', 'noopener,noreferrer')}/>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="menu-wrap">
                                <div className="menu-title">about us:</div>
                                <div className="menu-sec">
                                    <div className="">
                                        <div className="menu-item" onClick={() => navigate('/story')}>Our Story</div>
                                        <div className="menu-item" onClick={() => navigate('/about/#team')}>Team</div>
                                        <div className="menu-item" onClick={() => navigate('/about/#mission')}>Mission</div>
                                        <div className="menu-item" onClick={() => navigate('/vision')}>Vision</div>
                                        <div className="menu-item" onClick={() => navigate('/vision/#values')}>Values</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 middle-sec">
                            <div className="menu-title">services:</div>
                            <div className="menu-flex">
                                <div className="menu-sec">
                                    <div className="">
                                        <div className="menu-item" onClick={() => navigate('/virtual-medical-receptionist')}>Virtual Medical Receptionist</div>
                                        <div className="menu-item" onClick={() => navigate('/virtual-medical-scribe')}>Virtual Medical Scribe</div>
                                        <div className="menu-item" onClick={() => navigate('/virtual-medical-billing')}>Virtual Medical Biller</div>
                                        <div className="menu-item" onClick={() => navigate('/virtual-medical-coder')}>Virtual Medical Coder</div>
                                        <div className="menu-item" onClick={() => navigate('/remote-mds-coordinator')}>Virtual MDS Coordinator</div>
                                    </div>
                                </div>
                                <div className="menu-sec">
                                    <div className="">
                                        <div className="menu-item" onClick={() => navigate('/virtual-mental-health-assistant')}>Virtual Mental Health Assistant</div>
                                        <div className="menu-item" onClick={() => navigate('/remote-patient-monitoring-assistant')}>Remote Patient Monitoring Assistant</div>
                                        <div className="menu-item" onClick={() => navigate('/telehealth-medical-assistant')}>Telehealth Medical Assistant</div>
                                        <div className="menu-item" onClick={() => navigate('/telephone-triage-medical-assistant')}>Telephone Triage Medical Assistant</div>
                                        <div className="menu-item" onClick={() => navigate('/virtual-dental-assistant')}>Virtual Dental Assistant</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 right-sec">
                            <div className="menu-flex">
                                <div className="item">
                                    <div className="menu-title">resources:</div>
                                    <div className="menu-sec">
                                        <div>
                                            <div className="menu-item" onClick={() => navigate('/contact-us')}>Contact Us</div>
                                            {/* <div className="menu-item" onClick={() => navigate('/blog')}>Blog</div> */}
                                            <div className="menu-item" onClick={() => navigate('/faqs')}>FAQs</div>
                                            <div className="menu-item" onClick={() => navigate('/services')}>Services</div>
                                            <div className="menu-item" onClick={() => navigate('/fulfillment-policy')}>Fulfillment Policy</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="menu-title last-title">Schedule Discovery Call</div>
                                    <div className="btn btn-orange" onClick={() => navigate('/va/request-VA')}>Schedule</div>
                                    <div className="apply">Join our Team: <span onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfucq_29cMbGMKsvlYFBcuzrZFoJ4hs5Q2K8Iai1KPJS0b1AA/viewform", '_blank')?.focus()}>Apply NOW</span> </div>
                                    <div className="phone-wrap">
                                        <img src={footerCall}/>
                                        <div>817 420-7608</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="content">
                        <div className="" onClick={() => navigate('/terms-of-service')}>Terms of service</div>
                        <div className="" onClick={() => navigate('/privacy-policy')}>Privacy</div>
                    </div>
                </div>
            </div>
        </div>
    )
}