
import { 
    TOGGLE_SIDE_MENU,
    GET_BLOGS,
    GET_BLOG,
    GET_TOPICS,
    TOGGLE_CALENDLY_MODAL,
    SUBMIT_CONTACT,
    GET_TOPIC_BLOGS,
    TOGGLE_INTERVIEW_MODAL,
    REQUEST_INTERVIEW,
    TOGGLE_VIDEO_MODAL,
    TOGGLE_REVIEW_MODAL,
    SET_OPEN_REVIEW,
    REQUEST_VA,
} from "../Type";


export const toggleSideMenu = (isOpen) => {
    return {
        type: TOGGLE_SIDE_MENU,
        data: { isOpen }
    }
}

export const getBlogsAction = () => {
    return {
        type: GET_BLOGS,
        data: {}
    }
}

export const getBlogAction = (blog_id) => {
    return {
        type: GET_BLOG,
        blog_id
    }
}

export const getTopicBlogsAction = (categoryId) => {
    return {
        type: GET_TOPIC_BLOGS,
        categoryId
    }
}

export const getTopicsAction = () => {
    return {
        type: GET_TOPICS,
        data: {}
    }
}

export const toggleCalendlyModal = (isOpen) => {
    return {
        type: TOGGLE_CALENDLY_MODAL,
        data: { isOpen }
    }
}

export const toggleInterviewModal = (isOpen) => {
    return {
        type: TOGGLE_INTERVIEW_MODAL,
        data: { isOpen }
    }
}

export const requestInterviewAction = (data) => {
    return {
        type: REQUEST_INTERVIEW,
        data
    }
}

export const toggleVideoModal = (data) => {
    return {
        type: TOGGLE_VIDEO_MODAL,
        data: data
    }
}

export const toggleReviewModal = (isOpen) => {
    return {
        type: TOGGLE_REVIEW_MODAL,
        data: { isOpen }
    }
}

export const setOpenReview = (review) => {
    return {
        type: SET_OPEN_REVIEW,
        data: review
    }
}

export const requestVAAction = (data) => {
    return {
        type: REQUEST_VA,
        data: data
    }
}