import { Api } from "./Api";

export function* savePersonalInfo(data) {
    const jsonData = yield Api.formPost("/personal_info", data)
    return jsonData
}

export function* register(data) {
    const jsonData = yield Api.formPost("/register", data)
    return jsonData
}
export function* login(data) {
    const jsonData = yield Api.formPost("/login", data)
    return jsonData
}
export function* updateProfile(data) {
    const jsonData = yield Api.formPost("/update_profile", data)
    return jsonData
}
export function* getUser() {
    const jsonData = yield Api.get("/profile")
    return jsonData
}
export function* getProfile() {
    const jsonData = yield Api.get("/profile_content")
    return jsonData
}
export function* saveExperience(data) {
    const jsonData = yield Api.formPost("/experience", data)
    return jsonData
}
export function* editExperience(id, data) {
    const jsonData = yield Api.formPost(`/experience/${id}/edit`, data)
    return jsonData
}
export function* saveCertificates(data) {
    const jsonData = yield Api.formPost("/qualifications", data)
    return jsonData
}
export function* editCertificates(id, data) {
    const jsonData = yield Api.formPost(`/qualifications/${id}/edit`, data)
    return jsonData
}
export function* saveSkills(data) {
    const jsonData = yield Api.formPost("/user_skills", data)
    return jsonData
}
export function* editSkills(id, data) {
    const jsonData = yield Api.formPost(`/user_skills/${id}/edit`, data)
    return jsonData
}
export function* saveTags(data) {
    const jsonData = yield Api.formPost("/tags", data)
    return jsonData
}
export function* saveTools(data) {
    const jsonData = yield Api.formPost("/tools", data)
    return jsonData
}
export function* deleteContent(id, data) {
    const jsonData = yield Api.formPost(`/profile_content/${id}/delete`, data)
    return jsonData
}
export function* getTestQuestions() {
    const jsonData = yield Api.get("/test_questions")
    return jsonData
}
export function* getQuizQuestions() {
    const jsonData = yield Api.get("/orientation_questions")
    return jsonData
}
export function* getLocations() {
    const jsonData = yield Api.get("/get_locations")
    return jsonData
}
export function* getJobs() {
    const jsonData = yield Api.get("/jobs")
    return jsonData
}