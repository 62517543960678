import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SelectComponent } from "src/Components/Common";
import { saveTagsAction } from "src/Redux/Actions";
import { showSpinner } from "src/Utils/Helper";

export const RolesComponent = ({profile}) => {
    const dispatch = useDispatch()
    const [tags, setTags] = useState([]);
    useEffect(() => {
        setTags(profile.user_tags.map((item) => {return { value: item.id, label: item.name }}))
    }, [])

    const saveTools = () => {
        if(tags.length === 0) {
            toast("No Roles selected")
            return;
        }
        const formdata = new FormData()
        formdata.append("tags", tags.map(item => item.value).join(","))
        showSpinner()
        dispatch(saveTagsAction(formdata))
    } 
    
    return (
        <div className="item-list">
            <div className="sec-title">What types of jobs would you like us to match you with. You can select more than one.</div>
            {/* <div className="btn-row">
                <div className="add-btn" ><span className="fa fa-plus me-2"></span>Add Skill</div>
            </div> */}
            <div className="form-group">
                <SelectComponent key={tags} value={tags} options={profile.tags.map((item) => {return { value: item.id, label: item.name}})} onChange={(value) => setTags(value)} multiple={true} searchable={true}/>
            </div>
            <div className="modal-btns mt-5 d-f j-c">
                <div className="save-btn center-item" onClick={() => saveTools()}>Save</div>
            </div>
        </div>
    )
}