import React, { useState } from "react";

export default function InputComponent({label, value, placeholder, onChange, type = "text", className = '', showLabel = false }) {
    const [isFocused, setIsFocused] = useState(false)
    return (
        <>
            { showLabel && <label className="input-comp-label">{label}</label>}
            <div className={`input-component ${isFocused ? 'active': ''} ${className}`}>
                { (isFocused || value && value.length > 0) && <div className="focus-label">{label}</div>}
                <input type={type}
                    className="form-control" 
                    placeholder={isFocused ? placeholder : label} 
                    value={value} 
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={e => onChange(e.target.value)}/>
            </div>
        </>
    )
}