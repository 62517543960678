import { useEffect, useState } from "react"
import { InputComponent } from "src/Components/Common"
import SubmitArea from "../SubmitArea"
import { useDispatch, useSelector } from "react-redux"
import { hideSpinner, showSpinner } from "src/Utils/Helper"
import { updateProfileAction } from "src/Redux/Actions"
import { toast } from "react-toastify"

export default function RemoteWorkReadiness({user}) {
    const dispatch = useDispatch()
    const [url, setUrl] = useState(user.remote_work_url)

    const btnDisabled = user.remote_work_url && user.remote_work_url.length > 0
 
     const {
         updateProfile_loading,
         updateProfile_success
     } = useSelector(state => state.Auth)
 
     useEffect(() => {
         if(!updateProfile_loading)hideSpinner()
     }, [dispatch, updateProfile_loading, updateProfile_success])
 
     const handleSubmit = () => {
         if(url === '') {
             toast("Please provide work readiness link")
             return
         }
         showSpinner()
         const formData = new FormData()
         formData.append("remote_work_url", url)
         formData.append("action", "remote_work_url")
         dispatch(updateProfileAction(formData))
     }

    return (
        <div className="remote-work">
            <h1>Work from Home Readiness</h1>
            <p className="mt-5">To ensure you are prepared for a remote work environment, please submit the following:</p>
            <ul style={{listStyleType:"decimal"}}>
                <li>A photo of your home workspace.</li>
                <li>A screenshot of your internet speed.</li>
                <li>A screenshot of your computer specifications.</li>
                <li>A photo showing your backup power supply and internet setup.</li>
                <li>A photo of your work computer</li>
                <li>Front and back of government issued ID Card or Passport for Identity verification</li>
            </ul>
            <div style={{fontWeight:"bold",marginTop:"3rem"}}>Note:</div>
            <p>Upload all documents to a google drive or dropbox folder and submit the link to the folder below. </p>
            <div className="mt-5 mb-2" style={{fontSize:"20px",fontWeight:"bold"}}>Enter folder link here</div>
            <InputComponent value={url} onChange={(value) => setUrl(value)} />
            <div className="d-f j-c w-100 my-5">
                <div className={`save-btn center-item ${url === '' || url === null ? 'disabled': ''}`} onClick={() => handleSubmit()}>Submit Work Readiness</div>
            </div>
            <SubmitArea disabled={!btnDisabled} />
        </div>
    )
}