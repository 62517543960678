import React from "react";
import SubmitArea from "../SubmitArea";

export default function DigitalProfile({user}) {

    return (
        <div className="digital-profile">
            <h1>Digital Profile</h1>
            <p className="mt-5">Access your complete digital profile using the link below. This profile highlights your qualifications and will be shared with potential clients for their review. Make sure it accurately represents your skills and experience.</p>
            <div className="btn-wrapper d-f j-c f-c a-c" style={{minHeight: "60vh"}}>
                <div className="save-btn center" onClick={() => window.open(`https://honesttaskers.com/profile/${user.id}`, '_blank')?.focus()}>View Digital Profile</div>
            </div>
            <SubmitArea disabled={true}/>
        </div>
    )
}