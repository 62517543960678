import React, { useEffect } from "react";
import './style.scss';
import { AddExperienceModal, ExperienceComponent } from "./Experience";
import { useDispatch, useSelector } from "react-redux";
import { deleteContentAction, toggleCertificationModalAction, toggleExperienceModalAction, toggleSkillModalAction } from "src/Redux/Actions";
import { AddCertificationModal, CertificationComponent } from "./Certificates";
import { AddSkillModal, SkillsComponent } from "./Skills";
import { ToolsComponent } from "./Tools";
import { RolesComponent } from "./Roles";
import SubmitArea from "../SubmitArea";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import Swal from "sweetalert2";
import _ from 'lodash';

export default function Qualification({currentTab, setCurrentTab}) {
    const tabs = ['Experience', 'Certifications', 'Skills', 'Tools', 'Roles'];
    // const [currentTab, setCurrentTab] = useState(tabs[0])
    const dispatch = useDispatch()
    const {
        showExperienceModal,
        showCertificationModal,
        showSkillModal,
        profile,
        experience,
        certificate,
        skill,
        saveExperience_loading,
        saveExperience_success,
        saveCertificate_loading,
        saveCertificate_success,
        deleteContent_loading,
        deleteContent_success,
        saveSkill_loading, 
        saveSkill_success,
        saveTools_loading,
        saveTools_success,
        saveTags_loading,
        saveTags_success,
    } = useSelector(state => state.Auth)

    useEffect(() => {
        if(!saveExperience_loading)hideSpinner()
        if(saveExperience_success) {
            sessionStorage.setItem("profile", JSON.stringify(profile))
        }
    }, [dispatch, saveExperience_loading, saveExperience_success])
    useEffect(() => {
        if(!saveCertificate_loading)hideSpinner()
        if(saveCertificate_success) {
            sessionStorage.setItem("profile", JSON.stringify(profile))
        }
    }, [dispatch, saveCertificate_loading, saveCertificate_success])
    useEffect(() => {
        if(!deleteContent_loading)hideSpinner()
        if(deleteContent_success) {
            console.log("Item deleted")
            sessionStorage.setItem("profile", JSON.stringify(profile))
        }
    }, [dispatch, deleteContent_loading, deleteContent_success])
    useEffect(() => {
        if(!saveSkill_loading)hideSpinner()
        if(saveSkill_success) {
            sessionStorage.setItem("profile", JSON.stringify(profile))
        }
    }, [dispatch, saveSkill_loading, saveSkill_success])
    useEffect(() => {
        if(!saveTools_loading)hideSpinner()
        if(saveTools_success) {
            sessionStorage.setItem("profile", JSON.stringify(profile))
        }
    }, [dispatch, saveTools_loading, saveTools_success])
    useEffect(() => {
        if(!saveTags_loading)hideSpinner()
        if(saveTags_success) {
            sessionStorage.setItem("profile", JSON.stringify(profile))
        }
    }, [dispatch, saveTags_loading, saveTags_success])

    const nextDisabled = () => {
        return profile.experience.length === 0 || profile.user_skills.length === 0
        || profile.user_tools.length === 0 || profile.user_tags.length === 0
    }
    return (
        <div className="qualification">
            <div className="tab-wrapper">
                <div className="tabs">
                    {
                        tabs.map((item, index) => (
                            <div className={`tab-item ${currentTab == item ? 'active' : ''}`} key={index} onClick={() => setCurrentTab(item)}>{item}</div>
                        ))
                    }
                </div>
            </div>
            <div className="tab-contents">
                <TabContents currentTab={currentTab} profile={profile}/>
                <AddExperienceModal 
                    key={experience ?? "experience"}
                    show={showExperienceModal} 
                    experience={experience}
                    loading={saveExperience_loading}
                    onHide={() => dispatch(toggleExperienceModalAction(false))} />
                <AddCertificationModal 
                    key={certificate ?? "cert"}
                    show={showCertificationModal} 
                    certificate={certificate}
                    loading={saveCertificate_loading}
                    onHide={() => dispatch(toggleCertificationModalAction(false))}/>
                <AddSkillModal 
                    key={skill ?? "skill"}
                    show={showSkillModal} 
                    onHide={() => dispatch(toggleSkillModalAction(false))} 
                    currentSkill={skill}
                    loading={saveSkill_loading}
                    profile={profile} />
            </div>
            <SubmitArea disabled={nextDisabled()}/>
        </div>
    )
}

const TabContents = ({currentTab, profile}) => {
    const dispatch = useDispatch()
    const handleDelete = (item, type) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You're about to delete item with title: ${type === 'skill' ? item.skill.title : item.title }?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.isConfirmed) {
                showSpinner()
                const formdata = new FormData()
                formdata.append('type', type)
                dispatch(deleteContentAction(item.id, formdata))
            //   Swal.fire("Deleted!", "Your item has been deleted.", "success");
            }
          });
    }
    switch (currentTab) {
        case 'Experience':
            return (<ExperienceComponent profile={profile} onDelete={(item) => handleDelete(item, 'experience')}/>)
        case 'Certifications':
            return (<CertificationComponent profile={profile} onDelete={(item) => handleDelete(item, 'certification')}/>)
        case 'Skills':
            return (<SkillsComponent profile={profile} onDelete={(item) => handleDelete(item, 'skill')}/>)
        case 'Tools':
            return (<ToolsComponent profile={profile}/>)
        case 'Roles':
            return (<RolesComponent profile={profile}/>)
        default: 
            return (<ExperienceComponent />)
    }
}