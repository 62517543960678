import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { TextEditor } from "src/Components/Common";
import { editCertificatesAction, saveCertificatesAction, toggleCertificationModalAction } from "src/Redux/Actions";
import { showSpinner } from "src/Utils/Helper";
import _ from 'lodash';
import { SET_CERTIFICATE } from "src/Redux/Type";
import { toast } from "react-toastify";

export const CertificationItem = ({item, onEdit, onDelete }) => {
    return (
        <div className="cert-item q-item">
            <div className="item-header">
                <div className="info">
                    <div className="title">{item.title}</div>
                    <div className="company">{item.company.name}</div>
                </div>
                <div className="action-btns">
                    <div className="item-btn center-item orange" onClick={() => window.open(item.url, '_blank')?.focus()}>View Certification</div>
                    <div className="item-btn center-item" onClick={() => onEdit()}>Edit</div>
                    <div className="item-btn center-item danger" onClick={() => onDelete()}>Delete</div>
                    {/* <div className="item-btn danger center-item">Delete</div> */}
                </div>
            </div>
            <p className="description" dangerouslySetInnerHTML={{__html: item.description}}></p>
        </div>
    )
}

export const CertificationComponent = ({profile, onDelete }) => {
    const dispatch = useDispatch()
    const handleEdit = (item) => {
        dispatch({
            type: SET_CERTIFICATE,
            data: item
        })
    }
    return (
        <div className="item-list">
            <div className="sec-title">List all your certifications and training</div>
            <div className="btn-row">
                <div className="add-btn" onClick={() => dispatch(toggleCertificationModalAction(true))}><span className="fa fa-plus me-2"></span>Add Certification</div>
            </div>
            {
                profile.certificates.map((item, index) => (
                    <CertificationItem 
                        key={index} 
                        item={item} 
                        onDelete={() => onDelete(item)}
                        onEdit={() => handleEdit(item)}/>
                ))
            }
            {
                profile.certificates.length === 0 && <div style={{padding:"3rem 0",textAlign:"center"}}>No Certifications</div>
            }
        </div>
    )
}

export const AddCertificationModal = ({show, onHide, certificate = null, loading = false }) => {
    const dispatch = useDispatch()
    const editorRef = useRef(null);
    const [title, setTitle] = useState('')
    const [company, setCompany] = useState('')
    const [description, setDescription] = useState('')
    const [url, setUrl] = useState('')

    useEffect(() => {
        if(certificate && certificate.id) {
            setTitle(certificate.title)
            setCompany(certificate.company.name)
            setDescription(certificate.description)
            setUrl(certificate.url)
        }
    }, [])
    const buttonDisabled = title === '' || company === ''
    const submit = () => {
        if((editorRef.current && editorRef.current.getContent() === '')) {
            toast("Enter description")
            return
        }
        const formdata = new FormData()
        formdata.append("title", title)
        formdata.append("company_name", company)
        formdata.append('description', editorRef.current.getContent())
        formdata.append('url', url)
        showSpinner()
        if(_.isEmpty(certificate)) {
            dispatch(saveCertificatesAction(formdata))
        } else {
            dispatch(editCertificatesAction(certificate.id, formdata))
        }
    }

    const placeholder = `<div style="color:#8F9098;">e.g<br> <p>Introduction to Medical Billing: Understanding the role of a medical biller, healthcare reimbursement processes, and the billing cycle.</p> <p>Healthcare Insurance Basics: Learning about different insurance plans, including private insurance, Medicare, Medicaid, and HMOs/PPOs.</p>
        <p>Medical Coding Fundamentals: Overview of ICD-10, CPT, and HCPCS codes and their role in accurate billing and claims processing.</p>
        <p>Claim Submission & Processing: Step-by-step guidance on how to generate, submit, and track medical claims using billing software.</p>
        <p>Insurance Verification & Prior Authorizations: Learning how to verify patient insurance coverage and obtain necessary approvals for procedures.</p>
        <p>Denials & Appeals Management: Understanding common reasons for claim denials and how to effectively appeal rejected claims.</p>
       <p>HIPAA & Compliance Regulations: Ensuring compliance with patient privacy laws and ethical billing practices.</p>
        <p>Revenue Cycle Management: Learning about the full financial process from patient registration to final payment collection.</p>
        <p>Medical Billing Software Training: Hands-on experience with popular medical billing platforms like Kareo, Athenahealth, or Practice Fusion (if covered in the course).</p>
        <p>Patient Billing & Collections: Handling patient invoices, payment posting, and managing outstanding balances professionally.</p></div>`

    return (
        <Modal className="cert-modal modal-lg" show={show} onHide={onHide}>
            <Modal.Header>
                <div className="modal-title">Add Certification</div>
                <div className="close" onClick={onHide}>Close</div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper row">
                    <div className="form-group group-2 col-lg-6">
                        <label>Certification Title</label>
                        <input type="text" className="form-control" placeholder="e.g Medical Billing" value={title} onChange={(e) => setTitle(e.target.value)}/>
                    </div>
                    <div className="form-group group-2 col-lg-6">
                        <label>Organization</label>
                        <input type="text" className="form-control" placeholder="e.g Udemy" value={company} onChange={(e) => setCompany(e.target.value)}/>
                    </div>
                    <div className="form-group group-2 col-lg-12">
                        <label>Certification link</label>
                        <input type="text" className="form-control" placeholder="www.dropbox.com/billingcertificateofcompletion" value={url} onChange={(e) => setUrl(e.target.value)}/>
                    </div>
                    <div className="form-group group-2">
                        <label>Description</label>
                        <TextEditor editorRef={editorRef} value={description} placeholder={placeholder} showPlaceholder={_.isEmpty(certificate)}/>
                    </div>
                    <div className="modal-btns">
                        <div className={`submit-btn center-item ${buttonDisabled || loading ? 'disabled': ''}`} onClick={() => submit()}>Save</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}