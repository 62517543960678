import React from "react";
import { Benefits, FAQs, Footer2, GetStarted2, LandingBottom, Pricing, RCChatAuditorHomeIntro, RCChatAuditorIntroduction, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function RCChatHomepage() {
    const navigate = useNavigate()

    const benefits = [
        {
            title: "Precision at its Best:",
            message: "Achieve unmatched accuracy in all your clinical chat communications.",
            color: "green"
        },
        {
            title: "Enhance Compliance:",
            message: "Stay confidently aligned with regulatory and clinical guidelines.",
            color: "green"
        },
        {
            title: "Resource Saving:",
            message: "Avoid potential pitfalls and reduce overheads with our remote audit solutions.",
            color: "green"
        },
        {
            title: "Quality Control:",
            message: "Ensure every patient interaction upholds your facility's reputation for excellence.",
            color: "green"
        },
        {
            title: "Feedback and Improvement:",
            message: "Get actionable insights to continuously enhance the quality of your digital communications.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: "Audit Excellence:",
            message: "Regularly review and audit clinical chat logs for accuracy and compliance."
        },
        {
            title: "Collaboration:",
            message: "Work closely with healthcare professionals to provide feedback and training opportunities."
        },
        {
            title: "Maintain Updates:",
            message: "Keep abreast of the latest clinical communication standards and protocols."
        },
        {
            title: "Reporting:",
            message: "Generate and present clear reports on audit findings and areas of improvement."
        },
        {
            title: "Data Protection:",
            message: "Uphold the highest standards of patient data confidentiality and security during audits."
        }
    ]

    const whyChooseList = [
        {
            title: "Industry Leaders:",
            message: "Trust in our auditors, steeped in deep clinical chat knowledge."
        },
        {
            title: "Adaptive Integration:",
            message: "Our auditors seamlessly align with your systems, ensuring fluid operations."
        },
        {
            title: "Consistency is Key:",
            message: "Our service quality is unwavering, marked by our impressively low staffing turnover."
        },
        {
            title: "Continuous Growth:",
            message: "At HonestTaskers, learning never stops. Regular training ensures our auditors are always a step ahead."
        },
        {
            title: "24/7 Support:",
            message: "Our commitment extends beyond audits. We're here for consultations, support, and more."
        }
    ]

    const faqs = [
        {
            question: "How do your auditors adapt to our specific clinical chat platform?",
            answer: "Through an extensive onboarding process, our auditors familiarize themselves with your platform, ensuring flawless integration."
        },
        {
            question: "Are your audit methodologies aligned with current clinical guidelines?",
            answer: "Undoubtedly. We prioritize staying updated with the latest clinical guidelines and weave them into our audit processes."
        },
        {
            question: "How do you guarantee the confidentiality of our chat data?",
            answer: "Patient data integrity is our topmost priority. Our auditors adhere to strict confidentiality and data protection protocols."
        },
        {
            question: "Can we get periodic training based on the audit insights?",
            answer: "Absolutely. We believe in constructive feedback and are more than willing to provide training sessions for continuous improvement."
        }
    ]

    const meta_title = "Remote Clinical Chat Auditors | Save 60% with Honest Taskers"
    const meta_description = "Enhance your healthcare communication with Honest Taskers’ Remote Clinical Chat Auditors. Achieve meticulous accuracy and save over 60% on your auditing costs. Contact us now"
    const meta_image = "https://honesttaskers.com/vms_image.png"

    return (
        <div className="rc-chat-homepage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/remote-clinical-chat-auditor"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <RCChatAuditorHomeIntro onGetStarted={() => navigate('/va/request-VA')}/>
            <LandingBottom hour="$12/hr"/>
            <RCChatAuditorIntroduction />
            <Benefits subTitle={"of Remote Clinical Chat Auditor"} benefits={benefits}/>
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities:"}/>
            <WhyChooseUs subTitle={"HonestTaskers' Remote Clinical Chat Auditors?"} whyChoose={whyChooseList}/>
            <FAQs faqs={faqs}/>
            <Pricing hasColor={true} plans={getPricingPlans('no-custom', false, true)} btnText="Contact Us For Pricing"/>
            <GetStarted2 title={"Elevate Your Clinical Chats to New Heights of Excellence!"}
                btnText="Begin Your Audit Journey Now!"
                onGetStarted={() => navigate('/va/request-VA')}/>
            <Footer2 />
        </div>
    )
}