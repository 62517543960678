
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';

export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS';
export const GET_BLOGS_FAIL = 'GET_BLOGS_FAIL';

export const GET_BLOG = 'GET_BLOG';
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL';

export const GET_TOPICS = 'GET_TOPICS';
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAIL = 'GET_TOPICS_FAIL';

export const TOGGLE_CALENDLY_MODAL = 'TOGGLE_CALENDLY_MODAL';

export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';
export const SUBMIT_CONTACT_SUCCESS = 'SUBMIT_CONTACT_SUCCESS';
export const SUBMT_CONTACT_FAIL = 'SUBMT_CONTACT_FAIL';
export const SUBMIT_RECEPTION_CONTACT = 'SUBMIT_RECEPTION_CONTACT';

export const TOGGLE_SKILL_MODAL = 'TOGGLE_SKILL_MODAL';

export const GET_VA_PROFILE = 'GET_VA_PROFILE';
export const GET_VA_PROFILE_SUCCESS = 'GET_VA_PROFILE_SUCCESS';
export const GET_VA_PROFILE_FAIL = 'GET_VA_PROFILE_FAIL';

export const GET_TOPIC_BLOGS = 'GET_TOPIC_BLOGS';
export const GET_TOPIC_BLOGS_SUCCESS = 'GET_TOPIC_BLOGS_SUCCESS';
export const GET_TOPIC_BLOGS_FAIL = 'GET_TOPIC_BLOGS_FAIL';

export const TOGGLE_INTERVIEW_MODAL = 'TOGGLE_INTERVIEW_MODAL';

export const REQUEST_INTERVIEW = 'REQUEST_INTERVIEW';
export const REQUEST_INTERVIEW_SUCCESS = 'REQUEST_INTERVIEW_SUCCESS';
export const REQUEST_INTERVIEW_FAIL = 'REQUEST_INTERVIEW_FAIL';

export const TOGGLE_VIDEO_MODAL = 'TOGGLE_VIDEO_MODAL';

export const TOGGLE_PORTAL_SIDE_MENU = 'TOGGLE_PORTAL_SIDE_MENU';

export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';
export const GET_COURSES_FAIL = 'GET_COURSES_FAIL';

export const TOGGLE_EXPERIENCE_MODAL = 'TOGGLE_EXPERIENCE_MODAL';
export const TOGGLE_CERTIFICIATION_MODAL = 'TOGGLE_CERTIFICIATION_MODAL';
export const TOGGLE_SKILL_FORM_MODAL = 'TOGGLE_SKILL_FORM_MODAL';
export const TOGGLE_ATTENTION_TEST_MODAL = 'TOGGLE_ATTENTION_TEST_MODAL';
export const TOGGLE_REVIEW_MODAL = 'TOGGLE_REVIEW_MODAL';

export const SET_OPEN_REVIEW = 'SET_OPEN_REVIEW';

export const SUBMIT_REQUEST_CALL = 'SUBMIT_REQUEST_CALL';
export const SUBMIT_REQUEST_CALL_SUCCESS = 'SUBMIT_REQUEST_CALL_SUCCESS';
export const SUBMIT_REQUEST_CALL_FAIL = 'SUBMIT_REQUEST_CALL_FAIL';


// VA signup
export const UPDATE_SIGNUP_STEP = 'UPDATE_SIGNUP_STEP';

export const REQUEST_VA = 'REQUEST_VA';
export const REQUEST_VA_SUCCESS = 'REQUEST_VA_SUCCESS';
export const REQUEST_VA_FAIL = 'REQUEST_VA_FAIL';

export const SAVE_INFO = 'SAVE_INFO';
export const SAVE_INFO_SUCCESS = 'SAVE_INFO_SUCCESS';
export const SAVE_INFO_FAIL = 'SAVE_INFO_FAIL';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const SET_USER = 'SET_USER';

export const GET_PROFILE_CONTENT = 'GET_PROFILE_CONTENT';
export const GET_PROFILE_CONTENT_SUCCESS = 'GET_PROFILE_CONTENT_SUCCESS';
export const GET_PROFILE_CONTENT_FAIL = 'GET_PROFILE_CONTENT_FAIL';
export const SET_PROFILE = 'SET_PROFILE';

export const SAVE_EXPERIENCE = 'SAVE_EXPERIENCE';
export const SAVE_EXPERIENCE_SUCCESS = 'SAVE_EXPERIENCE_SUCCESS'
export const SAVE_EXPERIENCE_FAIL = 'SAVE_EXPERIENCE_FAIL';
export const SET_EXPERIENCE = 'SET_EXPERIENCE';
export const EDIT_EXPERIENCE = 'EDIT_EXPERIENCE';
export const EDIT_EXPERIENCE_SUCCESS = 'EDIT_EXPERIENCE_SUCCESS';
export const EDIT_EXPERIENCE_FAIL = 'EDIT_EXPERIENCE_FAIL';

export const SAVE_CERTIFICATE = 'SAVE_CERTIFICATE';
export const SAVE_CERTIFICATE_SUCCESS = 'SAVE_CERTIFICATE_SUCCESS';
export const SAVE_CERTIFICATE_FAIL = 'SAVE_CERTIFICATE';
export const SET_CERTIFICATE = 'SET_CERTIFICATE';
export const EDIT_CERTIFICATE = 'EDIT_CERTIFICATE';
export const EDIT_CERTIFICATE_SUCCESS = 'EDIT_CERTIFICATE_SUCCESS';
export const EDIT_CERTIFICATE_FAIL = 'EDIT_CERTIFICATE_FAIL';

export const SAVE_SKILL = 'SAVE_SKILL';
export const SAVE_SKILL_SUCCESS = 'SAVE_SKILL_SUCCESS';
export const SAVE_SKILL_FAIL = 'SAVE_SKILL_FAIL';
export const EDIT_SKILL = 'EDIT_SKILL';
export const EDIT_SKILL_SUCCESS = 'EDIT_SKILL_SUCCESS';
export const EDIT_SKILL_FAIL = 'EDIT_SKILL_FAIL';
export const SET_SKILL = 'SET_SKILL';

export const SAVE_TOOLS = 'SAVE_TOOLS';
export const SAVE_TOOLS_SUCCESS = 'SAVE_TOOLS_SUCCESS';
export const SAVE_TOOLS_FAIL = 'SAVE_TOOLS_FAIL';

export const SAVE_TAGS = 'SAVE_TAGS';
export const SAVE_TAGS_SUCCESS = 'SAVE_TAGS_SUCCESS';
export const SAVE_TAGS_FAIL = 'SAVE_TAGS_FAIL';

export const DELETE_CONTENT = 'DELETE_CONTENT';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAIL = 'DELETE_CONTENT_FAIL';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const GET_TEST_QUESTIONS = 'GET_TEST_QUESTIONS';
export const GET_TEST_QUESTIONS_SUCCESS = 'GET_TEST_QUESTIONS_SUCCESS';
export const SET_QUESTION = 'SET_QUESTION';

export const GET_QUIZ_QUESTIONS = 'GET_QUIZ_QUESTIONS';
export const GET_QUIZ_QUESTIONS_SUCCESS = 'GET_QUIZ_QUESTIONS_SUCCESS';
// export const GET_QUIZ_QUESTIONS_FAIL = 'GET_QUIZ_QUESTIONS_FAIL';
export const TOGGLE_QUIZ_MODAL = 'TOGGLE_QUIZ_MODAL';

export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL';
export const SET_LOCATIONS = 'SET_LOCATIONS';

export const GET_JOBS = 'GET_JOBS';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_JOBS_FAIL = 'GET_JOBS_FAIL';

