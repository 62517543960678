import { AdwordsFAQs, AdwordsHeroSection, AdwordsHowItWorks, AdwordsReviews, AdwordsServices, AdwordsWhyUs, BrandingSection, ClientReviews, FooterBottom, FreeTrialSection, HIPAACompliance, Pricing, RequestDiscoveryCall } from "src/Components";
import CallToAction from "src/Components/Adwords/CallToAction";
import Showcase from "src/Components/Adwords/Showcase";
import { getPricingPlans } from "src/Constant";
import dutyImg1 from 'src/Assets/Images/VmrHome/Duty1.svg';
import dutyImg2 from 'src/Assets/Images/VmrHome/Duty2.svg';
import dutyImg3 from 'src/Assets/Images/VmrHome/Duty3.svg';
import dutyImg4 from 'src/Assets/Images/VmrHome/Duty4.svg';
import feature1 from 'src/Assets/Images/VmsHome/Feature1.svg';
import feature2 from 'src/Assets/Images/VmsHome/Feature2.svg';
import feature3 from 'src/Assets/Images/VmsHome/Feature3.svg';
import feature4 from 'src/Assets/Images/VmsHome/Feature4.svg';
import feature5 from 'src/Assets/Images/VmsHome/Feature5.svg';
import feature6 from 'src/Assets/Images/VmsHome/Feature6.svg';
import storyImg2 from 'src/Assets/Images/Story/Story2.svg';
import storyImg3 from 'src/Assets/Images/Story/Story6.svg';
import storyImg4 from 'src/Assets/Images/Home/FaqHImg.svg';
import storyImg5 from 'src/Assets/Images/Home/WhyHImg.svg';
import policy2 from 'src/Assets/Images/Policy/P2.svg';
import policy3 from 'src/Assets/Images/Policy/P3.svg';
import policy5 from 'src/Assets/Images/Policy/P5.svg';
import policy7 from 'src/Assets/Images/Policy/P7.svg';
import policy8 from 'src/Assets/Images/Policy/P8.svg';
import service1 from 'src/Assets/Images/Services/Services1.svg';
import service2 from 'src/Assets/Images/Services/Services2.svg';
import service3 from 'src/Assets/Images/Services/Services3.svg';
import service4 from 'src/Assets/Images/Services/Services4.svg';
import service5 from 'src/Assets/Images/Services/Services5.svg';
import vision1 from 'src/Assets/Images/Vision/Vision1.svg';
import vision2 from 'src/Assets/Images/Vision/Vision2.svg';


export default function VMS_AdwordsPage() {

    const heroTitle = "Do You Need An Experienced <span>Virtual Medical Scribe</span> To Support Medical Record Documentation or Transcription & Save 70% on Cost? ";
    const howItWorksTitle = "This entire process is streamlined and typically takes <b>1–2 weeks</b>, allowing you to quickly integrate a skilled Virtual Medical Scribe into your team.";
    const requestTitle = "Get More Information About Our Virtual Medical Scribe Staffing Services";
    const requestSubTitle = "We pride ourselves on having a warm and approachable team. You’ll love talking to us as we guide you through finding the perfect Virtual Medical Scribe for your practice!";
    return ( 
        <div className="vdr-landing">
           <AdwordsHeroSection title={heroTitle}/>
           <BrandingSection />
           <AdwordsReviews reviews={reviews} title="Our Medical Practice Owners and Managers Love Us As Much As We Love Them"/>
           <Showcase videoList={3} titleVA="Who Are Our Virtual Medical Scribe?" titleClient="What Our Clients Have To Say About Their Medical VA"/>
           <ClientReviews />
           {/* <CallToAction /> */}
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Scribe Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsWhyUs title={"Why Hire Our Virtual Medical Scribe"} whyusList={whyusList}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Scribe Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <AdwordsServices title={"Ways Our Virtual Medical Scribe Can Support Your Practice"} services={services}/>
           <AdwordsHowItWorks howItWorks={howItWorks} title={howItWorksTitle}/>
           <HIPAACompliance hippas={hippas}/>
           <FreeTrialSection title={"Interested in a Discovery Call for Our Virtual Medical Scribe Services?"} message={"Let us help you find the perfect solution for your practice!"}/>
           <Pricing plans={getPricingPlans('pricing-one')} action="ReqeustVA"/>
           <AdwordsFAQs faqs={faqs}/>
           <RequestDiscoveryCall title={requestTitle} subTitle={requestSubTitle}/>
           <FooterBottom />
        </div>
    )
}

const services = [
    {
        title: "Scheduling and Calendar Management",
        icon: dutyImg1,
        message: "",
        list: [
            "Book, reschedule, and confirm patient appointments.",
            "Send appointment reminders to reduce no-shows."

        ]
    },
    {
        title: "Patient Intake Coordination",
        icon: dutyImg2,
        message: "",
        list: [
            "Collect and organize patient information before appointments.",
            "Verify and ensure the accuracy of intake forms."

        ]
    },
    {
        title: "Insurance Verification and Authorization",
        icon: dutyImg3,
        message: "",
        list: [
            "Verify patient insurance benefits and coverage.",
            "Obtain prior authorizations for therapy sessions or treatments."

        ]
    },
    {
        title: "Billing and Payment Management",
        icon: dutyImg4,
        message: "",
        list: [
            "Generate and send invoices to patients.",
            "Follow up on unpaid balances and set up payment plans."
        ]
    },
    {
        title: "Documentation Support",
        icon: vision1,
        message: "",
        list: [
            "Prepare and update patient records in the EMR system.",
            "Ensure proper documentation of therapy notes and progress updates."
        ]
    },
    {
        title: "Telehealth Support",
        icon: vision2,
        message: "",
        list: [
            "Set up and manage virtual therapy sessions.",
            "Troubleshoot technical issues for both patients and providers."

        ]
    },
    {
        title: "Patient Communication",
        icon: vision2,
        message: "",
        list: [
            "Respond to patient inquiries via phone, email, or chat.",
            "Follow up with patients after sessions for feedback or next steps."
        ]
    },
    {
        title: "Compliance and Confidentiality",
        icon: vision2,
        message: "",
        list: [
            "Ensure all administrative tasks comply with HIPAA regulations.",
            "Maintain the confidentiality and security of patient information."
        ]
    },
    {
        title: "Scribing Services",
        icon: vision2,
        message: "",
        list: [
            "Take detailed notes during therapy sessions to free up the therapist’s time.",
            "Ensure all notes are accurate and compliant with industry standards."
        ]
    },
    {
        title: "Referral Management",
        icon: vision2,
        message: "",
        list: [
            "Coordinate referrals to other healthcare providers or specialists.",
            "Follow up with referred providers to ensure continuity of care."
        ]
    },
    {
        title: "Practice Management Software Assistance",
        icon: vision2,
        message: "",
        list: [
            "Manage EMR systems like TherapyNotes, SimplePractice, or TheraNest.",
            "Create and organize templates for documentation and billing."
        ]
    },
    {
        title: "Client Onboarding",
        icon: vision2,
        message: "",
        list: [
            "Guide new clients through the onboarding process, including form completion and policy explanations.",
            "Address initial questions and set up their accounts in the practice’s system."
        ]
    },
    {
        title: "Social Media and Marketing Support",
        icon: vision2,
        message: "",
        list: [
            "Manage the practice’s social media accounts to improve online visibility.",
            "Create and schedule posts about mental health awareness or practice updates."
        ]
    },
    {
        title: "Google Business Profile Management",
        icon: vision2,
        message: "",
        list: [
            "Update and optimize the practice’s Google Business Profile to attract more clients.",
            "Respond to reviews and inquiries to maintain a positive online presence."
        ]
    },
    {
        title: "Data Entry and Reporting",
        icon: vision2,
        message: "",
        list: [
            "Compile reports on patient trends, appointment metrics, and billing performance.",
            "Track and analyze data to optimize practice operations."
        ]
    },
    {
        title: "Group Therapy Coordination",
        icon: vision2,
        message: "",
        list: [
            "Schedule and manage logistics for group therapy sessions.",
            "Communicate with participants and ensure proper documentation."
        ]
    },
    {
        title: "Follow-Up and Care Coordination",
        icon: vision2,
        message: "",
        list: [
           "Follow up with patients after therapy sessions for additional support.",
            "Coordinate with other healthcare professionals involved in a patient’s care."
        ]
    },
    {
        title: "Prescription Refill Assistance",
        icon: vision2,
        message: "",
        list: [
            "Process requests for prescription refills under the provider’s guidance.",
            "Communicate with pharmacies to ensure timely fulfillment."
        ]
    },
    {
        title: "Administrative Workflow Optimization",
        icon: vision2,
        message: "",
        list: [
            "Streamline practice operations by identifying inefficiencies in administrative workflows.",
            "Propose and implement solutions to save time and reduce costs."
        ]
    },
    {
        title: "Crisis Management Support",
        icon: vision2,
        message: "",
        list: [
            "Help direct patients to appropriate crisis services when necessary.",
            "Document and escalate critical information to the therapist immediately."
        ]
    },
    {
        title: "Insurance Claim Follow-Up",
        icon: vision2,
        message: "",
        list: [
            "Submit claims to insurance providers and address denials or delays.",
            "Ensure timely payment for sessions and services."
        ]
    },
    {
        title: "Email and Communication Management",
        icon: vision2,
        message: "",
        list: [
           "Monitor and organize the practice’s email inbox.",
            "Prioritize urgent messages and respond to routine inquiries."
        ]
    },
    {
        title: "Patient Retention Efforts",
        icon: vision2,
        message: "",
        list: [
            "Reach out to patients who haven’t booked follow-up appointments.",
            "Assist in implementing patient satisfaction surveys to improve services."
        ]
    },
    {
        title: "Workshops and Event Coordination",
        icon: vision2,
        message: "",
        list: [
            "Organize mental health workshops or community events.",
            "Handle registration, communication, and logistics for participants."
        ]
    },
    {
        title: "File Organization and Archiving",
        icon: vision2,
        message: "",
        list: [
            "Organize and archive patient records for easy retrieval.",
            "Ensure compliance with record-keeping regulations."
        ]
    },
    {
        title: "Support for Multilingual Patients",
        icon: vision2,
        message: "",
        list: [
            "Provide bilingual or multilingual communication for diverse patient populations."
        ]
    },
    {
        title: "Backup Support for Emergencies",
        icon: vision2,
        message: "",
        list: [
            "Act as a reliable point of contact for administrative tasks during staff shortages or emergencies."
        ]
    },
]

const whyusList = [
    {
        title: "Significant Cost Savings",
        icon: service1,
        message: "Honest Taskers helps dental practices save 65% or more on labor costs by hiring Virtual Medical Scribe (VMAs). Without the added expenses of taxes, benefits, and paid time off, practices can significantly reduce overhead while still receiving high-quality, reliable support.",
        list: []
    },
    {
        title: "Access to Skilled Talent",
        icon: service2,
        message: "Honest Taskers provides highly trained VAs who excel in:",
        list: [
            "Insurance verification and claims processing",
            "Appointment scheduling and follow-ups",
            "Patient communication",
            "Google business profiles optimization and social media management",
            "EMR software proficiency, Tebra, DrChrono, athenahealth, eClinicalWorks, Practice Fusion, AdvanceMD and more"
        ]
    },
    {
        title: "Bilingual Support for Diverse Patient Bases",
        icon: service3,
        message: "For practices serving multilingual communities, Honest Taskers offers bilingual VAs, enhancing communication and patient satisfaction, especially in Spanish-speaking markets.",
        list: []
    },
    {
        title: "Reduced Turnover and Reliable Support",
        icon: service4,
        message: "High turnover is a common issue for in-office staff. Honest Taskers’ VAs are reliable, consistent, and have a proven track record of long-term commitment, eliminating the need for frequent retraining.",
        list: []
    },
    {
        title: "Enhanced Practice Efficiency",
        icon: service5,
        message: "Delegating administrative tasks to a VMS allows in-office staff to focus on clinical duties, streamlining operations and improving patient care.",
        list: []
    },
    {
        title: "Proactive and Versatile Services",
        icon: vision1,
        message: "Honest Taskers’ VAs go beyond traditional tasks. For example, during slow periods, they can optimize Google Business Profiles, improving local search rankings and attracting new patients.",
        list: []
    },
    {
        title: "Flexibility and Scalability",
        icon: vision2,
        message: "Honest Taskers’ VAs work the hours you need, including evenings or weekends, and require no physical office space. As your practice grows, you can easily scale your virtual team.",
        list: []
    },
    {
        title: "HIPAA-Compliant and Secure",
        icon: storyImg5,
        message: "All Honest Taskers VAs are trained in HIPAA compliance, ensuring patient data security and confidentiality.",
        list: []
    },
    {
        title: "Improved Patient Retention and Growth",
        icon: storyImg4,
        message: "Fewer missed calls, timely follow-ups, and excellent service lead to higher patient retention rates and increased new patient inquiries.",
        list: []
    },
    {
        title: "Proven Track Record",
        icon: storyImg3,
        message: "Dental practices using Honest Taskers’ VAs report improved efficiency, reduced costs, and measurable growth, such as increased bookings and fewer no-shows.",
        list: []
    },
    {
        title: "Two-Week Trial to Ensure the Perfect Fit",
        icon: policy2,
        message: "Honest Taskers offers a two-week trial so you can assess whether the VMS meets your practice’s unique needs before committing.",
        list: []
    },
    {
        title: "No Long-Term Commitment",
        icon: policy5,
        message: "With no long-term contracts, Honest Taskers provides flexibility to adapt to your practice’s evolving needs.",
        list: []
    },
    {
        title: "Core Values-Driven Hiring",
        icon: policy8,
        message: "Honest Taskers doesn’t just hire for talent; they prioritize core values that align with their dental partners, ensuring a positive impact on your team culture and patient interactions.",
        list: []
    },
    {
        title: "Customized Training for Unique Practice Needs",
        icon: policy7,
        message: "If your practice has specific requirements, Honest Taskers offers customized training to ensure their VAs are fully prepared to meet your unique needs. This tailored approach helps optimize performance and achieve the desired outcomes for your practice.",
        list: []
    },
]

const faqs = [
    {
        question: "What tasks can the Virtual Medical Scribe handle for my practice?",
        answer: "Our Virtual Medical Scribe (VMAs) can manage a wide range of tasks, including:",
        options: [
            "Appointment scheduling and confirmation",
            "Patient intake and record updates",
            "Insurance verification and claims processing",
            "Patient communication, such as reminders and inquiries",
            "Billing, coding, and payment follow-ups",
            "Prescription refills and referrals",
            "Scribing and chart preparation",
            "Managing Google Business Profiles and social media",
            "Compliance documentation and reporting",
            "Care coordination and remote patient monitoring"
        ]
    },
    {
        question: "How experienced is the Virtual Medical Scribe in healthcare-specific tasks?",
        answer: "Our VMAs are highly trained and experienced in healthcare-specific administrative and clinical tasks. They are familiar with medical terminology, processes, and popular EHR systems like Athenahealth, Kareo, and eClinicalWorks."
    },
    {
        question: "Are they HIPAA-compliant, and how is patient data security ensured?",
        answer: "Yes, all Honest Taskers VMAs are HIPAA-trained and compliant. We use secure, encrypted communication channels and access controls to safeguard patient data. Regular training and audits ensure continued compliance."
    },
    {
        question: "What is the educational background of your Virtual Medical Scribe?",
        answer: "Our Virtual Medical Scribe (VMAs) have a strong educational background, including bachelor’s degrees in nursing, healthcare, or related fields, and some even hold degrees in medicine. Many are registered nurses (RNs) or have formal training in medical-related disciplines, making them adept at handling both clinical and administrative tasks. Additionally, some have specialized certifications in medical coding, billing, or transcription, further enhancing their skill set. Combined with extensive training in medical terminology, HIPAA compliance, and practice management software, our VMAs are highly qualified to support the diverse needs of medical practices."
    },
    {
        question: "What hours of availability can they provide?",
        answer: "Our VMAs work flexible hours to accommodate your practice’s needs, including evenings, weekends, and during peak times. We ensure their schedules align with your time zone and operational requirements."
    },
    {
        question: "Can the Virtual Dental Scribe assist with patient communication?",
        answer: "Absolutely. Our VMAs excel in patient communication, including:",
        options: [
            "Answering phone calls",
            "Sending appointment reminders",
            "Managing patient inquiries through email or chat",
            "Handling follow-ups to improve satisfaction and retention"
        ]
    },
    {
        question: "What kind of training and onboarding is required for the Medical Scribe ?",
        answer: "Honest Taskers provides initial training tailored to general dental tasks. If your practice has specific protocols, we offer customized training to ensure seamless integration. Onboarding typically takes 1-2 weeks."
    },
    {
        question: "What software platforms are they proficient in?",
        answer: "Our VMAs are trained in popular medical software, including:",
        options: [
            "Athenahealth",
            "Kareo",
            "eClinicalWorks",
            "Epic",
            "AdvancedMD",
            "ETC",
            "If your practice uses other tools, we can provide additional training."
        ]
    },
    {
        question: "How is their performance monitored and evaluated?",
        answer: "We track VMA performance through productivity reports, regular check-ins, and feedback from your practice. Our monitoring includes adherence to assigned tasks, timely responses, and compliance with protocols. Any concerns are addressed promptly."
    },
    {
        question: "What communication tools do they use to stay in touch with the team?",
        answer: "Our VMAs use secure and efficient platforms like Slack, Microsoft Teams, Zoom, and HIPAA-compliant email systems to ensure seamless collaboration with your team."
    },
    {
        question: "What is the cost structure, and are there any long-term contracts?",
        answer: "Our pricing is flexible and based on your needs, with options for hourly or flat-rate packages. There are no long-term contracts, giving you the freedom to scale or adjust as necessary."
    },
    {
        question: "Can they handle insurance verification and claims processing?",
        answer: "Yes, our VMAs specialize in insurance-related tasks, including verifying patient benefits, submitting claims, and following up on denials or pending claims."
    },
    {
        question: "How do they handle patient complaints or difficult conversations?",
        answer: "Our VMAs are trained in professional communication and problem-solving. They handle complaints empathetically and escalate issues to the appropriate team member when necessary."
    },
    {
        question: "What languages do they speak fluently?",
        answer: "Many of our VMAs are bilingual, with fluency in English and other languages such as Spanish. This ensures better communication with diverse patient bases."
    },
    {
        question: "Can they assist with social media management or marketing tasks?",
        answer: "Yes, our VMAs can support marketing by:",
        options: [
            "Creating and scheduling posts for social media",
            "Managing social media accounts",
            "Responding to online inquiries",
            "Optimizing Google Business Profiles for better local SEO"
        ]
    },
    {
        question: "How do they ensure accuracy in documenting patient records?",
        answer: "Our VMAs follow strict protocols for accuracy and compliance when entering patient data. They double-check entries and adhere to HIPAA guidelines to maintain record integrity."
    },
    {
        question: "What tools or technology do they need from the practice?",
        answer: "Typically, our VMAs require:",
        options: [
            "Access to your EHR system",
            "Secure communication tools",
            "A web phone or VOIP system",
            "Remote access to relevant files or systems"
        ]
    },
    {
        question: "Do they have experience working in a fast-paced medical practice?",
        answer: "Yes, many of our VMAs have worked in busy practices and are skilled at handling high patient volumes, managing schedules efficiently, and staying organized under pressure."
    },
    {
        question: "How quickly can they adapt to our office workflows and protocols?",
        answer: "Our VMAs are quick learners and typically adapt to your workflows within 2 -12 weeks. Customized training during onboarding ensures they are ready to hit the ground running."
    },
    {
        question: "What happens if they are unavailable or have connectivity issues?",
        answer: "Honest Taskers ensures continuity with backup staff or quick replacements in case of emergencies. We also require our VAs to work from reliable, secure environments with strong internet connections and have back up incase of disruption"
    },
    {
        question: "Can they assist with maintaining compliance documentation?",
        answer: "Yes, our VMAs can help manage and update compliance-related documents, such as OSHA records, patient forms, and audit logs, ensuring your practice stays compliant."
    },
    {
        question: "Where are your Virtual Medical Scribe located?",
        answer: "Our Virtual Dental Scribe are based in the Philippines and Latin America."
    }
]

const reviews = [
    {
        name: "Dr. Emily Harper",
        initials: "EH",
        subTitle: "Internal Medicine",
        message: "As a busy internist, I used to spend hours after clinic catching up on charting, which took valuable time away from my family. Since hiring a Virtual Medical Scribe from Honest Taskers, my workflow has completely transformed. My scribe captures every detail of patient encounters in real-time, ensuring accurate and complete documentation. Now, I can leave work on time, knowing my notes are taken care of and ready for submission. The transition was seamless, and the level of professionalism from my scribe has been exceptional. Honest Taskers has made a lasting difference in how I practice medicine!"
    },
    {
        name: "Dr. Raj Patel",
        initials: "RP",
        subTitle: "Family Medicine",
        message: "Before using a Virtual Medical Scribe from Honest Taskers, I struggled to keep up with the overwhelming administrative demands of charting. My scribe has not only lightened my workload but has also improved the overall quality of my notes. They are incredibly efficient, accurate, and detail-oriented. What impressed me most was how quickly they adapted to my workflow and EHR system. Now, I can focus fully on my patients during appointments without worrying about documentation. Hiring a virtual scribe has been one of the best decisions I’ve made for my practice."
    },
    {
        name: "Sarah Thompson",
        subTitle: "NP",
        initials: "MS",
        message: "As a Nurse Practitioner working across different nursing homes, I used to spend 2-3 hours every day on documentation. Since hiring a Virtual Medical Scribe from Honest Taskers, my workload has become so much more manageable. My scribe handles all my charting in real-time, ensuring accurate and complete documentation while allowing me to focus on patient care. The time I’ve saved has been incredible—I can now use those extra hours to improve my workflow and better serve my patients. Honest Taskers has truly been a game-changer for me!"
    }
]

const howItWorks = [
    {
        title: "Submit Your Inquiry & Discovery Call",
        img: dutyImg1,
        message: "Share your needs by submitting an inquiry, and we’ll schedule a discovery call to discuss your requirements, clarify expectations, and answer any questions you have."
    },
    {
        title: "Interview Hand-Selected Candidates",
        img: dutyImg2,
        message: "We’ll provide a shortlist of qualified candidates based on your needs. You’ll interview them to ensure they’re the perfect match for your practice."
    },
    {
        title: "Start Working with Your VMS",
        img: dutyImg3,
        message: "Once you’ve chosen your candidate, they’ll start supporting your practice right away, providing tailored assistance and immediate value."
    }
]

const hippas = [
    {
        img: feature1,
        title: "Comprehensive Training for Virtual Medical Scribe",
        message: "All Virtual Medical Scribe (VMSs) receive extensive training on HIPAA regulations, including:",
        options: [
            "Proper handling of Protected Health Information (PHI).",
            "Identifying and mitigating risks of data breaches.",
            "Secure communication practices to prevent unauthorized access."
        ]
    },
    {
        img: feature2,
        title: "Secure Communication Channels",
        message: "We ensure that all communication between VMSs and your practice is conducted via HIPAA-compliant platforms, such as:",
        options: [
            "Encrypted email services.",
            "Secure file-sharing systems.",
            "HIPAA-compliant phone and video conferencing tools."
        ]
    },
    {
        img: feature3,
        title: "Access Control and Authorization",
        options: [
            "VMSs are granted access only to the information necessary to perform their duties.",
            "Role-based permissions ensure that sensitive data is restricted to authorized personnel."
        ]
    },
    {
        img: feature1,
        title: "Data Encryption",
        message: "All transmitted and stored data is encrypted to safeguard it from potential breaches or unauthorized access."
    },
    {
        img: feature4,
        title: "Regular Audits and Monitoring",
        message: "Honest Taskers conducts routine internal audits to ensure ongoing compliance with HIPAA standards and proactively address potential vulnerabilities."
    },
    {
        img: feature5,
        title: "Business Associate Agreements (BAA)",
        message: "We provide Business Associate Agreements to all dental practices, ensuring a clear understanding of responsibilities and compliance requirements."
    },
    {
        img: feature6,
        title: "Secure Work Environment for VMSs",
        options: [
            "VMSs work in private, monitored environments to prevent unauthorized viewing or sharing of PHI.",
            "Policies are in place to prevent the use of unsecured devices or networks."
        ]
    },
    {
        img: feature1,
        title: "Incident Management Protocols",
        message: "In the rare event of a security incident, Honest Taskers has a well-defined protocol to:",
        options: [
            "Immediately contain and resolve the issue.",
            "Notify your practice promptly.",
            "Take corrective actions to prevent recurrence."
        ]
    },
    {
        img: feature1,
        title: "Continuous Education",
        message: "VMAs participate in ongoing HIPAA training to stay updated on evolving regulations and best practices for maintaining data security."
    },
]