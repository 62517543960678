import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { InputComponent } from "src/Components/Common"
import { updateProfileAction } from "src/Redux/Actions"
import { hideSpinner, showSpinner } from "src/Utils/Helper"
import { toast } from "react-toastify"
import SubmitArea from "../SubmitArea"

export default function VoiceRecording({user}) {
    const dispatch = useDispatch()
    const [video, setVideo] = useState(user.voice_recording)

    const {
        updateProfile_loading,
        updateProfile_success
    } = useSelector(state => state.Auth)

    useEffect(() => {
        if(!updateProfile_loading)hideSpinner()
    }, [dispatch, updateProfile_loading, updateProfile_success])

    const handleSubmit = () => {
        if(video === '') {
            toast("Please enter voice recording link")
            return
        }
        showSpinner()
        const formData = new FormData()
        formData.append("voice_recording", video)
        formData.append("action", "voice_recording")
        dispatch(updateProfileAction(formData))
    }

    const btnEnabled = user.resume_status === 'approved';
    
    return (
        <div className="video-interview">
            <h1 className="text-center">Voice Recording Instructions for Virtual Medical Receptionist Applicants</h1>
            {/* <div className="video-wrapper mt-5">
                <iframe src={"https://muse.ai/vc/3Sr9cYa"} 
                    ref={iframeRef}
                    style={{ width:"100%", height:"500px" }}
                    frameBorder="0" 
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen></iframe>
            </div> */}
            <div className="mt-5">To proceed with your application, please complete the <strong>voice recording assessment</strong> by following the steps below.</div>
            <div className="f-bold mt-3">📌 Recording Guidelines:</div>
            <ul style={{listStyleType: "none"}}>
                <li>✅ Find a quiet environment with minimal background noise.</li>
                <li>✅ Use a clear and professional tone, as if speaking to a patient or healthcare provider.</li>
                <li>✅ Speak at a natural pace, ensuring words are pronounced clearly.</li>
            </ul>

            <div className="f-bold mt-3">🎙️ How to Record & Submit:</div>
            <ul style={{listStyle:"numeric"}}>
                <li><strong>Click on this link</strong>: <a href="https://vocaroo.com/" target="__blank">https://vocaroo.com/</a> </li>
                <li><strong>Press the red button</strong> to start recording.</li>
                <li>Read the script below in a <strong>calm and professional manner</strong>.</li>
                <li>Once done, click <strong>Save & Share</strong>, then <strong>copy the link</strong> to your recording.</li>
                <li><strong>Paste your recording link in the application form below</strong> and submit your application.</li>
            </ul>

            <div className="f-bold mt-3">📜 Script to Record:</div>
            <div style={{fontStyle:"italic"}} className="mb-5">"Than you for calling Shiloh Health. My name is [Your Name], and I’m here to assist you. How can I help you today?
                I’d be happy to schedule your appointment. May I have your full name and date of birth?
                Thank you! I see that Dr. Johnson is available on December 1st at 1PM. Would you like me to book that for you?
                Perfect! You’re all set. You will receive a confirmation via email and text shortly. Is there anything else I can assist you with today?
                Thank you for calling Shiloh Health. Have a wonderful day!"</div>

            <div className="mt-4 mb-2" style={{fontSize:"20px",fontWeight:"bold"}}>Enter your audio link</div>
            <InputComponent value={video} onChange={(value) => setVideo(value)} />
            <div className="d-f j-c w-100 my-5">
                <div className={`save-btn center-item ${video === '' ? 'disabled': ''}`} onClick={() => handleSubmit()}>Submit Voice Recording</div>
            </div>
            <SubmitArea disabled={!btnEnabled}/>
        </div>
    )
}