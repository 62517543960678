import React from "react";
// import { useDispatch } from "react-redux";
import { Benefits, FAQs, Footer2, GetStarted2, Pricing, VMentalHealthHomeIntro, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans, pricingTitle } from "src/Constant";
// import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";


export default function VMentalHealthHomepage() {
    // const dispatch = useDispatch()
    const navigate = useNavigate()

    const benefits = [
        {
            title: "Patient-Centric Care:",
            message: "More time for face-to-face interactions, fostering deeper patient-provider connections.",
            color: "green"
        },
        {
            title: "Enhanced Efficiency:",
            message: "Delegate administrative tasks, letting you focus on clinical aspects and patient care.",
            color: "green"
        },
        {
            title: "HIPAA Compliant:",
            message: "We prioritize patient data protection and adhere to stringent security standards.",
            color: "green"
        },
        {
            title: "Cost-Effective:",
            message: "Minimize overheads and enjoy up to 60% savings compared to in-house staffing.",
            color: "green"
        },
        {
            title: "Flexible and Adaptable:",
            message: "Our assistants are trained to adapt to your specific software tools and practice protocols.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: "Appointment Management:",
            message: "Scheduling, reminders, and follow-ups to streamline patient visits."
        },
        {
            title: "Documentation:",
            message: "Assisting with case notes, treatment plans, and other essential documentation."
        },
        {
            title: "Billing & Coding Assistance:",
            message: "Ensure accurate medical coding and efficient billing processes."
        },
        {
            title: "Patient Communications:",
            message: "Handling phone calls, emails, and inquiries, ensuring timely responses."
        },
        {
            title: "Referral Coordination:",
            message: "Managing and coordinating referrals to specialists or other health care professionals."
        },
        {
            title: "Social Media & Online Reputation Management:",
            message: "Our Mental Health VA boosts your online presence and reputation, enhancing visibility and patient engagement."
        }
    ]

    const whyChooseUs = [
        {
            title: "Specialized Training:",
            message: "Our assistants undergo rigorous training tailored to mental health practices."
        },
        {
            title: "Consistent Support:",
            message: "We're available round-the-clock for any assistance or queries."
        },
        {
            title: "Trusted by Leading Professionals:",
            message: "Many leading mental health professionals trust our services for efficiency and expertise."
        },
        {
            title: "Scalable Solutions:",
            message: "Whether a solo practitioner or a multi-provider clinic, our solutions fit your unique needs."
        },
        {
            title: "Quality Assurance:",
            message: "Regular feedback and quality checks to ensure impeccable service standards."
        }
    ]

    const faqs = [
        {
            question: "Are your virtual assistants trained specifically for mental health practices?",
            answer: "Yes, our assistants undergo training tailored for mental health settings, ensuring they are equipped to handle unique needs."
        },
        {
            question: "How do you ensure patient confidentiality?",
            answer: "We strictly adhere to HIPAA guidelines, ensuring all patient communications and data remain confidential."
        },
        {
            question: "Can I customize the duties of my virtual assistant?",
            answer: "Absolutely! We work closely with you to tailor our services to fit your practice's specific requirements."
        },
        {
            question: "How quickly can an assistant be integrated into my practice?",
            answer: "Our onboarding process is swift, and we aim to have your virtual assistant integrated within a week, depending on your practice's systems."
        }
    ]

    const meta_title = "Virtual Mental Health Assistants | Save 60% - Honest Taskers";
    const meta_description = "Empower your mental health practice with our Virtual Assistants and save over 60% on operational costs. Enhance patient engagement and streamline tasks. Connect today!"
    const meta_image = "https://honesttaskers.com/vms_image.png"

    return (
        <div className="vmental-homepage">
             <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-mental-health-assistant"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <VMentalHealthHomeIntro onGetStarted={() => navigate('/va/request-VA')}/>
            <Benefits subTitle={"of Hiring a Virtual Mental Health Assistant"} benefits={benefits}/>
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities:"} />
            <WhyChooseUs subTitle={"HonestTaskers' Virtual Mental Health Assistant?"} whyChoose={whyChooseUs} />
            <FAQs faqs={faqs}/>
            <Pricing subTitle={pricingTitle} hasColor={true} plans={getPricingPlans('pricing-one')}/>
            <GetStarted2 
                title="Empower Your Mental Health Practice with a Specialized Touch."
                btnText="Engage Your Virtual Assistant Today!"
                onGetStarted={() => navigate('/va/request-VA')}/>
            <Footer2 />
        </div>
    )
}