import homeImg from 'src/Assets/Images/VmsHome/HomeImg.svg';
import './style.scss';
import { useEffect, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import dutyImg1 from 'src/Assets/Images/VmrHome/Duty1.svg';
import dutyImg2 from 'src/Assets/Images/VmrHome/Duty2.svg';
import dutyImg3 from 'src/Assets/Images/VmrHome/Duty3.svg';
import dutyImg4 from 'src/Assets/Images/VmrHome/Duty4.svg';
import { useDispatch, useSelector } from 'react-redux';
import { requestVAAction } from 'src/Redux/Actions';
import { hideSpinner, showSpinner } from 'src/Utils/Helper';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { PhoneComponent } from 'src/Components/Common';

const numberList = ['1', '2', '3', '4+'];
const periodList = ['This Week', 'Next Week', 'In Two Weeks', 'In Three Weeks', 'In +1 Month'];
const typeList = ['Virtual Dental Assistant', 'Virtual Dental Receptionist', 'Virtual Medical Assistant', 'Virtual Medical Receptionist','Virtual Mental Health Assistant',
    'Virtual Billing/Coding Assistant', 'Virtual Scribe', 'Remote Patient Monitoring Assistant', 'General Virtual Assistant','Other'
]


export default function RequestVA() {
    const location = useLocation()
    const navigate = useNavigate()
    const locationData = location.state || {}
    const [number, setNumber] = useState('');
    const [period, setPeriod] = useState('');
    const [step, setStep] = useState(1);
    const [types, setTypes] = useState([])
    const [name, setName] = useState('')
    const [practice, setPractice] = useState('')
    const [email, setEmail] = useState('')
    const [code, setCode] = useState("US");
    const [phone, setPhone] = useState('');
    const dispatch = useDispatch()
    const {
        requestVALoading,
        requestVASuccess
    } = useSelector(state => state.Home)

    const titles = [
        {
            title: "Thanks, that’s what we thought",
            question: "The number of Healthcare VAs needed?",
            img: homeImg,
            name: "Number"
        },
        {
            title: `Yes! We can support your organization with ${number} healthcare virtual assistant`,
            question: "How soon do you want to get started?",
            img: dutyImg1,
            name: "HowSoon"
        },
        {
            title: `Thanks for sharing, we can staff your organization with ${number} Healthcare VA ${period}`,
            question: "Type Healthcare VA Needed?",
            img: dutyImg2,
            name: "Type"
        },
        {
            title: `Great! We have awesome VAs available to support your practice`,
            question: "Please for your practice and organization name so we know who we’re dealing with?",
            img: dutyImg3,
            name: "Name"
        },
        {
            title: `Hi ${name}, Thanks for trusting us with your needs. This is the last step`,
            question: "Please for your contact information, we’re HIPAA compliant and takes your privacy seriously ",
            img: dutyImg4,
            name: "Contact"
        }
        // {
        //     title: 'Thank you for requesting a discovery call with Honest Taskers.',
        //     question: 'Please use the calendar below to schedule a discovery call at a time that works best for you.',
        //     img: dutyImg4,
        //     name: "Calendar"
        // }
    ]

    const titles2 = [
        {
            title: `Sorry, we missed that! Please select the type of healthcare VA you're looking for.`,
            question: "Type Healthcare VA Needed?",
            img: dutyImg2,
            name: "Type"
        },
        {
            title: `Noted! we can support you with ${types.join(", ")}`,
            question: "The number of Healthcare VAs needed?",
            img: homeImg,
            name: "Number"
        },
        {
            title: `Yes! We can support your organization with ${number} healthcare virtual assistant`,
            question: "How soon do you want to get started?",
            img: dutyImg1,
            name: "HowSoon"
        },
        {
            title: `Great! We have awesome VAs available to support your practice`,
            question: "Please for your practice and organization name so we know who we’re dealing with?",
            img: dutyImg3,
            name: "Name"
        },
        {
            title: `Hi ${name}, Thanks for trusting us with your needs. This is the last step`,
            question: "Please for your contact information, we’re HIPAA compliant and takes your privacy seriously ",
            img: dutyImg4,
            name: "Contact"
        }
        // {
        //     title: 'Thank you for requesting a discovery call with Honest Taskers.',
        //     question: 'Please use the calendar below to schedule a discovery call at a time that works best for you.',
        //     img: dutyImg4,
        //     name: "Calendar"
        // }
    ]

    useEffect(() => {
        if(locationData.name === "Calender") {
            setStep(titles.length)
        }
    }, [])

    useEffect(() => {
        if (!requestVALoading) {
            hideSpinner()
        }
        if(requestVASuccess) {
            toast("Request sent successfully!")
            navigate('/va/schedule-discovery-call', { replace: true })
        }
    }, [dispatch, requestVALoading, requestVASuccess])

    const handleBack = () => {
        if(step > 1)setStep(step-1)
        else window.history.back()
    }

    const currentTitle = locationData.answer === "no" ? titles2[step-1] : titles[step-1];
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const handleContinue = () =>  {
        if(step === titles.length) {
            if(!email.match(validRegex)) {
                toast("Please enter a valid email")
                return
            }
            showSpinner()
            const formdata = new FormData();
            formdata.append('first_name', name);
            formdata.append('email', email);
            formdata.append('phone', phone);
            formdata.append('types', types.join(", "))
            formdata.append('practice', practice)
            formdata.append('how_soon', period)
            formdata.append('number', number)
            dispatch(requestVAAction(formdata))
        } else {
            setStep(step+1)
        }
    }

    const continueDisabled = () => {
        if(currentTitle.name === "Number" && number === '')return true;
        if(currentTitle.name === "HowSoon" && period === '')return true;
        if(currentTitle.name === "Type" && types.length === 0)return true;
        if(currentTitle.name === "Name" && (practice === '' || name === ''))return true;
        if(currentTitle.name === "Contact" && (email === '' || phone === ''))return true;
        return false
    }

    const getStepContent = () => {
        const handleNumber = (number) => {
            setNumber(number)
            setTimeout(() => {
                setStep(step+1)
            }, 500);
        }
        const handlePeriod = (period) => {
            setPeriod(period)
            setTimeout(() => {
                setStep(step+1)
            }, 500);
        }
        const handleTypeChange = (type) => {
            let index = types.indexOf(type)
            if(index == -1) {
                setTypes([...types, type])
            } else {
                setTypes(types.filter((item) => item !== type))
            }
        }
        switch(currentTitle.name) {
            case "Number":
                return (<OptionsComponent onSelect={(item) => handleNumber(item)} value={number} list={numberList}/>)
            case "HowSoon": 
                return (<OptionsComponent onSelect={(item) => handlePeriod(item)} value={period} list={periodList}/>)
            case "Type":
                return (<OptionsMultiComponent onSelect={(item) => handleTypeChange(item)} currentList={types} list={typeList}/>)
            case "Name": 
                return (
                    <div className='row items-content'>
                        <div className='col-lg-6 mb-3'>
                            <InputField value={practice} label={'Practice name'} onChange={(value) => setPractice(value)}/>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            <InputField value={name} label={'Your name'} onChange={(value) => setName(value)}/>
                        </div>
                    </div>
                )
            case "Contact":
                return (
                    <div className='row items-content'>
                        <div className='col-lg-6 mb-3'>
                            <InputField value={email} label={'Email'} onChange={(value) => setEmail(value)} type='email'/>
                        </div>
                        <div className='col-lg-6 mb-3'>
                            {/* <div className='input-component-2'>
                                <ReactFlagsSelect 
                                    searchable
                                    searchPlaceholder='Search...'
                                    className='custom-flag'
                                    selected={code}
                                    showSelectedLabel={false}
                                    showSecondaryOptionLabel={true}
                                    onSelect={(code) => setCode(code)}
                                />
                                <div className='input-content'>
                                    <input className='form-control' value={phone} type='tel' onChange={(e) => setPhone(e.target.value)} placeholder='Phone'/>
                                </div>
                            </div> */}
                            <PhoneComponent
                                className='phone-comp-2'
                                value={phone} 
                                placeholder="Phone"
                                onChange={(value) => setPhone(value)} />
                        </div>
                    </div>
                )
            case "Calendar":
                return (
                    <div className='calender-picker'>
                         <InlineWidget url="https://calendly.com/honesttaskers/30min" />
                    </div>
                )
            default:
                return <div></div>
        }
    }

    return (
        <div className='request-va center'>
            <div className='container d-f j-c'>
                <div className='row w-100'>
                    <div className='col-lg-6 left-section'>
                        <div className='img-wrapper d-f j-c h-100'>
                            <img src={currentTitle.img} alt='request VA img'/>
                        </div>
                    </div>
                    <div className='col-lg-6 right-section'>
                        <div className='title'>{currentTitle.title}</div>
                        <h2>{currentTitle.question}</h2>
                        { getStepContent() }
                        <div className='action-row d-f a-c'>
                            <div className={`back center`} onClick={() => handleBack()}>
                                <span className='fa fa-angle-left'></span>Back
                            </div>
                            { !continueDisabled() && <div className='continue center' onClick={() => handleContinue()}>{step === titles.length ? 'Done': 'Continue'}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

const OptionsComponent = ({onSelect, value, list}) => {
    
    return (
        <div className='items-content row'>
            {
                list.map((item, index) => ( 
                    <div className='col-lg-6 mb-3'>
                        <div className={`item-card ${list[index] === value ? 'selected': ''}`} key={index} onClick={() => onSelect(item)}>
                            <div className='circle center'>{letters[index]}</div>
                            <div>{list[index]}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

const OptionsMultiComponent = ({onSelect, currentList, list}) => {
    
    return (
        <div className='items-content row'>
            {
                list.map((item, index) => ( 
                    <div className='col-lg-6 mb-3'>
                        <div className={`item-card ${currentList.includes(item) ? 'selected': ''}`} key={index} onClick={() => onSelect(item)}>
                            <div className='circle center'>{letters[index]}</div>
                            <div>{list[index]}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

 
const InputField = ({value, label, onChange, type = 'text'}) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <div className='input-component-2'>
            <div className='input-content'>
                { (isFocused || value.length > 0) && <div className='focus-label'>{label}</div> }
                <input 
                    className='form-control' 
                    value={value} 
                    type={type}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={label}/>
            </div>
        </div>
    )
}