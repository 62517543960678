import React from "react";
import { InlineWidget } from "react-calendly";
import SubmitArea from "../SubmitArea";

export default function LiveClientMatching() {

    return (
        <div className="client-matching">
            <h1>Schedule Live Client Matching Orientation</h1>
            <p className="my-5">Book a live orientation session where we’ll guide you through the client matching process and discuss how we pair you with the right opportunities. Click the link below to schedule your session.</p>
            {/* <div class="calendly-inline-widget" data-url="" style={{minWidth:"320px",height:"700px"}}></div>
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script> */}
            <div >
                <InlineWidget url="https://calendly.com/honesttaskers/honest-taskers-orientation"/>
            </div>
            <SubmitArea disabled={false} />
        </div>
    )
}