import { combineReducers } from 'redux';

import Home from './Home'
import Contact from './Contact';
import Profile from './Profile';
import Portal from './Portal';
import Auth from './Auth';

export default combineReducers({
    Home,
    Contact,
    Profile,
    Portal,
    Auth
})