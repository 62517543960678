import React, { useEffect, useState } from "react";
import './style.scss';
import { AttentionToDetails, BookInterview, DigitalProfile, HeadShotComponent, HIPAAComponent, LiveClientMatching, Orientation, PersonalInfoComponent, QualificationComponent, RemoteWorkReadiness, VideoInterview, VoiceRecording } from "src/Components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import _ from 'lodash';
import { getProfileAction, getUserAction } from "src/Redux/Actions";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { SET_PROFILE, SET_USER } from "src/Redux/Type";

const stepTitles = [
    {
        title: "Personal Information:",
        subTitle: "Accurately enter your personal information here before progressing to the next step of the application"
    },
    {
        title: "Qualification:",
        subTitle: "This is a critical part of your application. Please take the time to provide a detailed and comprehensive overview of your qualifications. Treat this section as you would your resume, as it will play a key role in determining your suitability for our organization."
    },
    {
        title: "HIPAA Certification for Healthcare Virtual Assistants",
        subTitle: "Healthcare Virtual Assistants must complete HIPAA certification to ensure they understand and comply with privacy and security regulations for handling sensitive patient information. This certification is critical for maintaining trust and safeguarding patient data in compliance with legal standards. Please follow the directions at the center of the page to complete the short HIPAA certification course. Once completed, submit a screenshot of your certification as proof of completion."
    },
    {
        title: "Attention to Detail Test",
        subTitle: "As part of our application process, this test evaluates your ability to follow instructions carefully and pay close attention to detail. Please read each question thoroughly and provide accurate responses. This is an important step in assessing your suitability for the role. Take your time and ensure all answers are complete before submitting."
    },
    {
        title: "Professional Headshot",
        subTitle: "Applicants are required to submit a professional headshot as part of the application process. A clear, well-presented photo helps create a strong first impression and demonstrates your professionalism. Please ensure your headshot has a neutral background and follows professional standards."
    },
    {
        title: "Work from Home Readiness",
        subTitle: "To ensure you are prepared for a remote work environment, please submit the following:",
        subTitle2: "These submissions help us confirm that your setup meets the requirements for optimal performance. ",
        options: [
            "A photo of your home workspace.",
            "A screenshot of your internet speed.",
            "A screenshot of your computer specifications.",
            "A photo showing your backup power supply and internet setup.",
            "A photo of your work computer",
            "Front and back of government issued ID Card or Passport for Identity verification"
        ]
    },
    {
        title: "Video Interview:",
        subTitle: "As part of the application process, Medical Virtual Assistants are required to submit a video interview. This allows us to evaluate your communication skills, professionalism, and ability to present yourself effectively. Follow the instructions provided to record and upload your video."
    },
    {
        title: "Voice Recording Instructions for Virtual Medical Receptionist Applicants",
        subTitle: "To proceed with your application, please complete the voice recording assessment by following the steps below.",
    },
    {
        title: "Interview:",
        subTitle: "Click the link below to schedule a live interview with a member of our hiring team. This is your opportunity to showcase your skills and learn more about the role. We look forward to speaking with you!"
    },
    {
        title: "Orientation",
        subTitle: "Watch the orientation video and complete the accompanying questions. This step ensures you understand the role, expectations, and our processes. Be sure to answer all questions accurately."
    },
    {
        title: "Schedule Live Client Matching Orientation",
        subTitle: "Book a live orientation session where we’ll guide you through the client matching process and discuss how we pair you with the right opportunities. Click the link below to schedule your session."
    },
    {
        title: "Digital Profile",
        subTitle: "Access your complete digital profile using the link below. This profile highlights your qualifications and will be shared with potential clients for their review. Make sure it accurately represents your skills and experience."
    }
]

export default function VACompleteProfile() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const [qStep, setQStep] = useState("Experience")
    const queryParams = new URLSearchParams(location.search);
    const signupTabs = ["Personal Information", "Qualification", "HIPAA", "Attention", "Professional Headshot", "Remote Work", "Video Interview", "Voice Recording", "Interview", "Orientation", "Schedule Client Matching", "Digital Profile"]

    const {
        user,
        profile,
        signupStep,
        getUser_loading,
        getUser_success,
        getProfile_loading,
        getProfile_success
    } = useSelector(state => state.Auth)

    const tokenIsEmpty = (token) => ['null', null, undefined, ''].includes(token)
    const locationData = location.state || {}
    useEffect(() => {
        var token = queryParams.get("token");
        if(token) {
            sessionStorage.setItem("token", token);
        } else {
            token = sessionStorage.getItem("token");
        }
        if(tokenIsEmpty(token) || (token && token.length < 200))navigate('/signup')

        if(locationData.reload) {
            console.log("New state: Reloading user data")
            showSpinner()
            dispatch(getUserAction())
            dispatch(getProfileAction())
        } else {
            if(user === null || user === undefined) {
                if(tokenIsEmpty(token))return
                showSpinner()
                dispatch(getUserAction())
                // let localUser = JSON.parse(sessionStorage.getItem("user"))
                // if(!_.isEmpty(localUser)) {
                    
                // } else {
                //     dispatch({ type: SET_USER, data: localUser })
                //     console.log("loaded user from cache")
                // }
            }
            if(profile === null || profile === undefined) {
                if(tokenIsEmpty(token))return
                let localProfile = JSON.parse(sessionStorage.getItem("profile"))
                if(_.isEmpty(localProfile)) {
                    dispatch(getProfileAction())
                } else {
                    console.log("loaded profile from cache")
                    dispatch({ type: SET_PROFILE, data: localProfile })
                }
            }
        }
    }, [])

    useEffect(() => {
        if(!getProfile_loading)hideSpinner()
        if(getUser_success && user) {
            hideSpinner()
            sessionStorage.setItem("user", JSON.stringify(user))
            if(getProfile_success) {
                console.log("profile loaded from server")
            }
            console.log(user, profile)
        }
    }, [dispatch, getUser_loading, getUser_success, getProfile_loading, getProfile_success])
    
    return (
        <div className="signup-page">
            <div className="progress-explain">
                <div style={{fill:"white",cursor:"pointer",top:"32px",left:"31px",position:"absolute"}}>
                    {/* <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="inherit" class="injected-svg" data-src="/static/media/left-arrow.8dcc3739fa2112979d30a38736f74358.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M6.4 0.599976L7.8 1.99998L3.8 5.99998H19V7.99998H3.8L7.1 11.3L7.8 12L6.4 13.4L0 6.99998L6.4 0.599976Z" fill="inherit"></path>
                        </svg>
                    </div> */}
                </div>
                <div className="bg-dot custom-bg"></div>
                <div className="explain-content">
                    <div id="section-explain-1">
                        <h1>{stepTitles[signupStep-1].title}</h1>
                        <p>{stepTitles[signupStep-1].subTitle}</p>
                        {
                            stepTitles[signupStep-1].options && (
                                <ul style={{listStyleType: "decimal"}}>
                                    {stepTitles[signupStep-1].options.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )
                        }
                        {stepTitles[signupStep-1].subTitle2 && <p>{stepTitles[signupStep-1].subTitle2}</p>}
                        <div className="mt-4 f-bold">Note:</div>
                        <div className="f-16 text-orange" style={{fontStyle:"italic"}}>Your progress is being saved automatically. You can safely continue your application at any time.</div>
                    </div>
                </div>
            </div>
            <div className="work-area">
                <div className="work-header show-web-flex">
                    <div className="container center-item">
                        <div className="work-container">
                            <div className="header-row">
                                {
                                    signupTabs.map((item, index) => (
                                        <div className={`col-name ${index+1 === signupStep ? 'active' : ''} `} key={index}>{item}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="work-section">
                    <div className="container">
                        <div className="work-content work-container" id="section-1">
                            { user && profile && 
                            <ProfileFormSection 
                                key={signupStep}
                                signupStep={signupStep} 
                                user={user} 
                                qStep={qStep} 
                                onQStep={(value) => setQStep(value)}/>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProfileFormSection = ({signupStep, user, qStep, onQStep}) => {
    switch(signupStep) {
        case 1: 
            return (<PersonalInfoComponent user={user} />)
        case 2:
            return (<QualificationComponent currentTab={qStep} setCurrentTab={(value) => onQStep(value)}/>)
        case 3:
            return (<HIPAAComponent user={user} />)
        case 4:
            return (<AttentionToDetails />)
        case 5:
            return (<HeadShotComponent user={user} />)
        case 6:
            return (<RemoteWorkReadiness user={user} />)
        case 7:
            return (<VideoInterview user={user} />)
        case 8:
            return (<VoiceRecording user={user}/>)
        case 9:
            return (<BookInterview user={user}/>)
        case 10:
            return (<Orientation user={user}/>)
        case 11:
            return (<LiveClientMatching />)
        case 12:
            return (<DigitalProfile user={user}/>)
        default: 
            return (<PersonalInfoComponent user={user}/>)
    }
}