import React, { useEffect, useState } from "react";
import './style.scss';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SET_QUESTION } from "src/Redux/Type";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { getQuizQuestionsAction } from "src/Redux/Actions";
import _ from 'lodash';

export const QuizModal = ({show, onHide, onComplete }) => {
    const dispatch = useDispatch()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [selectedOption, setSelectedOption] = useState(-1)
    const [answers, setAnswers] = useState([])

    const {
        currentQuestion,
        quiz_questions,
        quizQuestions_loading,
    } = useSelector(state => state.Auth)

    useEffect(() => {
        if(_.isEmpty(quiz_questions)) {
            showSpinner()
            dispatch(getQuizQuestionsAction())
        }
    }, [])

    useEffect(() => {
        if(!quizQuestions_loading) {
            hideSpinner()
        }
    }, [dispatch,quizQuestions_loading, quiz_questions])

    const handleSelect = (item) => {
        setSelectedOption(item.id)
        let newList = answers
        let index = newList.findIndex(item => item.question_id === currentQuestion.id)
        if(index !== -1) {
            newList[index] = item
        } else {
            newList.push(item)
        }
        setAnswers(newList)
    }

    const handleNext = () => {
        let answerIndex = answers.findIndex(value => value.question_id === currentQuestion.id)
        if(answerIndex === -1)return;
        if(currentIndex === quiz_questions.length-1) {
            onComplete(answers)
            onDismiss()
        };
        dispatch({
            type: SET_QUESTION,
            data: quiz_questions[currentIndex+1]
        })
        setCurrentIndex(currentIndex+1)
    }

    const handleBack = () => {
        if(currentIndex === 0)return;
        dispatch({
            type: SET_QUESTION,
            data: quiz_questions[currentIndex-1]
        })
        setCurrentIndex(currentIndex-1)
    }

    const optionIsActive = (item) => {
        let answerIndex = answers.findIndex(value => value.question_id === currentQuestion.id)
        if(answerIndex !== -1)return answers[answerIndex].description === item.description
        return false;
    }

    const onDismiss = () => {
        setCurrentIndex(0)
        setAnswers([])
        dispatch({
            type: SET_QUESTION,
            data: null
        })
        onHide()
    }

    return (
        <Modal className="quiz-modal modal-lg" show={show} onHide={() => onDismiss()} fullscreen>
            <Modal.Header>
                <div className="modal-title">Orientation Quiz</div>
                <div className="close" onClick={() => onDismiss()}><span className="fa fa-close"></span></div>
            </Modal.Header>
            <Modal.Body>
                {
                    quiz_questions && currentQuestion && (
                        <>
                            <div className="test-header card">
                                <div className="question-count">Questions {currentIndex+1} of {quiz_questions.length}</div>
                                <div className="boxes">
                                    { 
                                        quiz_questions.map((item, index) => (
                                            <div key={index} className={`box ${index === currentIndex ? 'active' : ''}`}></div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="row mt-5 test-content">
                                <div className="col-lg-6 left-section">
                                    <div className="card question-card">
                                        <h5>Question</h5>
                                        <div dangerouslySetInnerHTML={{ __html: currentQuestion.description }}></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 right-section">
                                    <div className="card answers-card">
                                        <h5>Answers</h5>
                                        <div className="options">
                                            { 
                                                currentQuestion.options.map((item, index) => (
                                                    <div key={index} className={`answer-option ${optionIsActive(item) ? 'selected' : ''}`} onClick={() => handleSelect(item)}>
                                                        <div className="circle center"><div className="inner"></div></div>
                                                        <div>{item.name}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="test-action-btns">
                                            <div className={`action-btn center back ${currentIndex === 0 ? 'disabled': ''}`} onClick={() => handleBack()}>BACK</div>
                                            <div className="action-btn center text-btn">
                                                {currentIndex+1} <span className="ms-1"> of {quiz_questions.length}</span>
                                            </div>
                                            <div className={`action-btn center next `} onClick={() => handleNext()}>{currentIndex === quiz_questions.length-1 ? 'FINISH': 'NEXT'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </Modal.Body>
        </Modal>
    )
}