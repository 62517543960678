
import './style.scss';
import { AdwordsVideoModal } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideoModal } from 'src/Redux/Actions';
import va1 from "src/Assets/Images/Adwords/va1.png";
import va2 from "src/Assets/Images/Adwords/va2.png";
import { useLocation } from 'react-router-dom';

const playIconImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAaVBMVEUyKDIAAAD5+Pn////////c2tzd291lX2X///////+Ig4j9/f3y8fL////Lycvj4uP////q6Oqxr7H///+xrLGgnKD////////////MyszAvcD///////////////////////+em57///8U66MHAAAAInRSTlMzAObgv5mZQCAQTPLMQH+mz7JmcGZZn1CAgHOvkI9gf29ZYTSjpAAAAgVJREFUSMet1+uanCAMBuAIKCAgKp7m2Nb7v8i6ZVtnZyDIbL//72PkkZhAEYyc3dTSdQtll8XIIhgIyR9MaduMNWyp+3OpFXPiEDZM2Z7D1/QdYQbFnuqGQygnQmcUC0b6V7bzScTxT1pxwFLRewTLGxkhkZrcgli2HYdkuG3FKxa0wtleuthx0OJaPuF2t2ndyi/4ZiEj3e0R3wnPwZy4HQtaQ1ZGKv7hqYLMVOwvngnkhhPziWkD2emZx4bAG9HmD2ahBw/lgOMT+8CChI5zXdeuxjBXcsOug9eU60fQC2qXDbMhilfSoEcGUkEEe14jdYPRGMZeXc+wlAm8qiqMrYPLGcPYqzcTsAHHPjpQ+9gCrTGMvXpNYQUE47Wvx/GqvoPJN7AKlH30wCwPHFg7HsG6D33cMDVpTIIf0vkCzqawitzMcoH5/YthQCqOYd1DLErizUAlmkGx2BhWaBvq3IaDdff+JmEhItp6x7I/0noLo+GNEN/0/ZFl5kQLjw3h2ZjMn7hg+b/Y6eHnPubZmop9rHC5Y8X9caC5djnYXotHLPNGqac5TOYMceK/jY9et5anKe9aGZy3r6RO2V/kKiPD+j1ROq+oQ9aECZsFBsIEuqDMNML5STNzYDUi3fAsB6vQ1WiPcEzp8jz4pWxsrFZskamlbI80y4X5dbCd3BxZB38DEqA4oLQzim8AAAAASUVORK5CYII=";
const quoteImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAMAAACKnBfWAAAAOVBMVEUAAAA4r2hAn3A5q2w6qmw4qWw6q2w6q2w6rGw5qWs5qmw1pWo6rGw4qm06qmo4p2g6rGw6qWw6q2xmPPlNAAAAEnRSTlMAIBC/kEDvz59wUDDfYDAgr4CQHqdzAAAAXElEQVQI1y2NSQ7AMAwCafak6eb/P7ZY2JcRlhjAa2a2gEJkxpu0E+hEYZ6eEx7HZM5kA5bnymzqjahV5wC2E9LaJ2uHtJy7VKNWvddRwL/mmuak3bTGXDr8Agk/4+gHiXQZID4AAAAASUVORK5CYII=";

// const medicalPages = ['/va/medical/receptionist', '/va/medical/scribe', '/va/mental-health', '/va/medical-billing-coding', '/va/medical']

const VideoRow = ({item, onClick}) => {

    return (
        <div className='row main-row mt-5 no-gutter'>
            <div className='col-lg-6 left-container'>
                <div className='left-area'>
                    <img src={quoteImg} alt="dot" class="dot" style={{width:"fit-content",width:"max-content"}}/>
                    <div><strong>Meet {item.name}</strong></div>
                    { item.country && <div><strong>Country:</strong> {item.country}</div> }
                    { item.degree && <div><strong>Degree:</strong> {item.degree}</div>}
                    { item.title && <div><strong>Title:</strong> {item.title}</div>}
                    { item.practice && <div> {item.practice}</div>}
                </div>
            </div>
            <div className='col-lg-6 right-container d-f'>
                <div className='right-area' onClick={onClick}>
                    <img alt="video" class="video" src={item.thumb} />
                    {/* <iframe src={item.video} 
                        style={{ width:"100%", height:"100%" }}
                        frameBorder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen></iframe> */}
                    <div className='overlay'>
                        <img src={playIconImg} alt="play-icon" class="play-icon"></img>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default function Showcase({
    titleVA = "Who Are Our Virtual Dental Assistants?",
    titleClient = "Client Review",
    videoList = 1 
}) {
    const dispatch = useDispatch()

    const va_videos_1 = [
        {
            name: "Marcela",
            video: "https://muse.ai/vc/XibGL2z-Virtual-Dental-Assistant-Services-From-Honest-Taskers",
            country: "Nicaragua",
            degree: "Marketing",
            practice: "Value Dental, New Jersey",
            thumb: va1
        }
    ]
    
    const va_videos_2 = [
        {
            name: "Marcela Gonzalez",
            video: "https://muse.ai/vc/PnRj4cM-Virtual-Dental-Assistant-Services-from-Honest-Taskers",
            country: "Nicaragua",
            degree: "Marketing",
            practice: "Value Dental, New Jersey",
            thumb: va2
        }
    ]

    const va_videos_3 = [
        {
            name: "Marcela Gonzalez",
            video: "https://muse.ai/vc/PnRj4cM-Virtual-Dental-Assistant-Services-from-Honest-Taskers",
            country: "Nicaragua",
            title: "Virtual Healthcare Assistant",
            thumb: va2
        }
    ]

    const {
        showVideoModal,
        video
    } = useSelector(state => state.Home)

    const handleVideoClick = (open, video) => {
        let data = { isOpen: open, video: video }
        dispatch(toggleVideoModal(data))
    }

    const getVideos = () => {
        if(videoList === 1) {
            return va_videos_1
        }
        if(videoList === 2) {
            return va_videos_2
        }
        return va_videos_3
    }

    return (
        <div className="showcase">
            { video && <AdwordsVideoModal show={showVideoModal} onHide={() => handleVideoClick(false, null)} video={video}/>}
            <div className="container">
                <div className="padding">
                    <h2 className='pb-5'>{titleVA}</h2>
                    {
                        getVideos().map((item, index) => (
                            <VideoRow key={index} item={item} onClick={() => handleVideoClick(true, item.video)}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}