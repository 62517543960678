import React from "react";
import './style.scss'
import { FaTimes } from 'react-icons/fa';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleCalendlyModal, toggleSideMenu } from "src/Redux/Actions";

export default function MobileSideMenu({show}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const changePage = (page) => {
        dispatch(toggleSideMenu(false))
        navigate(page)
    }

    const handleDiscovery = () => {
        // Handle click event
        navigate('/va/request-VA')
        dispatch(toggleSideMenu(false))
    }

    return (
        <div className={`mobile-menu-wrapper ${show ? '' : 'd-none'}`}>
            <div className="side-menu">
                <div className="content">
                    {/* <div className="close">
                        <img src={close} onClick={() => dispatch(toggleSideMenu(false))}/>
                    </div> */}
                    <nav className="nav-list">
                        <div className="btn btn-call" onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfucq_29cMbGMKsvlYFBcuzrZFoJ4hs5Q2K8Iai1KPJS0b1AA/viewform", '_blank')?.focus()}>Apply NOW</div>
                        <div className="menu-item">Services</div>
                        <div className="sub-menu-wrapper">
                            <div className="menu-item" onClick={() => changePage('/multi-purpose-virtual-medical-assistant')}>Multi-Purpose Virtual Medical Assistant</div>
                            <div className="menu-item" onClick={() => changePage('/virtual-medical-scribe')}>Virtual Medical Scribe</div>
                            <div className="menu-item" onClick={() => changePage('/virtual-medical-receptionist')}>Virtual Medical Receptionist</div>
                            <div className="menu-item" onClick={() => changePage('/virtual-dental-receptionist')}>Virtual Dental Receptionist</div>
                            <div className="menu-item" onClick={() => changePage('/virtual-medical-billing')}>Virtual Medical Biller</div>
                            <div className="menu-item" onClick={() => changePage('/virtual-mental-health-assistant')}>Virtual Mental Health Assistant</div>
                            <div className="menu-item" onClick={() => changePage('/remote-patient-monitoring-assistant')}>Remote Patient Monitoring Assistant</div>
                            <div className="menu-item" onClick={() => changePage('/telehealth-medical-assistant')}>Telehealth Medical Assistant</div>
                            <div className="menu-item" onClick={() => changePage('/virtual-medical-coder')}>Virtual Medical Coder</div>
                            <div className="menu-item" onClick={() => changePage('/telephone-triage-medical-assistant')}>Telephone Triage Medical Assistant</div>
                            <div className="menu-item" onClick={() => changePage('/virtual-patient-care-coordinator')}>Virtual Patient Care Coordinator</div>
                            <div className="menu-item" onClick={() => changePage('/remote-mds-coordinator')}>Remote MDS Coordinator</div>
                            <div className="menu-item" onClick={() => changePage('/remote-clinical-chat-auditor')}>Remote Clinical Chat Auditor</div>
                            <div className="menu-item" onClick={() => changePage('/virtual-dental-assistant')}>Virtual Dental Assistant</div>
                        </div>
                        <div className="menu-item" onClick={() => changePage('/about')}>About Us</div>
                        <div className="menu-item" onClick={() => changePage('/story')}>Our Story</div>
                        <div className="menu-item" onClick={() => changePage('/why-us')}>Why Us</div>
                        <div className="menu-item" onClick={() => changePage('/vision')}>Vision</div>
                        <div className="menu-item" onClick={() => changePage('/vision/#values')}>Values</div>
                        {/* <div className="menu-item" onClick={() => changePage('/blog')}>Blog</div> */}
                        <div className="menu-item" onClick={() => changePage('/#pricing')}>Pricing</div>
                        {/* <div className="menu-item" onClick={() => changePage('/auth/sign-in')}>Sign In</div> */}
                        <div className="btn btn-call" onClick={() => handleDiscovery()}>Book Discovery Call</div>
                    </nav>
                </div>
            </div>
        </div>
    )
}