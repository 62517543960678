import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "src/Redux/Actions";
import { UPDATE_SIGNUP_STEP } from "src/Redux/Type";

export default function SubmitArea({ disabled = true, title = "Continue"}) {
    const dispatch = useDispatch()
    const {
        signupStep,
        user
    } = useSelector(state => state.Auth)

    const handleBack = () => {
        if(signupStep <= 1)return
        dispatch({
            type: UPDATE_SIGNUP_STEP,
            data: { step: signupStep - 1}
        })
    }

    const handleNext = () => {
        if(signupStep >= 12)return;
        dispatch({
            type: UPDATE_SIGNUP_STEP,
            data: { step: signupStep + 1}
        })

        if(signupStep === 9 && !user.scheduled_client_orientation) {
            const formdata = new FormData()
            formdata.append("action", "scheduled_client_orientation")
            dispatch(updateProfileAction(formdata))
            console.log("scheduled client orientation");
        }
    }

    return (
        <div className="submit-area">
            <div className="submit-container container">
                <div className="btn-area">
                    <div className={`back-btn ${signupStep === 1 ? 'disabled': ''}`} onClick={() => handleBack()}>
                        <div className="back-icon center-item">
                            <div className="fa fa-arrow-left" style={{color:"rgb(192, 197, 201)"}}></div>
                        </div>
                        <div className="back-text center-item">Back</div>
                    </div>
                    <div>
                        <div className={`continue-btn d-f a-c j-c ${disabled ? 'disabled': ''}`} onClick={() => handleNext()}>
                            <div className="d-f a-c">{title}
                                <div className="ms-2 fa fa-arrow-right" style={{fill:"white"}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}