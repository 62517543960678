import React from "react";
import { Benefits, FAQs, Footer2, GetStarted2, Pricing, VmHowItWorks } from "src/Components";
import { RPMonitoringHomeIntro } from "src/Components/Home/RPMonitoring";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function RPMonitoringHomepage() {
    // const dispatch = useDispatch()
    const navigate = useNavigate()

    const benefits = [
        {
            title: "Enhanced Patient Engagement:",
            message: "Continuous tracking ensures patients remain engaged with their treatment plans.",
            color: "green"
        },
        {
            title: "Early Detection:",
            message: "Identify health issues before they escalate, leading to better outcomes.",
            color: "green"
        },
        {
            title: "Seamless Integration:",
            message: "Our tools and techniques fit smoothly into your current healthcare system.",
            color: "green"
        },
        {
            title: "Cost-Effective:",
            message: "Remote monitoring can decrease hospital visits, saving costs for both practices and patients.",
            color: "green"
        },
        {
            title: "Safety First:",
            message: "Especially crucial in situations like pandemics; monitor patients without physical contact.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: 'Data Collection:',
            message: "Collecting patient health data from remote monitoring devices."
        },
        {
            title: "Data Interpretation:",
            message: "Analyzing the data to identify patterns, concerns, or anomalies."
        },
        {
            title: "Alerts & Notifications:",
            message: "Informing medical professionals about any concerning data points or health changes."
        },
        {
            title: "Patient Communication:",
            message: "Educating patients on device use and answering any queries they might have."
        },
        {
            title: "Reporting:",
            message: "Compiling and presenting data in understandable formats for healthcare professionals."
        }
    ]

    const whyChooseList =  [
        {
            title: "Specialized Training:",
            message: "Our assistants undergo rigorous training tailored to remote patient monitoring."
        },
        {
            title: "Data Security:",
            message: "We prioritize patient data protection and adhere to stringent security standards."
        },
        {
            title: "Cutting-Edge Tools:",
            message: "Utilizing the latest remote monitoring technologies for precise patient tracking."
        },
        {
            title: "Scalable Solutions:",
            message: "Whether for individual patients or large groups, we can scale to fit your needs."
        },
        {
            title: "24/7 Support:",
            message: "Continuous monitoring means round-the-clock support from our side."
        }
    ]

    const faqs = [
        {
            question: "Are your assistants trained specifically for remote patient monitoring?",
            answer: "Yes, our assistants receive specialized training to handle the nuances of remote patient monitoring effectively."
        },
        {
            question: "How do you ensure data security and patient confidentiality?",
            answer: "All data transfers are encrypted, and we adhere strictly to HIPAA guidelines to protect patient data."
        },
        {
            question: "How quickly can an assistant be integrated into our healthcare system?",
            answer: "Depending on your system, the onboarding process is swift, with most integrations completed within a week."
        },
        {
            question: "What devices or systems do you support?",
            answer: "We are adaptable and can work with a wide range of remote monitoring devices and software platforms."
        }
    ]

    const meta_title = "Remote Patient Monitoring Services | 60% Cost Savings"
    const meta_description = "Enhance patient care with Honest Taskers’ Remote Patient Monitoring. Achieve over 60% in cost savings while ensuring accurate and timely health data monitoring. Get in touch now!"
    const meta_image = "https://honesttaskers.com/vms_image.png"

    return (
        <div className="rp-monitoring-homepage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/remote-patient-monitoring-assistant"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <RPMonitoringHomeIntro onGetStarted={() => navigate('/va/request-VA')}/>
            <Benefits subTitle={"of Working With Our Remote Patient Monitoring Expert"} benefits={benefits}/>
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities:"}/>
            <WhyChooseUs subTitle={"HonestTaskers' Remote Patient Monitoring Assistants?"} whyChoose={whyChooseList}/>
            <FAQs faqs={faqs}/>
            <Pricing hasColor={true} plans={getPricingPlans('rp-monitoring')}/>
            <GetStarted2 title={"Redefine Patient Care with Expert Remote Monitoring."}
                btnText="Engage Your Monitoring Assistant Today!"
                onGetStarted={() => navigate('/va/request-VA')}/>
            <Footer2 />
        </div>
    )
}