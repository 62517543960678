import React from "react";
// import { useDispatch } from "react-redux";
import { Benefits, Duties, FAQs, Footer2, LetsConnect, Pricing, Reviews, VmrHomeIntro, VmrWhyChooseUs } from "src/Components";
import { ReceptionReviews, ReceptionFaqs, getPricingPlans, VmrBenefitsList, vmrDuties, VMRWhyChoooseUs, getImageStructuralData, getFAQStructuralData } from "src/Constant";
// import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function Home() {
    // const dispatch = useDispatch()
    const navigate = useNavigate()
    const pricingTitle = "We offer a range of tailored packages to cater to diverse practice<br> sizes and requirements. From basic to advanced, our solutions<br> are designed to provide unmatched value and efficiency."

    const meta_title = "Hire Our Virtual Medical Receptionist & Save Over 60%"
    const meta_description = "Boost efficiency with our Virtual Medical Receptionist. Save over 60% on costs, enhance service quality, and streamline operations. Contact us now!"
    const meta_image = "https://honesttaskers.com/vmr_image.png"

    return (
        <div className="vmr-landing">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-medical-receptionist"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(ReceptionFaqs))}</script>
            </Helmet>
            <VmrHomeIntro onLearnMore={() => navigate('/va/request-VA')}/>
            <Benefits benefits={VmrBenefitsList} subTitle={"of Virtual Medical Receptionist"}/>
            <Duties duties={vmrDuties} title={"Duties & Responsibilities"} subTitle={"of Virtual Medical Receptionist"}/>
            <VmrWhyChooseUs whyChoose={VMRWhyChoooseUs} subTitle={"HonestTaskers' Virtual Medical Receptionists"}/>
            <Reviews Reviews={ReceptionReviews} className={'white-bg'}/>
            <FAQs faqs={ReceptionFaqs}/>
            <Pricing subTitle={pricingTitle} hasColor={true} plans={getPricingPlans('pricing-one')}/>
            <LetsConnect />
            <Footer2 />
        </div>
    )
}