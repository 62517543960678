import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";

export default function TextEditor({editorRef, value, placeholder, showPlaceholder = false }) {
  const [isPlaceholderActive, setIsPlaceholderActive] = useState(showPlaceholder);
    return (
      <>
        <Editor
          apiKey='o9u5xwanwn8xh3r1fmqt88kaqvtslyte8hmtiryd5ous8wxg'
          onInit={(_evt, editor) => editorRef.current = editor}
          initialValue={isPlaceholderActive ? placeholder : value}
          onChange={(e) => {
            if (e.target.getContent() === "") {
              setIsPlaceholderActive(true);
            }
          }}
          init={{
            height: 500,
            menubar: false,
            setup: (editor) => {
              editor.on("focus", () => {
                if (isPlaceholderActive) {
                  editor.setContent("");
                  setIsPlaceholderActive(false);
                }
              });
            },
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />
      </>
    );
  }