import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { editExperienceAction, saveExperienceAction, toggleExperienceModalAction } from "src/Redux/Actions";
import { TextEditor } from "src/Components/Common";
import { toast } from "react-toastify";
import { SET_EXPERIENCE } from "src/Redux/Type";
import { showSpinner } from "src/Utils/Helper";
import _ from 'lodash';

export const ExperienceItem = ({item, onEdit, onDelete }) => {
    return (
        <div className="experience-item q-item">
            <div className="item-header">
                <div className="info">
                    <div className="title">{item.title}</div>
                    <div className="company">{item.company.name}</div>
                </div>
                <div className="action-btns">
                    <div className="item-btn center-item" onClick={() => onEdit()}>Edit</div>
                    <div className="item-btn danger center-item" onClick={() => onDelete()}>Delete</div>
                </div>
            </div>
            <div className="dates">
                <div className="item"><span className="label">Start:</span>{item.start_date}</div>
                <div className="item"><span className="label">End:</span>{item.end_date === '' ? "Current" : item.end_date}</div>
            </div>
            <p className="description" dangerouslySetInnerHTML={{ __html: item.description }}></p>
        </div>
    )
}

export const ExperienceComponent = ({profile, onDelete}) => {
    const dispatch = useDispatch()
    const handleEdit = (item) => {
        dispatch({
            type: SET_EXPERIENCE,
            data: item
        })
    }

    return (
        <div className="item-list">
            <div className="sec-title">Capture your work experience from previous employers one at a time</div>
            <div className="btn-row">
                <div className="add-btn" onClick={() => dispatch(toggleExperienceModalAction(true))}><span className="fa fa-plus me-2"></span>Add Experience</div>
            </div>
            {
                profile.experience.map((item, index) => (
                    <ExperienceItem 
                        key={index} 
                        item={item} 
                        onDelete={() => onDelete(item)}
                        onEdit={() => handleEdit(item)}/>
                ))
            }
            {
                profile.experience.length === 0 && <div style={{padding:"3rem 0",textAlign:"center"}}>No Experience</div>
            }
        </div>
    )
}

export const AddExperienceModal = ({show, onHide, onSubmit, experience = null, loading = false}) => {
    const dispatch = useDispatch()
    const editorRef = useRef(null);
    const [title, setTitle] = useState('')
    const [company, setCompany] = useState('')
    const [description, setDescription] = useState('')
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
       if(experience && experience.id) {
            setTitle(experience.title)
            setCompany(experience.company.name)
            setDescription(experience.description)
            setStartDate(parseDate(experience.start_date))
            setEndDate(parseDate(experience.end_date))
       }
    }, [])

    const parseDate = (dateString) => {
        const parsedDate = new Date(dateString);
        if (!isNaN(parsedDate.getTime())) { 
            const formattedDate = parsedDate.toISOString().split("T")[0]; 
            return formattedDate
        } else {
            return ""
        }
    }

    const submit = () => {
        if((editorRef.current && editorRef.current.getContent() === '')) {
            toast("Enter description")
            return
        }
        const formdata = new FormData()
        formdata.append("title", title)
        formdata.append("company_name", company)
        formdata.append('start_date', startDate)
        formdata.append('description', editorRef.current.getContent())
        formdata.append('current', endDate === '' ? 'active' : 'inactive');
        formdata.append('end_date', endDate)
        showSpinner()
        if(_.isEmpty(experience)) {
            dispatch(saveExperienceAction(formdata))
        } else {
            dispatch(editExperienceAction(experience.id, formdata))
        }
    }

    const buttonDisabled = title === '' || company === '' || startDate === '';

    return (
        <Modal className="experience-modal modal-lg" show={show} onHide={onHide}>
            <Modal.Header>
                <div className="modal-title">Add Experience</div>
                <div className="close" onClick={onHide}>Close</div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper row">
                    <div className="form-group group-2 col-lg-6">
                        <label>Title</label>
                        <input type="text" className="form-control" onChange={(e) => setTitle(e.target.value)} value={title}/>
                    </div>
                    <div className="form-group group-2 col-lg-6">
                        <label>Organization</label>
                        <input type="text" className="form-control" onChange={(e) => setCompany(e.target.value)} value={company}/>
                    </div>
                    <div className="form-group col-lg-6 group-2">
                        <label>Start Date</label>
                        <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} value={startDate}/>
                    </div>
                    <div className="form-group col-lg-6 group-2">
                        <label>End Date <small style={{color:"gray"}}>(Do not fill if you currently work here)</small></label>
                        <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} value={endDate}/>
                    </div>
                    <div className="form-group group-2">
                        <label>Description</label>
                        <TextEditor editorRef={editorRef} value={description}/>
                    </div>
                    <div className="modal-btns">
                        <div className={`submit-btn center-item ${buttonDisabled || loading ? 'disabled' : ''}`} onClick={() => submit()}>Save</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}