import React, { useEffect, useState } from "react";
import './style.scss';
import Countdown from "react-countdown";
import { AttentionTestModal } from "./AttentionTestModal";
import { useDispatch, useSelector } from "react-redux";
import { toggleAttentionTestModalAction, updateProfileAction } from "src/Redux/Actions";
import SubmitArea from "../SubmitArea";
import { hideSpinner, showSpinner } from "src/Utils/Helper";

export default function AttentionToDetails() {
    const [score, setScore] = useState(null)
    const [time, setTime] = useState(0)
    const dispatch = useDispatch()
    const {
        user,
        showAttentionTest,
        updateProfile_loading,
        updateProfile_success
    } = useSelector(state => state.Auth)

    useEffect(() => {
        if(!updateProfile_loading)hideSpinner()
        if(updateProfile_success) {
            setTime(getWaitTime())
        }
    }, [dispatch, updateProfile_loading, updateProfile_success])

    const getWaitTime = () => {
        if(user.attention_test_wait === null) return 0;
        let futureDate = new Date(user.attention_test_wait)
        let secondsLeft = Math.floor((futureDate - (new Date())) / 1000);
        return secondsLeft * 1000
    }

    const StartBtn = () => {
        return (
            <div className="btn-wrapper d-f j-c">
                <div className="start-test-btn" onClick={() => dispatch(toggleAttentionTestModalAction(true))}>Start Attention to details Test</div>
            </div>
        )
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          return <StartBtn />
        } else {
          return <span className="count-down">{hours}:{minutes}:{seconds}</span>;
        }
    };

    const evaluateTest = (answers) => {
        let correctAnswers = answers.filter(item => item.is_correct === 1)
        let score = Math.ceil((correctAnswers.length / (answers.length)) * 100)
        showSpinner()
        const formdata = new FormData()
        if(score >= 50)formdata.append("action", "passed_test")
        else formdata.append("action", "failed_test")
        formdata.append("score", score)
        dispatch(updateProfileAction(formdata))
        setScore(score)
    }

    const ResultsScreen = () => {
        return (
            <div className="results d-f f-c j-c a-c">
                {
                    score >= 50 && (
                        <div className="passed">
                        <h2>Congratulations!</h2>
                        <div className="msg">You’ve successfully completed the test.</div>
                        <small>Score:</small>
                        <div className="score green">{score}%</div>
                    </div>
                    )
                }
                {
                    score < 50 && (
                        <div className="failed">
                            <h2>Better Luck Next Time!</h2>
                            <div className="msg">Sorry, you failed the test. Try again later.</div>
                            <small className="retry">Score:</small>
                            <div className="score red">{score}%</div>
                            <div className="waiting">
                                <small className="me-2">Retry in:</small>
                                <Countdown date={Date.now() + getWaitTime()} renderer={renderer} onComplete={() => setTime(0)} />
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }

    return (
        <div className="attention-to-details">
            <h1 className="text-center">Attention to details test</h1>
            <div className="form-cotent d-f f-c j-c flex-1 w-100" style={{minHeight:"65vh"}}>
                { score !== null && <ResultsScreen /> }
                { score === null && ( 
                    <div className="waiting d-f j-c">
                        { getWaitTime() > 0 && <small className="me-2">Retry in:</small>}
                        <Countdown date={Date.now() + getWaitTime()} renderer={renderer} onComplete={() => setTime(0)}/>
                    </div>
                )}
            </div>
            <AttentionTestModal 
                show={showAttentionTest} 
                onComplete={(values) => evaluateTest(values)}
                onHide={() => dispatch(toggleAttentionTestModalAction(false))}/>
            <SubmitArea disabled={false} />
        </div>
    )
}