import { takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { DELETE_CONTENT, DELETE_CONTENT_FAIL, DELETE_CONTENT_SUCCESS, EDIT_CERTIFICATE, EDIT_CERTIFICATE_FAIL, EDIT_CERTIFICATE_SUCCESS, EDIT_EXPERIENCE, EDIT_EXPERIENCE_FAIL, EDIT_EXPERIENCE_SUCCESS, EDIT_SKILL, EDIT_SKILL_FAIL, EDIT_SKILL_SUCCESS, GET_JOBS, GET_JOBS_FAIL, GET_JOBS_SUCCESS, GET_LOCATIONS, GET_LOCATIONS_FAIL, GET_LOCATIONS_SUCCESS, GET_PROFILE_CONTENT, GET_PROFILE_CONTENT_FAIL, GET_PROFILE_CONTENT_SUCCESS, GET_QUIZ_QUESTIONS, GET_QUIZ_QUESTIONS_SUCCESS, GET_TEST_QUESTIONS, GET_TEST_QUESTIONS_SUCCESS, GET_USER, GET_USER_FAIL, GET_USER_SUCCESS, LOGIN, LOGIN_FAIL, LOGIN_SUCCESS, SAVE_CERTIFICATE, SAVE_CERTIFICATE_FAIL, SAVE_CERTIFICATE_SUCCESS, SAVE_EXPERIENCE, SAVE_EXPERIENCE_FAIL, SAVE_EXPERIENCE_SUCCESS, SAVE_INFO, SAVE_INFO_FAIL, SAVE_INFO_SUCCESS, SAVE_SKILL, SAVE_SKILL_FAIL, SAVE_SKILL_SUCCESS, SAVE_TAGS, SAVE_TAGS_FAIL, SAVE_TAGS_SUCCESS, SAVE_TOOLS, SAVE_TOOLS_FAIL, SAVE_TOOLS_SUCCESS, SIGNUP, SIGNUP_FAIL, SIGNUP_SUCCESS, UPDATE_PROFILE, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS } from '../Type';

import {
    savePersonalInfo as savePersonalInfoApi,
    register as registerApi,
    getUser as getUserApi,
    getProfile as getProfileApi,
    saveExperience as saveExperienceApi,
    editExperience as editExperienceApi,
    saveCertificates as saveCertificatesApi,
    editCertificates as editCertificatesApi,
    saveSkills as saveSkillsApi,
    editSkills as editSkillsApi,
    deleteContent as deleteContentApi,
    saveTags as saveTagsApi,
    saveTools as saveToolsApi,
    updateProfile as updateProfileApi,
    getTestQuestions as getTestQuestionsApi,
    getQuizQuestions as getQuizQuestionsApi,
    getLocations as getLocationsApi,
    login as loginApi,
    getJobs as getJobsApi
} from 'src/Api/Auth';

function* savePersonalInfo(payload) {
  try {
      const result = yield savePersonalInfoApi(payload.data)
      console.log(result)
      if (result && result.data) {
        toast("Personal Info saved")
        yield put({ type: SAVE_INFO_SUCCESS, data: result.data });
      } else {
        toast(result.message ?? "Sorry, we encountered an error, please try again later.")
        yield put({ type: SAVE_INFO_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
  
export function* watchSavePersonalInfo() {
  yield(takeLatest(SAVE_INFO, savePersonalInfo))
}

function* register(payload) {
    try {
        const result = yield registerApi(payload.data)
        if (result && result.token) {
            // sessionStorage.setItem("token", result.token)
          yield put({ type: SIGNUP_SUCCESS, data: result });
        } else {
            if(result && result.message)toast(result.message)
          yield put({ type: SIGNUP_FAIL, data: result });
        }
      } catch (err) {
        alert(err);
      }
} 
export function* watchRegister() {
    yield(takeLatest(SIGNUP, register))
}

function* login(payload) {
  try {
      const result = yield loginApi(payload.data)
      if (result && result.token) {
          // sessionStorage.setItem("token", result.token)
        yield put({ type: LOGIN_SUCCESS, data: result });
      } else {
        if(result && result.message)toast(result.message)
        if(result && result.error)toast(result.error)
        yield put({ type: LOGIN_FAIL, data: result });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchLogin() {
  yield(takeLatest(LOGIN, login))
}

function* getUser() {
  try {
      const result = yield getUserApi()
      if (result && result.data) {
        yield put({ type: GET_USER_SUCCESS, data: result.data });
      } else {
        yield put({ type: GET_USER_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchGetUser() {
  yield(takeLatest(GET_USER, getUser))
}

function* getProfile() {
  try {
      const result = yield getProfileApi()
      if (result && result.data) {
        yield put({ type: GET_PROFILE_CONTENT_SUCCESS, data: result.data });
      } else {
        yield put({ type: GET_PROFILE_CONTENT_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchGetProfile() {
  yield(takeLatest(GET_PROFILE_CONTENT, getProfile))
}
function* saveExperience(payload) {
  try {
      const result = yield saveExperienceApi(payload.data)
      console.log(result)
      if (result && result.data) {
        toast("Experience saved successfully!")
        yield put({ type: SAVE_EXPERIENCE_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: SAVE_EXPERIENCE_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchSaveExperience() {
  yield(takeLatest(SAVE_EXPERIENCE, saveExperience))
}
function* editExperience(payload) {
  try {
      const result = yield editExperienceApi(payload.data.id, payload.data.data)
      console.log(result)
      if (result && result.data) {
        toast("Experience saved successfully!")
        yield put({ type: EDIT_EXPERIENCE_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: EDIT_EXPERIENCE_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchEditExperience() {
  yield(takeLatest(EDIT_EXPERIENCE, editExperience))
}
function* saveCertificates(payload) {
  try {
      const result = yield saveCertificatesApi(payload.data)
      console.log(result)
      if (result && result.data) {
        toast("Cerfitication saved successfully!")
        yield put({ type: SAVE_CERTIFICATE_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: SAVE_CERTIFICATE_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchSaveCertificates() {
  yield(takeLatest(SAVE_CERTIFICATE, saveCertificates))
}
function* editCertificates(payload) {
  try {
      const result = yield editCertificatesApi(payload.data.id, payload.data.data)
      console.log(result)
      if (result && result.data) {
        toast("Cerfitication saved successfully!")
        yield put({ type: EDIT_CERTIFICATE_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: EDIT_CERTIFICATE_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchEditCertificates() {
  yield(takeLatest(EDIT_CERTIFICATE, editCertificates))
}
function* saveSkills(payload) {
  try {
      const result = yield saveSkillsApi(payload.data)
      console.log(result)
      if (result && result.data) {
        toast("Skill saved successfully!")
        yield put({ type: SAVE_SKILL_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: SAVE_SKILL_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchSaveSkills() {
  yield(takeLatest(SAVE_SKILL, saveSkills))
}
function* editSkills(payload) {
  try {
      const result = yield editSkillsApi(payload.data.id, payload.data.data)
      console.log(result)
      if (result && result.data) {
        toast("Skill saved successfully!")
        yield put({ type: EDIT_SKILL_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: EDIT_SKILL_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchEditSkills() {
  yield(takeLatest(EDIT_SKILL, editSkills))
}
function* deleteContent(payload) {
  try {
      const result = yield deleteContentApi(payload.data.id, payload.data.data)
      console.log(result)
      if (result && result.data) {
        yield put({ type: DELETE_CONTENT_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: DELETE_CONTENT_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchDeleteContent() {
  yield(takeLatest(DELETE_CONTENT, deleteContent))
}
function* saveTools(payload) {
  try {
      const result = yield saveToolsApi(payload.data)
      console.log(result)
      if (result && result.data) {
        toast("Tools saved successfully!")
        yield put({ type: SAVE_TOOLS_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: SAVE_TOOLS_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchSaveTools() {
  yield(takeLatest(SAVE_TOOLS, saveTools))
}
function* saveTags(payload) {
  try {
      const result = yield saveTagsApi(payload.data)
      console.log(result)
      if (result && result.data) {
        toast("Roles saved successfully!")
        yield put({ type: SAVE_TAGS_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: SAVE_TAGS_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchSaveTags() {
  yield(takeLatest(SAVE_TAGS, saveTags))
}
function* updateProfile(payload) {
  try {
      const result = yield updateProfileApi(payload.data)
      console.log(result)
      if (result && result.data) {
        toast("Profile updated successfully!")
        yield put({ type: UPDATE_PROFILE_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        yield put({ type: UPDATE_PROFILE_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchUpdateProfile() {
  yield(takeLatest(UPDATE_PROFILE, updateProfile))
}
function* getTestQuestions() {
  try {
      const result = yield getTestQuestionsApi()
      console.log(result)
      if (result && result.data) {
        yield put({ type: GET_TEST_QUESTIONS_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        // yield put({ type: UPDATE_PROFILE_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchGetTestQuestions() {
  yield(takeLatest(GET_TEST_QUESTIONS, getTestQuestions))
}
function* getQuizQuestions() {
  try {
      const result = yield getQuizQuestionsApi()
      console.log(result)
      if (result && result.data) {
        yield put({ type: GET_QUIZ_QUESTIONS_SUCCESS, data: result.data });
      } else {
        toast("Sorry, we encountered an error, please try again later.")
        // yield put({ type: UPDATE_PROFILE_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchGetQuizQuestions() {
  yield(takeLatest(GET_QUIZ_QUESTIONS, getQuizQuestions))
}
function* getLocations() {
  try {
      const result = yield getLocationsApi()
      if (result && result.data) {
        yield put({ type: GET_LOCATIONS_SUCCESS, data: result.data });
      } else {
        yield put({ type: GET_LOCATIONS_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchGetLocations() {
  yield(takeLatest(GET_LOCATIONS, getLocations))
}
function* getJobs() {
  try {
      const result = yield getJobsApi()
      if (result && result.data) {
        yield put({ type: GET_JOBS_SUCCESS, data: result.data });
      } else {
        yield put({ type: GET_JOBS_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}
export function* watchGetJobs() {
  yield(takeLatest(GET_JOBS, getJobs))
}