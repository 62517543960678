import { useDispatch } from 'react-redux';
import './style.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleSideMenu } from 'src/Redux/Actions';

export default function MobileActionButton() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const excludedPaths = ['/va/request-VA', '/complete-profile']
    const handleClick = () => {
        // Handle click event
        navigate('/va/request-VA')
        dispatch(toggleSideMenu(false))
    }
    return (
        <div className={`wrapper ${excludedPaths.includes(location.pathname)  ? 'hide': ''}`}>
            <div className={`call-btn ${excludedPaths.includes(location.pathname)  ? 'hide': ''}`} onClick={() => handleClick()}>BOOK DISCOVERY CALL</div>
        </div>
    )
}
