
import {
    TOGGLE_SIDE_MENU,
    GET_BLOGS,
    GET_BLOGS_FAIL,
    GET_BLOGS_SUCCESS,
    GET_BLOG,
    GET_BLOG_SUCCESS,
    GET_BLOG_FAIL,
    GET_TOPICS,
    GET_TOPICS_SUCCESS,
    GET_TOPICS_FAIL,
    TOGGLE_CALENDLY_MODAL,
    SUBMIT_CONTACT,
    SUBMIT_CONTACT_SUCCESS,
    SUBMT_CONTACT_FAIL,
    GET_TOPIC_BLOGS,
    GET_TOPIC_BLOGS_SUCCESS,
    GET_TOPIC_BLOGS_FAIL,
    TOGGLE_INTERVIEW_MODAL,
    REQUEST_INTERVIEW,
    REQUEST_INTERVIEW_SUCCESS,
    REQUEST_INTERVIEW_FAIL,
    TOGGLE_VIDEO_MODAL,
    TOGGLE_REVIEW_MODAL,
    SET_OPEN_REVIEW,
    REQUEST_VA,
    REQUEST_VA_SUCCESS,
    REQUEST_VA_FAIL,
} from '../Type'

const INITIAL = {
    sideMenuOpen: false,
    posts: null,
    getBlogs_loading: false,
    post: null,
    getPost_loading: false,
    topics: null,
    topicPosts: null,
    getTopicPosts_loading: false,
    isCalendlyOpen: false,
    submitContact_loading: false,
    showInterviewModal: false,
    requestInterview_loading: false,
    requestInterview_success: false,
    showVideoModal: false,
    video: null,
    showReviewModal: false,
    review: null,
    requestVALoading: false,
    requestVASuccess: false
}

export default (state = INITIAL, action) => {
    switch(action.type) {
        case TOGGLE_SIDE_MENU: {
            const { isOpen } = action.data
            return {
                ...state,
                sideMenuOpen: isOpen
            }
        }
        case GET_BLOGS: {
            return {
                ...state,
                getBlogs_loading: true
            }
        }
        case GET_BLOGS_SUCCESS: {
            return {
                ...state,
                posts: action.data,
                getBlogs_loading: false
            }
        }
        case GET_BLOGS_FAIL: {
            return {
                ...state,
                getBlogs_loading: false
            }
        }
        case GET_BLOG: {
            return {
                ...state,
                post: null,
                getPost_loading: true
            }
        }
        case GET_BLOG_SUCCESS: {
            return {
                ...state,
                post: action.data,
                getPost_loading: false
            }
        }
        case GET_BLOG_FAIL: {
            return {
                ...state,
                getPost_loading: false
            }
        }
        case GET_TOPICS: {
            return {
                ...state,
            }
        }
        case GET_TOPICS_SUCCESS: {
            return {
                ...state,
                topics: action.data
            }
        }
        case GET_TOPICS_FAIL: {
            return {
                ...state,
            }
        }
        case TOGGLE_CALENDLY_MODAL: {
            const { isOpen } = action.data
            return {
                ...state,
                isCalendlyOpen: isOpen
            }
        }
        case SUBMIT_CONTACT: {
            return {
                ...state,
                submitContact_loading: true,
            }
        }
        case SUBMIT_CONTACT_SUCCESS: {
            return {
                ...state,
                submitContact_loading: false,
            }
        }
        case SUBMT_CONTACT_FAIL: {
            return {
                ...state,
                submitContact_loading: false,
            }
        }
        case GET_TOPIC_BLOGS: {
            return {
                ...state,
                topicPosts: null,
                getTopicPosts_loading: true
            }
        }
        case GET_TOPIC_BLOGS_SUCCESS: {
            return {
                ...state,
                getTopicPosts_loading: false,
                topicPosts: action.data
            }
        }
        case GET_TOPIC_BLOGS_FAIL: {
            return {
                ...state,
                getTopicPosts_loading: false,
            }
        }
        case TOGGLE_INTERVIEW_MODAL: {
            const { isOpen } = action.data
            return {
                ...state,
                showInterviewModal: isOpen
            }
        }
        case REQUEST_INTERVIEW: {
            return {
                ...state,
                requestInterview_loading: true,
                requestInterview_success: false
            }
        }
        case REQUEST_INTERVIEW_SUCCESS: {
            return {
                ...state,
                requestInterview_loading: false,
                requestInterview_success: true,
                showInterviewModal: false
            }
        }
        case REQUEST_INTERVIEW_FAIL: {
            return {
                ...state,
                requestInterview_loading: false
            }
        }
        case TOGGLE_VIDEO_MODAL: {
            const { isOpen, video } = action.data
            return {
                ...state,
                showVideoModal: isOpen,
                video: video
            }
        }
        case TOGGLE_REVIEW_MODAL:
            const { isOpen } = action.data
            return {
                ...state,
                showReviewModal: isOpen
            }
        case SET_OPEN_REVIEW:
            return {
                ...state,
                review: action.data
            }
        case REQUEST_VA:
            return {
                ...state,
                requestVALoading: true,
                requestVASuccess: false
            }
        case REQUEST_VA_SUCCESS: 
            return {
                ...state,
                requestVALoading: false,
                requestVASuccess: true
            }
        case REQUEST_VA_FAIL: 
            return {
                ...state,
                requestVALoading: false
            }
        default: 
            return state
    }
}