
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";
import { useState } from 'react';
import client1 from "src/Assets/Images/Adwords/client1.png";
import client2 from "src/Assets/Images/Adwords/client2.png";
import client3 from "src/Assets/Images/Adwords/client3.png";
import client4 from "src/Assets/Images/Adwords/client4.png";
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVideoModal } from 'src/Redux/Actions';
import circleRight from 'src/Assets/Images/Adwords/iconRight.png';
import circleLeft from 'src/Assets/Images/Adwords/iconLeft.png';
import star from 'src/Assets/Images/Adwords/star.png';

const playIconImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAaVBMVEUyKDIAAAD5+Pn////////c2tzd291lX2X///////+Ig4j9/f3y8fL////Lycvj4uP////q6Oqxr7H///+xrLGgnKD////////////MyszAvcD///////////////////////+em57///8U66MHAAAAInRSTlMzAObgv5mZQCAQTPLMQH+mz7JmcGZZn1CAgHOvkI9gf29ZYTSjpAAAAgVJREFUSMet1+uanCAMBuAIKCAgKp7m2Nb7v8i6ZVtnZyDIbL//72PkkZhAEYyc3dTSdQtll8XIIhgIyR9MaduMNWyp+3OpFXPiEDZM2Z7D1/QdYQbFnuqGQygnQmcUC0b6V7bzScTxT1pxwFLRewTLGxkhkZrcgli2HYdkuG3FKxa0wtleuthx0OJaPuF2t2ndyi/4ZiEj3e0R3wnPwZy4HQtaQ1ZGKv7hqYLMVOwvngnkhhPziWkD2emZx4bAG9HmD2ahBw/lgOMT+8CChI5zXdeuxjBXcsOug9eU60fQC2qXDbMhilfSoEcGUkEEe14jdYPRGMZeXc+wlAm8qiqMrYPLGcPYqzcTsAHHPjpQ+9gCrTGMvXpNYQUE47Wvx/GqvoPJN7AKlH30wCwPHFg7HsG6D33cMDVpTIIf0vkCzqawitzMcoH5/YthQCqOYd1DLErizUAlmkGx2BhWaBvq3IaDdff+JmEhItp6x7I/0noLo+GNEN/0/ZFl5kQLjw3h2ZjMn7hg+b/Y6eHnPubZmop9rHC5Y8X9caC5djnYXotHLPNGqac5TOYMceK/jY9et5anKe9aGZy3r6RO2V/kKiPD+j1ROq+oQ9aECZsFBsIEuqDMNML5STNzYDUi3fAsB6vQ1WiPcEzp8jz4pWxsrFZskamlbI80y4X5dbCd3BxZB38DEqA4oLQzim8AAAAASUVORK5CYII=";

export default function ClientReviews() {
    const dispatch = useDispatch()
    
    const {
        showVideoModal,
        video
    } = useSelector(state => state.Home)

    const handleVideoClick = (open, video) => {
        let data = { isOpen: open, video: video }
        dispatch(toggleVideoModal(data))
    }

    return (
        <div className="client-reviews">
            <div className="container">
                <div className="padding">
                    <h2 className='pb-5'>Client Reviews</h2>
                    <div className='section-content'>
                        <ShowcaseCarousel onClick={(video) => handleVideoClick(true, video)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClientReviewItem = ({item, onClick}) => {

    return (
        <div className='client-review-item' onClick={onClick}>
            <div className='img-wrapper'>
                <img className='play-icon' src={playIconImg}/>
                <img className='thumb' src={item.thumb}/>
            </div>
            <div className='info'>
                <div className='name'>{item.name}</div>
                <div className='practice'>{item.practice}</div>
                <div className='stars d-f a-c j-c mt-3 '>
                    {
                        Array(5).fill(true).map((item2, index) => (
                            <img src={star} key={index}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}  

const ShowcaseCarousel = ({onClick}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <Media
            queries={{
                lg: "(min-width: 1655px)",
                md: "(min-width: 1360px) and (max-width: 1654px)",
                sm: "(min-width: 1032px) and (max-width: 1359px)",
                normal: "(max-width: 1031px)",
            }}
        >
        {(matches) => (
            <OwlCarousel
                className="owl-theme"
                key={999}
                stagePadding={
                    matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                }
                margin={
                    matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                }
                items={matches.lg ? 3 : matches.md ? 2 : matches.sm ? 1 : 1}
                navText={[`<img src='${circleLeft}'/>`, `<img src='${circleRight}'/>`]}
                nav={true}
                dots={false}
                // autoplay
                autoplayHoverPause
                autoplayTimeout={1000}
                startPosition={currentIndex}
                onTranslated={(event) => {
                    setCurrentIndex(event.item.index);
                }}
                >
                    {
                        clientVideos.map((item, index) => (
                            <ClientReviewItem key={index} item={item} onClick={() => onClick(item.video)}/>
                        ))
                    }
                </OwlCarousel>
            )}
        </Media>
    )
}

const clientVideos = [
    {
        name: "Dr. Sosunmolu Shoyinka, MD",
        practice: "Founder at Centia Health",
        video: "https://muse.ai/vc/Qm4ydjQ",
        thumb: client2
    },
    {
        name: "Ms. Niya Hansen",
        video: "https://muse.ai/vc/TAe2Gt2",
        practice: "Practice Administrator at Value Dental, NJ",
        thumb: client4
    },
    {
        name: "Dr Winnie Bunch",
        video: "https://muse.ai/vc/zdfHeRJ",
        practice: "Owner at Fresh Wind Mental Health",
        thumb: client3
    },
    {
        name: "Mr. Aaron Cockburn",
        practice: "Owner at A Grade Therapy",
        video: "https://muse.ai/vc/chECJA4",
        thumb: client1
    },
]