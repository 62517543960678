import { InlineWidget } from 'react-calendly';
import dutyImg4 from 'src/Assets/Images/VmrHome/Duty4.svg';
import '../RequestVA/style.scss';

export default function ScheduleDiscovery() {

    return (
        <div className='request-va center'>
            <div className='container d-f j-c'>
                <div className='row w-100'>
                    <div className='col-lg-6 left-section'>
                        <div className='img-wrapper d-f j-c h-100'>
                            <img src={dutyImg4} alt='request VA img'/>
                        </div>
                    </div>
                    <div className='col-lg-6 right-section'>
                        <div className='title'>Thank you for requesting a discovery call with Honest Taskers.</div>
                        <h2>Please use the calendar below to schedule a discovery call at a time that works best for you.</h2>
                        <div className='calender-picker'>
                            <InlineWidget url="https://calendly.com/honesttaskers/30min" />
                        </div>
                        <div className='action-row d-f a-c'>
                            <div className={`back center disabled`}>
                                <span className='fa fa-angle-left'></span>Back
                            </div>
                            <div className='continue center' onClick={() => window.history.back()}>Done</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}