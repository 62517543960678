import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags';
import './style.scss';

export default function PhoneComponent({ 
    value, 
    onChange, 
    placeholder = "Enter phone number", 
    defaultCountry="US", 
    showLabel=false,
    className=""
}){
    return (
        <>
            { showLabel && <label className="input-comp-label">{placeholder}</label>}
            <div className={`phone-component ${className}`}>
                <PhoneInput
                    defaultCountry={defaultCountry}
                    placeholder={placeholder}
                    value={value}
                    flags={flags}
                    international={true}
                    withCountryCallingCode={true}
                    onChange={onChange}/>
            </div>
        </>
    )
}