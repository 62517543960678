import React, { useEffect, useState } from "react";
import { InputComponent } from "src/Components/Common";
import SubmitArea from "../SubmitArea";
import { useDispatch, useSelector } from "react-redux";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { updateProfileAction } from "src/Redux/Actions";

export default function HIPAA({user}) {
    const dispatch = useDispatch();
    const [url, setUrl] = useState(user.hippa_certificate)

    const btnEnabled = user.hippa_certificate && user.hippa_certificate.length > 0
    const {
        updateProfile_loading, 
        updateProfile_success
    }  = useSelector(state => state.Auth)

    useEffect(() => {
        if(!updateProfile_loading)hideSpinner()
        if(updateProfile_success) {
            console.log("hippa updated")
        }
    }, [dispatch, updateProfile_loading, updateProfile_success])

    const saveHippa = () => {
        if(url === '')return;
        const formdata = new FormData()
        formdata.append("action", "hippa_certificate");
        formdata.append("hippa_certificate", url)
        showSpinner()
        dispatch(updateProfileAction(formdata))
    }

    return (
        <div className="hipaa-component">
            <h1>HOW TO COMPLETE HIPAA CERTIFICATION TRAINING</h1>
            <div className="form-content mt-5">
                <div className="">If you haven't completed your HIPAA training and certification yet, please follow these steps below. </div>
                <ul className="list-style-none mt-3">
                    <li>Go to the training website. <a href="https://www.accountablehq.com/" target="__blank">https://www.accountablehq.com/</a></li>
                    <li>Create an account using your Gmail account. </li>
                    <li>Click on "Training." </li>
                    <li>Select "HIPAA Training." </li>
                    <li>Begin your training. </li>
                </ul>
                <div className="mt-4">After finishing the training, upload a screenshot of your completion proof to Google Drive or Dropbox. Then, share the link with us, and we will issue your certificate.</div>
                <div className="mt-5 mb-2" style={{fontSize:"20px",fontWeight:"bold"}}>Submit your HIPAA certificate</div>
                <InputComponent value={url} onChange={(value) => setUrl(value)}/>
                <div className="d-f j-c w-100 my-5">
                    <div className={`save-btn center-item ${url === '' ? 'disabled': ''}`} onClick={() => saveHippa()}>Submit HIPPA link</div>
                </div>
            </div>
            <SubmitArea disabled={!btnEnabled} />
        </div>
    )
}