import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SelectComponent } from "src/Components/Common";
import { saveToolsAction } from "src/Redux/Actions";
import { showSpinner } from "src/Utils/Helper";

export const ToolsComponent = ({profile}) => {
    const [tools, setTools] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        setTools(profile.user_tools.map((item) => {return { value: item.id, label: item.name }}))
    }, [])

    const saveTools = () => {
        if(tools.length === 0) {
            toast("No tools selected")
            return;
        }
        const formdata = new FormData()
        formdata.append("tools", tools.map(item => item.value).join(","))
        showSpinner()
        dispatch(saveToolsAction(formdata))
    } 

    return (
        <div className="item-list">
            <div className="sec-title">Select the tools you have experience using</div>
            {/* <div className="btn-row">
                <div className="add-btn" ><span className="fa fa-plus me-2"></span>Add Skill</div>
            </div> */}
            <div className="form-group">
                <SelectComponent key={tools} value={tools} options={profile.tools.map((item) => {return { value: item.id, label: item.name}})} onChange={(value) => setTools(value)} multiple={true} searchable={true}/>
            </div>
            <div className="modal-btns mt-5 d-f j-c">
                <div className="save-btn center-item" onClick={() => saveTools()}>Save</div>
            </div>
        </div>
    )
}