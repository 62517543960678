import React from "react";
// import { useDispatch } from "react-redux";
import { Benefits, FAQs, Footer2, GetStarted2, Pricing, VMCoderHomeIntro, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans, pricingTitle } from "src/Constant";
// import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function VMedicalCoderHomepage() {
    // const dispatch = useDispatch()
    const navigate = useNavigate()

    const benefits = [
        {
            title: "Accuracy and Compliance: ",
            message: "Our coders ensure your coding is accurate, reducing claim denials and boosting revenue.",
            color: "green"
        },
        {
            title: "Continuous Training:",
            message: "With ongoing education, our coders are always updated with the latest ICD and CPT code changes.",
            color: "green"
        },
        {
            title: "Cost Efficiency:",
            message: "Eliminate the overhead of an in-house coder and enjoy up to 60% savings on labor costs.",
            color: "green"
        },
        {
            title: "Enhanced Productivity:",
            message: "Outsource the coding tasks and let your team focus on core medical responsibilities.",
            color: "green"
        },
        {
            title: "Scalability:",
            message: "Whether you’re a growing practice or an established hospital, our scalable solutions fit all.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: "Accurate Code Assignments:",
            message: "Using the latest ICD and CPT manuals to ensure precise coding."
        },
        {
            title: "Claim Review:",
            message: "Before submission, ensuring all codes align with medical documentation."
        },
        {
            title: "Stay Updated:",
            message: "Regularly updating knowledge base with coding changes and healthcare regulations."
        },
        {
            title: "Coordination:",
            message: "Collaborating with healthcare providers for any code-related clarifications."
        },
        {
            title: "Audit Support:",
            message: "Assisting during audits by providing necessary coding documentation."
        }
    ]

    const whyChooseList = [
        {
            title: "Certified Professionals:",
            message: "All our coders are certified, ensuring expertise in medical coding."
        },
        {
            title: "Secure Data Handling:",
            message: "Patient data is of utmost importance; we maintain stringent data security protocols."
        },
        {
            title: "Dedicated Support:",
            message: "We provide round-the-clock support for any coding related queries."
        },
        {
            title: "Flexible Engagements:",
            message: "Choose from hourly, part-time, or full-time coder engagements based on your needs."
        },
        {
            title: "Consistent Quality:",
            message: "We maintain rigorous quality checks, ensuring coding accuracy remains consistently high."
        }
    ]

    const faqs = [
        {
            question: "Are your coders certified?",
            answer: "Absolutely! All our medical coders are certified and undergo regular training to stay updated."
        },
        {
            question: "How do you ensure data security?",
            answer: "We strictly adhere to HIPAA guidelines and use secure encrypted channels for data transfer and storage."
        },
        {
            question: "Can I hire a coder for short-term needs?",
            answer: "Yes, we offer flexible engagements ranging from hourly to long-term based on your requirements."
        },
        {
            question: "What specialties do your coders cater to?",
            answer: "Our coders are versatile and cater to a wide range of medical specialties."
        }
    ]

    const meta_title = "Expert Virtual Medical Coders | Save 60% with Honest Taskers"
    const meta_description = "Maximize accuracy and cost-efficiency in your medical coding. Choose Honest Taskers’ Virtual Medical Coders for over 60% savings and unmatched precision. Connect now!"
    const meta_image = "https://honesttaskers.com/vms_image.png"

    return  (
        <div className="vmc-landingpage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-medical-coder"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <VMCoderHomeIntro onGetStarted={() => navigate('/va/request-VA')}/>
            <Benefits subTitle={"Of Hiring a Remote Medical Coding Specialist"} benefits={benefits} />
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities:"}/>
            <WhyChooseUs subTitle={"HonestTaskers' Virtual Medical Coders?"} whyChoose={whyChooseList}/>
            <FAQs faqs={faqs}/>
            <Pricing subTitle={pricingTitle} hasColor={true} plans={getPricingPlans('pricing-two')}/>
            <GetStarted2 
                title="Take the leap towards efficient, accurate, and cost-effective medical coding." 
                btnText={"Get Your Virtual Medical Coder Today!"}
                onGetStarted={() => navigate('/va/request-VA')}
            />
            <Footer2 />
        </div>
    )
}