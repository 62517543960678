import React, { useEffect, useRef, useState } from "react";
import { InputComponent } from "src/Components/Common";
import SubmitArea from "../SubmitArea";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { updateProfileAction } from "src/Redux/Actions";

export default function VideoInterview({user}) {
    const dispatch = useDispatch()
    const iframeRef = useRef(null)
    const [video, setVideo] = useState(user.video)

    const {
        updateProfile_loading,
        updateProfile_success
    } = useSelector(state => state.Auth)

    useEffect(() => {
        if(!updateProfile_loading)hideSpinner()
    }, [dispatch, updateProfile_loading, updateProfile_success])

    const handleSubmit = () => {
        if(video === '') {
            toast("Please enter video link")
            return
        }
        showSpinner()
        const formData = new FormData()
        formData.append("video_interview", video)
        formData.append("action", "video_interview")
        dispatch(updateProfileAction(formData))
    }

    return (
        <div className="video-interview">
            <h1 className="text-center">Video Interview</h1>
            <div className="video-wrapper mt-5">
                <iframe src={"https://muse.ai/vc/3Sr9cYa"} 
                    ref={iframeRef}
                    style={{ width:"100%", height:"500px" }}
                    frameBorder="0" 
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen></iframe>
            </div>
            <div className="mt-5 f-bold">🎥 Video Interview Instructions</div>
            <div className="f-16">As part of your application with Honest Taskers, please record a short video answering the questions below. This helps us get to know you better and assess your communication skills.</div>
            <div className="f-bold mt-3">✅ Questions to Answer in Your Video:</div>
            <ul style={{listStyleType: "numeric",fontSize:"16px"}}>
                <li>What is your full name?</li>
                <li>What is your educational background?</li>
                <li>Can you share your work experience in your country?</li>
                <li>Do you have work experience with a foreign company?
                    <ul style={{listStyleType: "disc"}}>
                        <li>If yes, please tell us about it.</li>
                        <li>If not, share details about any relevant training you’ve completed.</li>
                    </ul>
                </li>
                <li>What tools or EMR systems are you experienced with?</li>
                <li>Why do you think you would be a good fit for Honest Taskers?</li>
                <li>How would the company benefit from having you as part of our team?</li>
                <li>Tell us something interesting about yourself—this is a great opportunity to showcase your personality!</li>
            </ul>
            <div className="f-bold mt-3">📋 Video Guidelines:</div>
            <ul style={{listStyleType: "disc",fontSize:"16px"}}>
                <li>Record using Zoom or any other tool.</li>
                <li>Ensure good lighting and clear video quality.</li>
                <li>Use the Honest Taskers background (<a href="https://drive.google.com/file/d/1u-TzbW6uKgPgPhgZV5zLY0chVrWFlaZI/view?usp=sharing" target="_blank">Attached</a>)</li>
                <li>Upload your video to Google Drive and share a viewable link.</li>
            </ul>
            <div className="f-bold mt-3">⚠️ Important Note</div>
            <div className="f-16">Your application will be <strong>disqualified</strong> if your video <strong>does not meet the required standards</strong>, so please ensure you follow all instructions carefully.</div>

            <div className="mt-4 mb-2" style={{fontSize:"20px",fontWeight:"bold"}}>Enter your video link</div>
            <InputComponent value={video} onChange={(value) => setVideo(value)} />
            {/* {
                user.video && user.video.length > 0 && user.video_status === 'pending' && (
                    <div>
                        <div className="f-bold mt-3 f-16">Thank You for Submitting Your Video Interview!</div>
                        <div className="f-16">We have received your video interview and will <strong>review it within 1 to 48 hours</strong>. Once reviewed, we will communicate the next steps via email so you can continue your application process.</div>
                        <div className="f-16">Please check your email regularly for updates. If you have any questions in the meantime, feel free to reach out.</div>
                        <div className="f-16">We appreciate your time and interest in joining our team!</div>
                    </div>
                )
            } */}
            <div className="d-f j-c w-100 my-5">
                <div className={`save-btn center-item ${video === '' ? 'disabled': ''}`} onClick={() => handleSubmit()}>Submit Video</div>
            </div>
            <SubmitArea disabled={false}/>
        </div>
    )
}