import React from "react";
import { Benefits, FAQs, Footer2, GetStarted2, Pricing, RemoteMDSHomeIntro, RemoteMDSIntroduction, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function RemoteMDSHomepage() {
    // const dispatch = useDispatch()
    const navigate = useNavigate()

    const benefits = [
        {
            title: "Enhanced Accuracy:",
            message: "Navigate patient assessments with greater precision and care.",
            color: "green"
        },
        {
            title: "Top-tier Compliance:",
            message: "Remain confidently in line with CMS regulations and avoid potential pitfalls.",
            color: "green"
        },
        {
            title: "Cost-effective Solution:",
            message: "Benefit from expert services without the overheads of in-house hires.",
            color: "green"
        },
        {
            title: "Improved Care Plans:",
            message: "Craft better resident care strategies with immaculate MDS data.",
            color: "green"
        },
        {
            title: "Audit-Ready Always:",
            message: "Step into audits with confidence, backed by impeccable record-keeping.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: "MDS Assessment Management:",
            message: "Complete and review MDS for residents ensuring timely submissions."
        },
        {
            title: "Healthcare Team Collaboration:",
            message: "Work in tandem with the facility's healthcare providers for comprehensive assessments."
        },
        {
            title: "Monitoring Care Strategies:",
            message: "Assess and ensure resident care plans resonate with MDS evaluations."
        },
        {
            title: "Regulatory Compliance:",
            message: "Remain updated on CMS guidelines and facilitate facility-wide adherence."
        },
        {
            title: "Reporting Excellence:",
            message: "Create, evaluate, and submit essential reports to underline compliance and patient care quality."
        }
    ]

    const whyChooseList = [
        {
            title: "Unparalleled Expertise:",
            message: "Lean on our coordinators who come fortified with deep-seated industry knowledge."
        },
        {
            title: "Smooth Integration:",
            message: "Our professionals seamlessly dovetail into your systems, ensuring undisturbed operations."
        },
        {
            title: "Consistency in Service:",
            message: "Enjoy the benefits of stable, high-grade service, thanks to our low staffing turnover."
        },
        {
            title: "Never-ending Learning:",
            message: "We believe in lifelong education, continuously training our coordinators, so they stay ahead in the MDS realm."
        },
        {
            title: "Round-the-clock Support:",
            message: "Questions, clarifications, consultations - we're here whenever you need us."
        }
    ]

    const faqs = [
        {
            question: "How do you ensure the integration of your MDS Coordinators with our existing processes?",
            answer: "Our coordinators engage in an onboarding process, ensuring they’re familiar with your specific systems and protocols."
        },
        {
            question: "Are your coordinators updated with the periodic CMS changes?",
            answer: "Absolutely. Continuous training and regulatory updates are a part of our commitment."
        },
        {
            question: "Can your coordinators manage multi-facility operations?",
            answer: "Yes, we can tailor solutions to cater to multiple facilities under a single entity."
        },
        {
            question: "How do you guarantee data security and patient confidentiality?",
            answer: "Patient data security is our utmost priority. We uphold stringent measures to protect and maintain confidentiality."
        }
    ]

    const meta_title = "Expert Remote MDS Coordinators | 60% Cost Savings - Honest Taskers"
    const meta_description = "Streamline your long-term care facility with Honest Taskers’ Remote MDS Coordinators. Ensure precise MDS management and save over 60% on operational costs. Reach out today"
    const meta_image = "https://honesttaskers.com/vms_image.png"

    return (
        <div className="remote-mds-homepage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/remote-mds-coordinator"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <RemoteMDSHomeIntro onGetStarted={() => navigate('/va/request-VA')}/>
            <RemoteMDSIntroduction />
            <Benefits subTitle={"of Remote MDS Coordinator"} benefits={benefits}/>
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities:"}/>
            <WhyChooseUs subTitle={"HonestTaskers' Remote MDS Coordinators?"} whyChoose={whyChooseList}/>
            <FAQs faqs={faqs} />
            <Pricing hasColor={true} plans={getPricingPlans('no-custom', false, true)} btnText="Contact Us For Pricing"/>
            <GetStarted2 title={"Dive into the Future of Efficient MDS Coordination!"}
                btnText="Engage with Us Today!"
                onGetStarted={() => navigate('/va/request-VA')}/>
            <Footer2 />
        </div>
    )
}