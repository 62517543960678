import React, { useEffect, useRef, useState } from "react";
import './style.scss';
import PricingCost from 'src/Assets/Images/Home/PricingCost.png';
import PricingHours from 'src/Assets/Images/Home/PricingHours.png';
import RadioCheck from 'src/Assets/Images/Home/RadioCheck.png';
import { useLocation, useNavigate } from "react-router-dom";
import _ from 'lodash';
import { useDispatch } from "react-redux";
import { toggleCalendlyModal } from "src/Redux/Actions";

export default function Pricing({plans, subTitle, hasColor = false, btnText = "Sign Up Now", action = 'Schedule'}) {
    const { hash } = useLocation()
    const dispatch = useDispatch()
    const pricingRef = useRef(null)
    const [planIndex, setPlanIndex] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        if (hash === '#pricing') {
            pricingRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [hash])

    function PricingItem({item, index}) {

        const getColor = () => {
            if (!hasColor) return ''
            if (index === 0) return 'purple'
            return index === 1 ? 'blue' : 'yellow'
        }

        const handleClick = () => {
            navigate('/va/request-VA')
        }

        function NormalPlanCard() {
            return  (
                <div className="pricing-item">
                    <div className={`title ${getColor()}`}>{item.title}</div>
                    <div className="info">
                        <div className="info-sec">
                            <div className="info-head">
                                <img src={PricingHours}/>
                                <div>Hours:</div>
                            </div>
                            <div>{item.hours}</div>
                        </div>
                        <div className="info-sec">
                            <div className="info-head">
                                <img src={PricingCost}/>
                                <div>Cost:</div>
                            </div>
                            <div className="cost">{item.cost}</div>
                        </div>
                    </div>
                    <div className="options">
                        {
                            item.options.map((option, index) => (
                                <div className="option" key={index}>
                                    <img src={RadioCheck}/>
                                    <div>{option}</div>
                                </div>
                            ))
                        }
                        <div className="btn btn-orange w-100 mt-4" onClick={() => handleClick()}>{btnText}</div>
                    </div>
                </div>
            )
        }

        return (
            <div className="col-lg-4">
                <NormalPlanCard />
            </div>
        )
    }

    return (
        <div className="pricing" ref={pricingRef}>
            <div className="inner-bg">
                <div className="container">
                    <div className="content">
                        <h2 className={_.isEmpty(subTitle)?'':'has-title'}>Pricing</h2>
                        { subTitle && <div className="sub-title" dangerouslySetInnerHTML={{ __html: subTitle }}></div>}
                        <div className="plan-wrapper">
                            <div className="row">
                                {
                                    plans.map((item, index) => (
                                        <PricingItem item={item} key={index} index={index}/>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="pricing-mobile">
                            <div className="pricing-tabs">
                                <div className={`item ${planIndex === 0 ? 'active': ''}`} onClick={() => setPlanIndex(0)}>Part</div>
                                <div className={`item ${planIndex === 1 ? 'active': ''}`} onClick={() => setPlanIndex(1)}>Full</div>
                                <div className={`item ${planIndex === 2 ? 'active': ''}`} onClick={() => setPlanIndex(2)}>Custom</div>
                            </div>
                            <PricingItem item={plans[planIndex]} index={planIndex}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}