import { all, fork } from 'redux-saga/effects';

import {
    watchGetBlogs,
    watchGetBlog,
    watchGetTopics,
    watchGetTopicBlogs,
    watchRequestInterview,
    watchRequestVA
} from './Home';
import { watchSubmitContact, watchSubmitReceptionContact, watchSubmitRequestCall } from './Contact';
import { watchGetVAProfile } from './Profile';
import { whatchGetCourses } from './Portal';
import { watchSavePersonalInfo, watchRegister, watchGetUser, watchGetProfile, watchSaveExperience, watchEditExperience, watchEditCertificates, watchSaveCertificates, watchEditSkills, watchSaveSkills, watchDeleteContent, watchSaveTags, watchSaveTools, watchUpdateProfile, watchGetTestQuestions, watchGetQuizQuestions, watchGetLocations, watchLogin, watchGetJobs } from './Auth';

export default function* rootSaga() {
    yield all([
      fork(watchGetBlogs),
      fork(watchGetBlog),
      fork(watchGetTopics),
      fork(watchSubmitContact),
      fork(watchSubmitReceptionContact),
      fork(watchGetVAProfile),
      fork(watchGetTopicBlogs),
      fork(watchRequestInterview),
      fork(whatchGetCourses),
      fork(watchSubmitRequestCall),
      fork(watchRequestVA),
      fork(watchSavePersonalInfo),
      fork(watchRegister),
      fork(watchLogin),
      fork(watchGetUser),
      fork(watchGetProfile),
      fork(watchSaveExperience),
      fork(watchEditExperience),
      fork(watchSaveCertificates),
      fork(watchEditCertificates),
      fork(watchSaveSkills),
      fork(watchEditSkills),
      fork(watchDeleteContent),
      fork(watchSaveTags),
      fork(watchSaveTools),
      fork(watchUpdateProfile),
      fork(watchGetTestQuestions),
      fork(watchGetQuizQuestions),
      fork(watchGetLocations),
      fork(watchGetJobs)
    ]);
}