import React, { useEffect } from "react";
import './style.scss';
import { BenefitsOfHiring, HomeIntro, Reviews, Partners, ServicesProvide, OurStory, CaseStudies, Pricing, GetStarted, Channels, FeaturedBlog } from "../../../Components";
import FAQs from "src/Components/Home/FAQs";
import { useDispatch, useSelector } from "react-redux";
import { toggleCalendlyModal } from "src/Redux/Actions";
import { faqs, getPricingPlans } from "src/Constant";
import { OurReviews } from "src/Constant";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";


export default function HomePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        posts
    } = useSelector(state => state.Home)

    const schedule = () => dispatch(toggleCalendlyModal(true))

    const meta_title = "Expert Virtual Medical Assistant Services | Scribing, Admin, Billing & Marketing"
    const meta_description = "Discover top-tier Virtual Medical Assistant services tailored for medical practices. Specializing in scribing, administrative tasks, billing, and marketing solutions. Streamline operations, enhance profitability, and save time with our professional support."
    const meta_image = "https://honesttaskers.com/preview.png"

    const faqStructuralData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(item => {
          return {
          "@type": "Question",
          "name": item.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>"+ item.answer +"</p>"
            }
          }
        })
    }

    return (
        <div className="home-page-wrapper">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.origin} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.origin} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <script type="application/ld+json">{JSON.stringify(faqStructuralData)}</script>
                <link rel="canonical" href="https://www.honesttaskers.com/"></link>
            </Helmet>
           <HomeIntro onGetStarted={() => navigate('/va/request-VA')}/>
           <Partners />
           <ServicesProvide onGetStarted={() => navigate('/va/request-VA')}/>
           <BenefitsOfHiring />
           <Reviews Reviews={OurReviews}/>
           <OurStory />
           <CaseStudies />
           <Pricing plans={getPricingPlans()}/>
           <GetStarted onSpeak={() => navigate('/va/request-VA')}/>
           {/* { posts && <FeaturedBlog posts={posts}/>} */}
            <FAQs faqs={faqs}/>
            {/* <Channels /> */}
        </div>
    )
}