import React from "react";
// import { useDispatch } from "react-redux";
import { Benefits, Footer2, GetStarted2, Pricing, VMedicalBillingHomeIntro, VMedicalBillingIntroduction, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getImageStructuralData, getPricingPlans } from "src/Constant";
// import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";


export default function VMedicalBillingHomepage() {
    // const dispatch = useDispatch()
    const navigate = useNavigate()

    const whyChooseList = [
        {
            title: "Experienced Professionals:",
            message: "Our team is composed of seasoned medical billing professionals who have in-depth knowledge of the healthcare billing landscape."
        },
        {
            title: "Tech-driven Solutions:",
            message: "We integrate human expertise with cutting-edge technology, ensuring that your billing is both personalized and optimized."
        },
        {
            title: "Affordable Excellence:",
            message: "With competitive pricing, we offer cost-saving benefits without compromising on quality."
        }
    ]

    const keyServices = [
        {
            title: "Patient Billing:",
            message: "Prompt and precise billing to ensure transparency and trust."
        },
        {
            title: "Insurance Claims Submission:",
            message: "Timely submissions to guarantee reimbursements without delays."
        },
        {
            title: "Payment Posting:",
            message: "Accurate record-keeping of all  payments."
        },
        {
            title: "Account Receivables Management:",
            message: "Diligent follow-ups to ensure every due amount is collected."
        },
        {
            title: "Denial Management & Appeals:",
            message: "Tackling claim denials head-on and appealing for rightful reimbursements."
        },
        {
            title: "Monthly Reports & Analytics:",
            message: "Comprehensive reporting to give you insights into your billing performance."
        }
    ]

    const benefits = [
        {
            title: "Enhanced Revenue Collection:",
            message: "Maximize your revenue with efficient billing practices.",
            color: "green"
        },
        {
            title: "Reduced Billing Errors:",
            message: "Minimize costly mistakes that can lead to denials or delays.",
            color: "green"
        },
        {
            title: "Compliance Assurance:",
            message: "Stay always updated and compliant with the latest regulations.",
            color: "green"
        },
        {
            title: "Focus on Patient Care:",
            message: "Free up your team's time to concentrate on what matters most - patient care.",
            color: "green"
        },
        {
            title: "Cost Efficiency:",
            message: "Outsourcing ensures you get the best expertise without the operational costs of an in-house team.",
            color: "green"
        }
    ]

    const introMessage1 = "Medical billing, a critical aspect of healthcare management, ensures that healthcare providers are promptly and accurately reimbursed for the services they provide. Our Medical Billing Medical Assistants specialize in processing patient data, coding medical procedures, and ensuring that claims are transmitted promptly to insurance companies. The significance of precise and timely medical billing in healthcare is immeasurable - it guarantees consistent revenue flow, maintains patient trust, and ensures that medical professionals can focus on patient care without financial disruptions."
    const introMessage2 = "Our commitment to excellence is reflected in our rigorous training processes. Every Medical Billing Medical Assistant at Honest Taskers undergoes intensive training, ensuring they're updated on the latest billing practices. Furthermore, our team boasts certifications from renowned institutions, and we emphasize continuous learning to always stay ahead in the rapidly evolving world of medical billing."
    const pricingTitle = "We offer a range of tailored packages to cater to diverse practice<br> sizes and requirements. From basic to advanced, our solutions<br> are designed to provide unmatched value and efficiency."

    const meta_title = "Efficient Virtual Medical Billing Assistants | Honest Taskers"
    const meta_description = "Streamline your billing with Honest Taskers’ Virtual Medical Billing Assistants. Achieve over 60% savings on costs and enhance your practice's financial health. Contact us now!"
    const meta_image = "https://honesttaskers.com/vmr_image.png"

    return (
        <div className="vmb-homepage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-medical-billing"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
            </Helmet>
            <VMedicalBillingHomeIntro onGetStarted={() => navigate('/va/request-VA')}/>
            <VMedicalBillingIntroduction 
                title={"Introduction to Medical Billing Medical Assistant:"} 
                message={introMessage1}/>
            <WhyChooseUs title="Why Choose Honest Taskers?" 
                subTitle={"At Honest Taskers, we pride ourselves on:"} 
                whyChoose={whyChooseList} className={"primary"}/>
            <VmHowItWorks howItWorkList={keyServices} title="Key Services Provided:"/>
            <Benefits subTitle={"of Outsourcing Medical Billing:"} benefits={benefits}/>
            <VMedicalBillingIntroduction 
                title={"Our Training & Certification Process:"}
                message={introMessage2}/>
            <Pricing subTitle={pricingTitle} hasColor={true} plans={getPricingPlans('pricing-two')}/>
            <GetStarted2 title={"Ready to redefine efficiency in medical billing?"}
                title1={"Dive into the future with Honest Taskers."}
                title2={"Let's Revolutionize Your Medical Billing Process"}
                btnText={"Contact Us Today!"}
                onGetStarted={() => navigate('/va/request-VA')}/>
            <Footer2 />
        </div>
    )
}