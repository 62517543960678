import { InlineWidget } from "react-calendly";
import SubmitArea from "../SubmitArea";
import './style.scss';
import { useState } from "react";

export default function BookInterview({user}) {
    const [tab, setTab] = useState(0)

    const btnEnabled = user.interview_status === 'passed' && user.contract_status === 'signed';

    return (
        <div className="interview">
            <h1>Book Interview</h1>
            <p className="mt-5">Click the link below to schedule a live interview with a member of our hiring team. This is your opportunity to showcase your skills and learn more about the role. We look forward to speaking with you!</p>
            <div className="tabs">
                <div className={`tab-item ${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>Calendar 1</div>
                <div className={`tab-item ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>Calendar 2</div>
            </div>
            <div className="calendar-wrapper">
                {
                    tab === 0 && <InlineWidget url="https://calendly.com/kristine-meht/interview-honest-taskers" />
                }
                {
                    tab === 1 && <InlineWidget url="https://calendly.com/kristine-meht/interview-honest-taskers-2" />
                }
            </div>
            <SubmitArea disabled={!btnEnabled}/>
        </div>
    )
}