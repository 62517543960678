import React from "react";
// import { useDispatch } from "react-redux";
import { Benefits, FAQs, Footer2, GetStarted2, Pricing, VPCareHomeIntro, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
// import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function VPCareHomepage() {
    // const dispatch = useDispatch()
    const navigate = useNavigate()

    const benefits = [
        {
            title: "Enhanced Patient Experience:",
            message: "Provide a smooth and personalized journey for every patient.",
            color: "green"
        },
        {
            title: "Operational Efficiency:",
            message: "Reduce delays, miscommunications, and administrative hiccups.",
            color: "green"
        },
        {
            title: "Optimal Resource Utilization:",
            message: "Ensure effective scheduling and resource allocation.",
            color: "green"
        },
        {
            title: "Improved Communication:",
            message: "Bridge the gap between patients, doctors, and other healthcare professionals.",
            color: "green"
        },
        {
            title: "Cost Savings:",
            message: "By optimizing processes, cut down on administrative overheads.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: "Patient Onboarding:",
            message: "Welcome and orient new patients, guiding them through processes and expectations."
        },
        {
            title: "Scheduling:",
            message: "Coordinating appointments, tests, and procedures while avoiding overlaps and ensuring resource availability."
        },
        {
            title: "Follow-ups:",
            message: "Conducting post-visit checks to gauge patient health and satisfaction."
        },
        {
            title: "Liaising:",
            message: "Act as the main point of contact between the patient and the healthcare team."
        },
        {
            title: "Documentation:",
            message: "Maintaining accurate patient records and updating any changes in patient health or treatment plans."
        }
    ]

    const whyChooseList = [
        {
            title: "Trained Professionals:",
            message: "Our coordinators are adept in medical processes and customer service, ensuring both efficiency and patient satisfaction."
        },
        {
            title: "Technology Integration:",
            message: "We utilize the latest digital tools to streamline tasks and foster clear communication."
        },
        {
            title: "Customizable Solutions:",
            message: "We adapt our services to match the unique needs of each healthcare institution."
        },
        {
            title: "HIPAA Compliance:",
            message: "Patient confidentiality is our top priority. We stringently uphold data protection regulations."
        },
        {
            title: "Versatile Handling:",
            message: "Our team is trained to support diverse medical specialties and patient needs."
        }
    ]

    const faqs = [
        {
            question: "How do your care coordinators handle emergencies or urgent changes?",
            answer: "Our coordinators are trained to prioritize and respond swiftly to emergencies, ensuring quick communication with the relevant medical team."
        },
        {
            question: "Are your services compatible with our existing patient management systems?",
            answer: "Yes, our team integrates seamlessly with most patient management systems, ensuring minimal disruption to your operations."
        },
        {
            question: "Can coordinators handle specialized care plans for chronic patients?",
            answer: "Absolutely. Our coordinators are equipped to manage specialized care plans and coordinate closely with doctors for chronic care patients."
        },
        {
            question: "How do you maintain the quality of service?",
            answer: "We conduct regular reviews, training sessions, and feedback loops with our clients to ensure consistent high quality."
        }
    ]

    const meta_title = "Remote Patient Care Coordinators | Save 60% - Honest Taskers"
    const meta_description = "Enhance your healthcare service with Honest Taskers’ Remote Patient Care Coordinators. Achieve efficient patient management and over 60% in cost savings. Connect with us today!"
    const meta_image = "https://honesttaskers.com/vmr_image.png"

    return (
        <div className="vp-care-hompage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-patient-care-coordinator"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <VPCareHomeIntro onGetStarted={() => navigate('/va/request-VA')}/>
            <Benefits subTitle={"of Remote Patient Care Coordinator"} benefits={benefits}/>
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities of Virtual Patient Care Coordinator"}/>
            <WhyChooseUs subTitle={"HonestTaskers' Remote Patient Care Coordinators?"} whyChoose={whyChooseList}/>
            <FAQs faqs={faqs}/>
            <Pricing hasColor={true} plans={getPricingPlans('no-custom', false, true)} btnText="Contact Us For Pricing"/>
            <GetStarted2 title={"Step into the Future of Streamlined Patient Care."}
                btnText="Engage a Patient Care Coordinator Today!"
                onGetStarted={() => navigate('/va/request-VA')}/>
            <Footer2 />
        </div>
    )
}