import { 
    DELETE_CONTENT,
    DELETE_CONTENT_FAIL,
    DELETE_CONTENT_SUCCESS,
    EDIT_CERTIFICATE,
    EDIT_CERTIFICATE_FAIL,
    EDIT_CERTIFICATE_SUCCESS,
    EDIT_EXPERIENCE,
    EDIT_EXPERIENCE_FAIL,
    EDIT_EXPERIENCE_SUCCESS,
    EDIT_SKILL,
    EDIT_SKILL_FAIL,
    EDIT_SKILL_SUCCESS,
    GET_JOBS,
    GET_JOBS_FAIL,
    GET_JOBS_SUCCESS,
    GET_LOCATIONS,
    GET_LOCATIONS_FAIL,
    GET_LOCATIONS_SUCCESS,
    GET_PROFILE_CONTENT,
    GET_PROFILE_CONTENT_FAIL,
    GET_PROFILE_CONTENT_SUCCESS,
    GET_QUIZ_QUESTIONS,
    GET_QUIZ_QUESTIONS_SUCCESS,
    GET_TEST_QUESTIONS,
    GET_TEST_QUESTIONS_SUCCESS,
    GET_USER,
    GET_USER_FAIL,
    GET_USER_SUCCESS,
    LOGIN,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    SAVE_CERTIFICATE,
    SAVE_CERTIFICATE_FAIL,
    SAVE_CERTIFICATE_SUCCESS,
    SAVE_EXPERIENCE,
    SAVE_EXPERIENCE_FAIL,
    SAVE_EXPERIENCE_SUCCESS,
    SAVE_INFO,
    SAVE_INFO_FAIL,
    SAVE_INFO_SUCCESS,
    SAVE_SKILL,
    SAVE_SKILL_FAIL,
    SAVE_SKILL_SUCCESS,
    SAVE_TAGS,
    SAVE_TAGS_FAIL,
    SAVE_TAGS_SUCCESS,
    SAVE_TOOLS,
    SAVE_TOOLS_FAIL,
    SAVE_TOOLS_SUCCESS,
    SET_CERTIFICATE,
    SET_EXPERIENCE,
    SET_LOCATIONS,
    SET_PROFILE,
    SET_QUESTION,
    SET_SKILL,
    SET_USER,
    SIGNUP,
    SIGNUP_FAIL,
    SIGNUP_SUCCESS,
    TOGGLE_ATTENTION_TEST_MODAL,
    TOGGLE_CERTIFICIATION_MODAL,
    TOGGLE_EXPERIENCE_MODAL,
    TOGGLE_QUIZ_MODAL,
    TOGGLE_SKILL_FORM_MODAL,
    UPDATE_PROFILE,
    UPDATE_PROFILE_FAIL,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_SIGNUP_STEP
 } from "../Type"


const INITIAL = {
    showExperienceModal: false,
    showCertificationModal: false,
    showSkillModal: false,
    signupStep: 1,
    saveInfo_loading: false,
    saveInfo_success: false,
    user: null,
    token: null,
    signup_loading: false,
    signup_success: false,
    getUser_loading: false,
    getUser_success: false,
    profile: null,
    getProfile_loading: false,
    getProfile_success: false,
    saveExperience_loading: false,
    saveExperience_success: false,
    saveCertificate_loading: false,
    saveCertificate_success: false,
    experience: null,
    certificate: null,
    skill: null,
    saveSkill_loading: false,
    saveSkill_success: false,
    deleteContent_loading: false,
    deleteContent_success: false,
    saveTools_loading: false, 
    saveTools_success: false,
    saveTags_loading: false,
    saveTags_success: false,
    updateProfile_loading: false, 
    updateProfile_success: false,
    // attention test
    test_questions: null,
    testQuestions_loading: false,
    showAttentionTest: false,
    currentQuestion: null,
    // quiz
    quiz_questions: null,
    quizQuestions_loading: false,
    showQuizModal: false,
    locations: null,
    getLocations_loading: false,
    getLocations_success: false,
    getJobs_loading: false,
    getJobs_success: false,
    jobs: null
}

export default (state = INITIAL, action) => {
    switch(action.type) {
        case TOGGLE_EXPERIENCE_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showExperienceModal: show,
                experience: show === true ? state.experience : null
            }
        }
        case TOGGLE_CERTIFICIATION_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showCertificationModal: show,
                certificate: show === true ? state.certificate : null
            }
        }
        case TOGGLE_SKILL_FORM_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showSkillModal: show,
                skill: show === true ? state.skill : null
            }
        }
        case TOGGLE_ATTENTION_TEST_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showAttentionTest: show,
                currentQuestion: show ? (state.test_questions && state.test_questions.length > 0 ? (state.test_questions)[0]: null) : null
            }
        }
        case TOGGLE_QUIZ_MODAL: {
            const { show } = action.data
            return {
                ...state,
                showQuizModal: show,
                currentQuestion: show ? (state.quiz_questions && state.quiz_questions.length > 0 ? (state.quiz_questions)[0]: null) : null
            }
        }
        case UPDATE_SIGNUP_STEP: {
            const { step } = action.data
            console.log(step)
            return {
                ...state,
                signupStep: step,
                saveInfo_success: false
            }
        }
        case SAVE_INFO: {
            return {
                ...state,
                saveInfo_loading: true,
                saveInfo_success: false
            }
        }
        case SAVE_INFO_SUCCESS: {
            sessionStorage.setItem("user", JSON.stringify(action.data))
            return {
                ...state,
                saveInfo_loading: false,
                saveInfo_success: true,
                user: action.data,
            }
        }
        case SAVE_INFO_FAIL: {
            return {
                ...state,
                saveInfo_loading: false,
                saveInfo_success: false
            }
        }
        case SIGNUP: {
            return {
                ...state,
                signup_loading: true,
                signup_success: false
            }
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                signup_loading: false,
                signup_success: true,
                user: action.data.user.data,
                token: action.data.token
            }
        }
        case SIGNUP_FAIL: {
            return {
                ...state,
                signup_loading: false,
                signup_success: false
            }
        }
        case LOGIN: {
            return {
                ...state,
                signup_loading: true,
                signup_success: false
            }
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                signup_loading: false,
                signup_success: true,
                user: action.data.user.data,
                token: action.data.token
            }
        }
        case LOGIN_FAIL: {
            return {
                ...state,
                signup_loading: false,
                signup_success: false
            }
        }
        case GET_USER: {
            return {
                ...state,
                getUser_loading: true,
                getUser_success: false
            }
        }
        case GET_USER_SUCCESS: {
            return {
                ...state,
                user: action.data,
                signupStep: action.data.step,
                getUser_loading: false,
                getUser_success: true,
            }
        }
        case GET_USER_FAIL: {
            return {
                ...state,
                getUser_loading: false,
                getUser_success: false,
            }
        }
        case SET_USER: {
            return {
                ...state,
                user: action.data,
                // signupStep: action.data.step
            }
        }
        case GET_PROFILE_CONTENT: {
            return {
                ...state,
                getProfile_loading: true,
                getProfile_success: false
            }
        }
        case GET_PROFILE_CONTENT_SUCCESS: {
            sessionStorage.setItem("profile", JSON.stringify(action.data))
            return {
                ...state,
                getProfile_loading: false,
                getProfile_success: true,
                profile: action.data,
                signupStep: state.user ? state.user.step : 1
            }
        }
        case GET_PROFILE_CONTENT_FAIL: {
            return {
                ...state,
                getProfile_loading: false,
                getProfile_success: false
            }
        }
        case SET_PROFILE: {
            return {
                ...state,
                profile: action.data,
                // signupStep: state.user ? state.user.step : 1
            }
        }

        // Save experience
        case SAVE_EXPERIENCE: {
            return {
                ...state,
                saveExperience_loading: true,
                saveExperience_success: false,
                showExperienceModal: false,
            }
        }
        case SAVE_EXPERIENCE_SUCCESS: {
            let experienceList = state.profile.experience
            experienceList.unshift(action.data)
            return {
                ...state,
                showExperienceModal: false,
                profile: {...state.profile, experience: experienceList },
                saveExperience_loading: false,
                saveExperience_success: true,
                experience: null,
            }
        }
        case SAVE_EXPERIENCE_FAIL: {
            return {
                ...state,
                saveExperience_loading: false,
                saveExperience_success: false
            }
        }
        case SET_EXPERIENCE: {
            return {
                ...state,
                showExperienceModal: true, 
                experience: action.data
            }
        }

        // Edit experience
        case EDIT_EXPERIENCE: {
            return {
                ...state,
                showExperienceModal: false,
                saveExperience_loading: false
            }
        }
        case EDIT_EXPERIENCE_SUCCESS: {
            let updatedObj = action.data
            let list = state.profile.experience
            list = list.map(obj => obj.id === updatedObj.id ? updatedObj : obj)
            return {
                ...state,
                showExperienceModal: false,
                profile: {...state.profile, experience: list },
                saveExperience_loading: false,
                saveExperience_success: true,
                experience: null
            }
        }
        case EDIT_EXPERIENCE_FAIL: {
            return {
                ...state,
                saveExperience_loading: false,
                saveExperience_success: false,
                showExperienceModal: true
            }
        }

        // Save certificate
        case SAVE_CERTIFICATE: {
            return {
                ...state,
                showCertificationModal: false,
                saveCertificate_loading: true,
                saveCertificate_success: false,
            }
        }
        case SAVE_CERTIFICATE_SUCCESS: {
            let certList = state.profile.certificates
            certList.unshift(action.data)
            return {
                ...state,
                showCertificationModal: false, 
                profile: {...state.profile, certificates: certList },
                saveCertificate_loading: false,
                saveCertificate_success: true,
                certificate: null
            }
        }
        case SAVE_CERTIFICATE_FAIL: {
            return {
                ...state,
                saveCertificate_loading: false,
                saveCertificate_success: false,
            }
        }
        case SET_CERTIFICATE: {
            return {
                ...state,
                certificate: action.data,
                showCertificationModal: true
            }
        }

        // Edit certificate
        case EDIT_CERTIFICATE: {
            return {
                ...state,
                showCertificationModal: false,
                saveCertificate_loading: true,
                saveCertificate_success: false
            }
        }
        case EDIT_CERTIFICATE_SUCCESS: {
            let updatedObj = action.data
            let list = state.profile.certificates
            list = list.map(obj => obj.id === updatedObj.id ? updatedObj : obj)
            return {
                ...state,
                showCertificationModal: false,
                profile: {...state.profile, certificates: list },
                saveCertificate_loading: false,
                saveCertificate_success: true,
                certificate: null
            }
        }
        case EDIT_CERTIFICATE_FAIL: {
            return {
                ...state,
                saveCertificate_loading: false,
                saveCertificate_success: false,
                showCertificationModal: true
            }
        }
        case SAVE_SKILL: {
            return {
                ...state,
                showSkillModal: false,
                saveSkill_loading: true, 
                saveSkill_success: false
            }
        }
        case SAVE_SKILL_SUCCESS: {
            let list = state.profile.user_skills
            list.unshift(action.data)
            return {
                ...state,
                showSkillModal: false,
                profile: {...state.profile, user_skills: list },
                saveSkill_loading: false, 
                saveSkill_success: true,
                skill: null
            }
        }
        case SAVE_SKILL_FAIL: {
            return {
                ...state,
                showSkillModal: true,
                saveSkill_loading: false, 
                saveSkill_success: false
            }
        }
        case EDIT_SKILL: {
            return {
                ...state,
                showSkillModal: false,
                saveSkill_loading: true, 
                saveSkill_success: false
            }
        }
        case EDIT_SKILL_SUCCESS: {
            let updatedObj = action.data
            let list = state.profile.user_skills
            list = list.map(obj => obj.id === updatedObj.id ? updatedObj : obj)
            return {
                ...state,
                showSkillModal: false,
                profile: {...state.profile, user_skills: list },
                saveSkill_loading: false, 
                saveSkill_success: true,
                skill: null
            }
        }
        case EDIT_SKILL_FAIL: {
            return {
                ...state,
                showSkillModal: true,
                saveSkill_loading: false, 
                saveSkill_success: false
            }
        }
        case SET_SKILL: {
            return {
                ...state,
                skill: action.data,
                showSkillModal: true
            }
        }
        case DELETE_CONTENT: {
            return {
                ...state,
                deleteContent_loading: true,
                deleteContent_success: false
            }
        }
        case DELETE_CONTENT_SUCCESS: {
            const { id, type } = action.data
            console.log(id, type)
            if(type === 'experience') {
                let list = state.profile.experience
                return {
                    ...state,
                    profile: { ...state.profile, experience: list.filter(item => item.id !== parseInt(id)) },
                    deleteContent_loading: false,
                    deleteContent_success: true
                }
            } else if(type === 'certification') {
                let list = state.profile.certificates
                return {
                    ...state,
                    profile: { ...state.profile, certificates: list.filter(item => item.id !== parseInt(id)) },
                    deleteContent_loading: false,
                    deleteContent_success: true
                }
            } else if(type === 'skill') {
                let list = state.profile.user_skills
                return {
                    ...state,
                    profile: { ...state.profile, user_skills: list.filter(item => item.id !== parseInt(id)) },
                    deleteContent_loading: false,
                    deleteContent_success: true
                }
            }
            
        }
        case DELETE_CONTENT_FAIL: {
            return {
                ...state,
                deleteContent_loading: false,
                deleteContent_success: false
            }
        }
        case SAVE_TOOLS: {
            return {
                ...state,
                saveTools_loading: true,
                saveTools_success: false
            }
        }
        case SAVE_TOOLS_SUCCESS: {
            return {
                ...state,
                profile: { ...state.profile, user_tools: action.data },
                saveTools_loading: false,
                saveTools_success: true
            }
        }
        case SAVE_TOOLS_FAIL: {
            return {
                ...state,
                saveTools_loading: false,
                saveTools_success: false
            }
        }
        case SAVE_TAGS: {
            return {
                ...state,
                saveTags_loading: true,
                saveTags_success: false
            }
        }
        case SAVE_TAGS_SUCCESS: {
            return {
                ...state,
                profile: { ...state.profile, user_tags: action.data },
                saveTags_loading: false,
                saveTags_success: true
            }
        }
        case SAVE_TAGS_FAIL: {
            return {
                ...state,
                saveTags_loading: false,
                saveTags_success: false
            }
        }
        case UPDATE_PROFILE: {
            return {
                ...state,
                updateProfile_loading: true,
                updateProfile_success: false
            }
        }
        case UPDATE_PROFILE_SUCCESS: {
            sessionStorage.setItem("user", JSON.stringify(action.data))
            return {
                ...state,
                user: action.data,
                updateProfile_loading: false,
                updateProfile_success: true
            }
        }
        case UPDATE_PROFILE_FAIL: {
            return {
                ...state,
                updateProfile_loading: false,
                updateProfile_success: false
            }
        }
        case GET_TEST_QUESTIONS: {
            return {
                ...state,
                test_questions: null,
                testQuestions_loading: true
            }
        }
        case GET_TEST_QUESTIONS_SUCCESS: {
            return {
                ...state,
                test_questions: action.data.questions,
                currentQuestion: (action.data.questions)[0],
                testQuestions_loading: false
            }
        }
        case SET_QUESTION: {
            return {
                ...state,
                currentQuestion: action.data
            }
        }
        case GET_QUIZ_QUESTIONS: {
            return {
                ...state,
                quiz_questions: null,
                quizQuestions_loading: true
            }
        }
        case GET_QUIZ_QUESTIONS_SUCCESS: {
            return {
                ...state,
                quiz_questions: action.data.questions,
                currentQuestion: (action.data.questions)[0],
                quizQuestions_loading: false
            }
        }
        case GET_LOCATIONS: {
            return {
                ...state,
                locations: null,
                getLocations_loading: true,
                getLocations_success: false
            }
        }
        case GET_LOCATIONS_SUCCESS: {
            return {
                ...state,
                locations: action.data,
                getLocations_loading: false,
                getLocations_success: true
            }
        }
        case GET_LOCATIONS_FAIL: {
            return {
                ...state,
                getLocations_loading: false,
                getLocations_success: false
            }
        }
        case SET_LOCATIONS: {
            return {
                ...state,
                locations: action.data
            }
        }
        case GET_JOBS: {   
            return {
                ...state,
                jobs: null,
                getJobs_loading: true,
                getJobs_success: false
            }
        }
        case GET_JOBS_SUCCESS: {
            return {
                ...state,
                jobs: action.data,
                getJobs_loading: false,
                getJobs_success: true
            }
        }
        case GET_JOBS_FAIL: {  
            return {
                ...state,
                getJobs_loading: false,
                getJobs_success: false
            }
        }
        default: 
            return state
    }
}