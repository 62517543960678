import { DELETE_CONTENT, EDIT_CERTIFICATE, EDIT_EXPERIENCE, EDIT_SKILL, GET_JOBS, GET_LOCATIONS, GET_PROFILE_CONTENT, GET_QUIZ_QUESTIONS, GET_TEST_QUESTIONS, GET_USER, LOGIN, SAVE_CERTIFICATE, SAVE_EXPERIENCE, SAVE_INFO, SAVE_SKILL, SAVE_TAGS, SAVE_TOOLS, SIGNUP, TOGGLE_ATTENTION_TEST_MODAL, TOGGLE_CERTIFICIATION_MODAL, TOGGLE_EXPERIENCE_MODAL, TOGGLE_QUIZ_MODAL, TOGGLE_SKILL_FORM_MODAL, UPDATE_PROFILE } from "../Type"

export const toggleExperienceModalAction = (show) => {
    return {
        type: TOGGLE_EXPERIENCE_MODAL,
        data: { show: show }
    }
}

export const toggleCertificationModalAction = (show) => {
    return {
        type: TOGGLE_CERTIFICIATION_MODAL,
        data: { show: show }
    }
}
export const toggleSkillModalAction = (show) => {
    return {
        type: TOGGLE_SKILL_FORM_MODAL,
        data: { show: show }
    }
}
export const toggleAttentionTestModalAction = (show) => {
    return {
        type: TOGGLE_ATTENTION_TEST_MODAL,
        data: { show: show }
    }
}
export const toggleQuizModal = (show) => {
    return {
        type: TOGGLE_QUIZ_MODAL,
        data: { show: show }
    }
}
export const savePersonalInfoAction = (data) => {
    return {
        type: SAVE_INFO,
        data: data
    }
}
export const signupAction = (data) => {
    return {
        type: SIGNUP,
        data: data
    }
}
export const loginAction = (data) => {
    return {
        type: LOGIN,
        data: data
    }
}
export const getUserAction = () => {
    return {
        type: GET_USER,
        data: {}
    }
}
export const getProfileAction = () => {
    return {
        type: GET_PROFILE_CONTENT,
        data: {}
    }
}
export const saveExperienceAction = (data) => {
    return {
        type: SAVE_EXPERIENCE,
        data: data
    }
}
export const editExperienceAction = (id, data) => {
    return {
        type: EDIT_EXPERIENCE,
        data: { id: id, data: data }
    }
}
export const saveCertificatesAction = (data) => {
    return {
        type: SAVE_CERTIFICATE,
        data: data
    }
}
export const editCertificatesAction = (id, data) => {
    return {
        type: EDIT_CERTIFICATE,
        data: { id: id, data: data }
    }
}
export const saveSkillAction = (data) => {
    return {
        type: SAVE_SKILL,
        data: data
    }
}
export const editSkillAction = (id, data) => {
    return {
        type: EDIT_SKILL,
        data: { id: id, data: data }
    }
}
export const saveToolsAction = (data) => {
    return {
        type: SAVE_TOOLS,
        data: data
    }
}
export const saveTagsAction = (data) => {
    return {
        type: SAVE_TAGS,
        data: data
    }
}
export const deleteContentAction = (id, data) => {
    return {
        type: DELETE_CONTENT,
        data: { id: id, data: data }
    }
}
export const updateProfileAction = (data) => {
    return {
        type: UPDATE_PROFILE,
        data: data
    }
}
export const getQuestionsAction = () => {
    return {
        type: GET_TEST_QUESTIONS,
        data: {}
    }
}
export const getQuizQuestionsAction = () => {
    return {
        type: GET_QUIZ_QUESTIONS,
        data: {}
    }
}
export const getLocationsAction = () => {
    return {
        type: GET_LOCATIONS,
        data: {}
    }
}
export const getJobsAction = () => {
    return {
        type: GET_JOBS,
        data: {}
    }
}