import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SelectComponent, TextEditor } from "src/Components/Common";
import { editSkillAction, saveSkillAction, toggleSkillModalAction } from "src/Redux/Actions";
import _ from 'lodash';
import { showSpinner } from "src/Utils/Helper";
import { SET_SKILL } from "src/Redux/Type";

export const SkillItem = ({item, onEdit, onDelete }) => {
    return (
        <div className="experience-item q-item">
            <div className="item-header">
                <div className="info">
                    <div className="title">{item.skill.title}</div>
                    <div className="company">{item.position.title}</div>
                </div>
                <div className="action-btns">
                    <div className="item-btn center-item" onClick={() => onEdit()}>Edit</div>
                    <div className="item-btn danger center-item" onClick={() => onDelete()}>Delete</div>
                </div>
            </div>
            <p className="description" dangerouslySetInnerHTML={{__html: item.description}}></p>
        </div>
    )
}

export const SkillsComponent = ({profile, onDelete}) => {
    const dispatch = useDispatch()
    const handleEdit = (item) => {
        dispatch({
            type: SET_SKILL,
            data: item
        })
    }
    return (
        <div className="item-list">
            <div className="sec-title">Capture your skills</div>
            <div className="btn-row">
                <div className="add-btn" onClick={() => dispatch(toggleSkillModalAction(true))}><span className="fa fa-plus me-2"></span>Add Skill</div>
            </div>
            {
                profile.user_skills.map((item, index) => (
                    <SkillItem key={index} item={item} onEdit={() => handleEdit(item)} onDelete={() => onDelete(item)}/>
                ))
            }
            {
                profile.user_skills.length === 0 && <div style={{padding:"3rem 0",textAlign:"center"}}>No Skills</div>
            }
        </div>
    )
}

export const AddSkillModal = ({show, onHide, profile, currentSkill, loading }) => {
    const dispatch = useDispatch()
    const [position, setPosition] = useState(null)
    const [skill, setSkill] = useState(null)
    const [skillOptions, setSkillOptions] = useState(null)
    const [description, setDescription] = useState('')
    const editorRef = useRef(null);
    const btnDisabled = position === null || skill === null

    useEffect(() => {
        if(currentSkill && currentSkill.id) {
            let filteredList = profile.skills.filter(item => item.position_id === currentSkill.position.id)
            setSkillOptions(filteredList.map((item) => {return { value: item.id, label: item.title } }))
            setPosition({ value: currentSkill.position.id, label: currentSkill.position.title })
            setSkill({ value: currentSkill.skill.id, label: currentSkill.skill.title })
            setDescription(currentSkill.description) 
        } else {
            setSkillOptions(profile.skills.map((item) => {return { value: item.id, label: item.title } }))
            setSkill(null)
        }
    }, [])

    const handlePosition = (selectedPosition) => {
        setSkill(null)
        let filteredList = profile.skills.filter(item => item.position_id === selectedPosition.value)
        setSkillOptions(filteredList.map((item) => {return { value: item.id, label: item.title } }))
        setPosition(selectedPosition)
    }

    const submit = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
        if((editorRef.current && editorRef.current.getContent() === '')) {
            toast("Enter description")
            return
        }
        const formdata = new FormData()
        formdata.append("position_id", position.value)
        formdata.append("skill_id", skill.value)
        formdata.append('description', editorRef.current.getContent())
        showSpinner()
        if(_.isEmpty(currentSkill)) {
            dispatch(saveSkillAction(formdata))
        } else {
            dispatch(editSkillAction(currentSkill.id, formdata))
        }
    }
    return (
        <Modal className="skill-modal modal-lg" show={show} onHide={onHide}>
            <Modal.Header>
                <div className="modal-title">Add Skills</div>
                <div className="close" onClick={onHide}>Close</div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper row">
                    <div className="form-group group-2 col-lg-12">
                        <label>Select Position</label>
                        <SelectComponent 
                            disabled={currentSkill && currentSkill.id} 
                            key={position} 
                            value={position} 
                            options={profile.positions.map((item) => {return { value: item.id, label: item.title } })} onChange={(value) => handlePosition(value)}/>
                    </div>
                    <div className="form-group group-2 col-lg-12">
                        <label>Select Skill</label>
                        <SelectComponent key={skill} value={skill} options={skillOptions} onChange={(value) => setSkill(value)}/>
                    </div>
                    <div className="form-group group-2">
                        <label>Description</label>
                        <TextEditor editorRef={editorRef} value={description}/>
                    </div>
                    <div className="modal-btns">
                        <div className={`submit-btn center-item ${btnDisabled || loading ? 'disabled' : ''}`} onClick={() => submit()}>Save</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}