import './style.scss';
import { Modal } from 'react-bootstrap';

export default function VideoModal({show, onHide, video}) {

    return (
        <Modal className="video-modal" show={show} onHide={onHide}>
            <div className='video-content'>
                <div className="close-btn" onClick={onHide}><span className='fa fa-close'></span></div>
                <iframe src={video} 
                    style={{ width:"100%", height:"600px" }}
                    frameBorder="0" 
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen></iframe>
            </div>
        </Modal>
    )
}