import './style.scss';
import logo from 'src/Assets/Images/Home/logo.svg';
import logo2 from 'src/Assets/Images/Home/logo2.png';
import { ReactSVG } from "react-svg";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [transform, setTransform] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        setTransform(window.scrollY > 0 ? true : false)
    }

    const hideButton = ['/va/request-VA', '/va/schedule-discovery-call'].includes(location.pathname)

    return (
        <div className={`adwords-header ${transform && !hideButton ? 'active': ''}`}>
            <div className='container'>
                <div className='header-content'>
                    <div className='logo-wrapper' onClick={() => navigate('/')}>
                        { transform && !hideButton ? <img src={logo2} alt='logo 2'/> : <ReactSVG src={logo} className="logo" />}
                    </div>
                    { !hideButton && <div className="header-btn" onClick={() => navigate('/va/request-VA')}>Book Discovery Call</div>}
                </div>
            </div>
        </div>
    )
}