import React, { useEffect, useState } from "react";
import { InputComponent, PhoneComponent, SelectComponent } from "src/Components/Common";
import SubmitArea from "../SubmitArea";
import '../style.scss';
import { useDispatch, useSelector } from "react-redux";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { getLocationsAction, savePersonalInfoAction } from "src/Redux/Actions";
import _ from 'lodash';
import { SET_LOCATIONS } from "src/Redux/Type";
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";

const degreeTypes = ['Bachelor', 'Master', 'Doctorate', 'PhD', 'Certification']
const genders = ['Male', 'Female']
const relationStatuses = ['Single', 'Married', 'Seperated', 'Divorced', 'Widowed'];
const languages = ['English', 'French', 'Bengali', 'Arabic', 'Hindi', 'Japanese', 'Russian', 'Portuguese', 'Mandarin Chinese', 'Spanish']

export default function PersonalInfo({user }) {
    const dispatch = useDispatch()
    const {
        saveInfo_loading,
        saveInfo_success,
        locations,
        getLocations_loading,
        getLocations_success
    } = useSelector(state => state.Auth)

    const parseDate = (dateString) => {
        const parsedDate = new Date(dateString);
        if (!isNaN(parsedDate.getTime())) { 
            const formattedDate = parsedDate.toISOString().split("T")[0]; 
            return formattedDate
        } else {
            return ""
        }
    }
    const getLanguageList = () => {
        if(user.language && user.language.length > 0) {
            let list = user.language.split(', ')
            return list.map(item => { return { value: item, label: item }})
        }
        return [];
    }
    const getRelationStatus = () => {
        if(user.relationship && user.relationship.length > 0)return { value: user.relationship, label: user.relationship }
        return null
    }
    const getDegreeType = () => {
        if(user.degree_type && user.degree_type.length > 0)return { value: user.degree_type, label: user.degree_type }
        return null
    }
    const getGender = () => {
        if(user.gender && user.gender.length > 0)return { value: user.gender, label: user.gender }
        return null
    }
    const getPhone = () => {
        if(user.phone && user.phone.length > 0) {
            if(user.phone.includes("+"))return user.phone
            return `+${user.phone}`;
        }
        return "";
    }
    const getEmergencyContact = () => {
        if(user.emergency_contact && user.emergency_contact.length > 0) {
            if(user.emergency_contact.includes("+"))return user.emergency_contact
            return `+${user.emergency_contact}`;
        }
        return "";
    }

    const [firstName, setFirstName] = useState(user.first_name ?? '')
    const [lastName, setLastName] = useState(user.last_name ?? '')
    const [title, setTitle] = useState(user.title ?? '')
    const [email, setEmail] = useState(user.email ?? '')
    const [address, setAddress] = useState(user.address ?? '');
    const [street, setStreet] = useState(user.address_street ?? '')
    const [phone, setPhone] = useState(getPhone())
    const [zipCode, setZipcode] = useState(user.address_zipcode ?? '')
    const [qualification, setQualification] = useState(user.qualification ?? '')
    const [university, setUniversity] = useState(user.institution ?? '')
    const [country, setCountry] = useState(null)
    const [city, setCity] = useState(null)
    const [state, setState] = useState(null)
    const [birthday, setBirthday] = useState(user.date_of_birth === '' ? '' : parseDate(user.date_of_birth))
    const [degreeType, setDegreeType] = useState(getDegreeType())
    const [relationship, setRelationship] = useState(getRelationStatus())
    const [emergencyPhone, setEmergencyPhone] = useState(getEmergencyContact())
    const [emergencyName, setEmergencyName] = useState(user.emergency_contact_name ?? '')
    const [emergencyRelation, setEmergencyRelation] = useState(user.emergency_contact_relation ?? '')
    const [language, setLanguage] = useState(getLanguageList())
    const [gender, setGender] = useState(getGender())
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const savePersonalInfo = () => {
        if(phone && (isPossiblePhoneNumber(phone) !== true || isValidPhoneNumber(phone) !== true)) {
            toast("The phone number entered is not valid")
            return;
        }
        if(emergencyPhone && (isPossiblePhoneNumber(emergencyPhone) !== true || isValidPhoneNumber(emergencyPhone) !== true)) {
            toast("The emergency phone number entered is not valid")
            return;
        }
        const formData = new FormData()
        formData.append('first_name', firstName)
        formData.append('last_name', lastName)
        formData.append('title', title)
        formData.append('email', email)
        formData.append('phone', phone)
        // formData.append('address', address)
        formData.append('address_street', street)
        formData.append('address_zipcode', zipCode)
        formData.append('qualification', qualification)
        formData.append('institution', university)
        formData.append('date_of_birth', birthday)
        formData.append('degree_type', degreeType && degreeType.value ? degreeType.value : "")
        formData.append('relationship', relationship && relationship.value ? relationship.value : "")
        formData.append('emergency_contact', emergencyPhone)
        formData.append('emergency_contact_name', emergencyName)
        formData.append('emergency_contact_relation', emergencyRelation)
        formData.append('language', language.map(item => item.value).join(', '))
        formData.append('gender', gender && gender.value ? gender.value : "")
        formData.append('city', city && city.value ? city.value : 0)
        formData.append('state', state.value)
        formData.append('country', country.value)
        showSpinner()
        dispatch(savePersonalInfoAction(formData))
    }

    useEffect(() => {
        if(_.isEmpty(locations)) {
            getLocationsFromDB().then((data) => {
                if(data && data.countries && data.countries.length > 0) {
                    console.log("loaded locations from cache")
                    dispatch({type: SET_LOCATIONS, data: data })
                    setLocations(data)
                } else {
                    showSpinner()
                    dispatch(getLocationsAction())
                }
            }).catch((error) => {
                console.log(error)
            })
        } else setLocations(locations)
    }, [])

    useEffect(() => {
        if(!getLocations_loading)hideSpinner()
        if(getLocations_success) {
            saveLocationsToDB(locations).then(result => console.log(result))
            setLocations(locations)
        }
        if(saveInfo_success) {
            sessionStorage.setItem("user", JSON.stringify(user))
        }
    }, [dispatch, saveInfo_loading, saveInfo_success, getLocations_loading, getLocations_success])

    const handleStateChange = (selected) => {
        setState(selected)
        setCity(null)
        let cities = locations.cities.filter(item => item.state_id === selected.value)
        setCities(cities.map(item => { return { value: item.id, label: item.name }}))
    }

    const handleCountrySelect = (selected) => {
        setCountry(selected)
        setState(null)
        setCity(null)
        let states = locations.states.filter(item => item.country_id === selected.value)
        setStates(states.map(item => { return { value: item.id, label: item.name }}))
    }

    const setLocations = (locations) => {
        const oldCountry = locations.countries.find(item => item.id === parseInt(user.country_id))
        setCountry(oldCountry && oldCountry.id ? { value: oldCountry.id, label: oldCountry.name } : null);
        if(oldCountry && oldCountry.id) {
            let states = locations.states.filter(item => item.country_id === parseInt(user.country_id))
            setStates(states.map(item => { return { value: item.id, label: item.name }}))
        }
        const oldState = locations.states.find(item => item.id === parseInt(user.state_id))
        setState(oldState && oldState.id ? { value: oldState.id, label: oldState.name } : null);
        if(oldState && oldState.id) {
            let cities = locations.cities.filter(item => item.state_id === parseInt(user.state_id))
            setCities(cities.map(item => { return { value: item.id, label: item.name }}))
        }
        const oldCity = locations.cities.find(item => item.id === parseInt(user.city_id))
        setCity(oldCity && oldCity.id ? { value: oldCity.id, label: oldCity.name } : null);
    }

    const submitDisabled = firstName === '' || email === '' || title === '' || lastName === '' || qualification === '' || university === '' || street === '' || degreeType === '' || birthday === '' || country === null || state === null

    const continueDisabled = _.isEmpty(user.first_name) || _.isEmpty(user.email) || _.isEmpty(user.title) || _.isEmpty(user.last_name) || _.isEmpty(user.qualification) || _.isEmpty(user.institution) || _.isEmpty(user.address_street) || _.isEmpty(user.degree_type) || _.isEmpty(user.date_of_birth) || user.country_id === 0 || user.state_id === 0

    return (
        <div className="va-personal-info">
            <h1>Enter your Personal PersonalInfo</h1>
            <div className="form-content row mt-5">
                <div className="col-lg-6">
                    <InputComponent 
                        showLabel={true}
                        label={"First Name"} 
                        placeholder={"John"} 
                        value={firstName} 
                        onChange={(value) => setFirstName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent 
                        showLabel={true}
                        label={"Last Name"} 
                        placeholder={"Doe"} 
                        value={lastName} 
                        onChange={(value) => setLastName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Email"} 
                        placeholder={""} 
                        showLabel={true}
                        value={email} 
                        type="tel"
                        onChange={(value) => setEmail(value)}/>
                </div>
                <div className="col-lg-6">
                    <PhoneComponent 
                        value={phone} 
                        placeholder="Phone"
                        showLabel={true}
                        defaultCountry={user.phone && user.phone.length > 0 ? (parsePhoneNumber(user.phone) && parsePhoneNumber(user.phone).country) : "US"}
                        onChange={(value) => setPhone(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Title"} 
                        placeholder={"US-RN Working 5+ Years As A Virtual Medical Assistant "} 
                        value={title} 
                        showLabel={true}
                        onChange={(value) => setTitle(value)}/>
                </div>
                <div className="col-lg-6">
                    {
                        locations && (
                            <SelectComponent 
                                key={country} 
                                placeholder="Country"
                                showLabel={true}
                                searchable={true}
                                value={country} 
                                options={locations.countries.map((item) => {return { value: item.id, label: item.name } })} 
                                onChange={(value) => handleCountrySelect(value)}/>
                        )
                    }
                </div>
                <div className="col-lg-6">
                    <SelectComponent 
                        key={state}
                        placeholder="State"
                        searchable={true}
                        value={state} 
                        showLabel={true}
                        options={states} 
                        onChange={(value) => handleStateChange(value)}/>
                </div>
                <div className="col-lg-6">
                    <SelectComponent 
                        key={city} 
                        placeholder="City"
                        searchable={true}
                        value={city} 
                        options={cities} 
                        showLabel={true}
                        onChange={(value) => setCity(value)}/>
                </div>
                {/* <div className="col-lg-6">
                    <InputComponent label={"Address"} 
                        placeholder={""} 
                        value={address} 
                        showLabel={true}
                        onChange={(value) => setAddress(value)}/>
                </div> */}
                <div className="col-lg-6">
                    <InputComponent label={"Street address"} 
                        placeholder={""} 
                        value={street} 
                        showLabel={true}
                        onChange={(value) => setStreet(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Zipcode/Postal Code"} 
                        placeholder={"00000"} 
                        type="number"
                        value={zipCode} 
                        showLabel={true}
                        onChange={(value) => setZipcode(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Degree"} 
                        placeholder={"e.g Nursing, Pharmacy, Medicine, Biology, Biology etc"} 
                        value={qualification} 
                        showLabel={true}
                        onChange={(value) => setQualification(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"University"} 
                        placeholder={"Texas Christian University"} 
                        value={university} 
                        showLabel={true}
                        onChange={(value) => setUniversity(value)}/>
                </div>
                <div className="col-lg-6">
                    <SelectComponent 
                        placeholder="Degree type"
                        searchable={true}
                        value={degreeType} 
                        showLabel={true}
                        options={degreeTypes.map(item => { return { value: item, label: item }})} 
                        onChange={(value) => setDegreeType(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Date of birth"} 
                        placeholder={""} 
                        type="date"
                        value={birthday} 
                        showLabel={true}
                        onChange={(value) => setBirthday(value)}/>
                </div>
                <div className="col-lg-6">
                    <SelectComponent 
                        placeholder="Relationship status"
                        searchable={true}
                        value={relationship} 
                        showLabel={true}
                        options={relationStatuses.map(item => { return { value: item, label: item }})} 
                        onChange={(value) => setRelationship(value)}/>
                </div>
                <div className="col-lg-6">
                    <SelectComponent 
                        placeholder="Gender"
                        value={gender} 
                        showLabel={true}
                        options={genders.map(item => { return { value: item, label: item }})} 
                        onChange={(value) => setGender(value)}/>
                </div>
                <div className="col-lg-6">
                    <SelectComponent 
                        placeholder="Languages"
                        searchable={true}
                        multiple={true}
                        value={language} 
                        showLabel={true}
                        options={languages.map(item => { return { value: item, label: item }})} 
                        onChange={(value) => setLanguage(value)}/>
                </div>
                <div className="col-lg-6">
                    <PhoneComponent 
                        value={emergencyPhone} 
                        placeholder="Emergency Contact"
                        showLabel={true}
                        defaultCountry={user.emergency_contact && user.emergency_contact.length > 0 ? (parsePhoneNumber(user.emergency_contact) && parsePhoneNumber(user.emergency_contact).country) : "US"}
                        onChange={(value) => setEmergencyPhone(value)} />
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"Emergency Contact Full Name"} 
                        placeholder={""} 
                        type="tel"
                        showLabel={true}
                        value={emergencyName} 
                        onChange={(value) => setEmergencyName(value)}/>
                </div>
                <div className="col-lg-6">
                    <InputComponent label={"How are you related to emergency contact: "} 
                        placeholder={""} 
                        type="tel"
                        showLabel={true}
                        value={emergencyRelation} 
                        onChange={(value) => setEmergencyRelation(value)}/>
                </div>
                <div className="d-f j-c">
                `<div className={`save-btn center my-5 ${submitDisabled ? 'disabled': ''}`} onClick={() => savePersonalInfo()}>Save Personal Info</div>
            </div>`
            </div>
            <SubmitArea disabled={continueDisabled}/>
        </div>
    )
}

function openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("HonestTaskersDB", 2);
  
      request.onupgradeneeded = function(event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("locationData")) {
          db.createObjectStore("locationData", { keyPath: "id" });
        }
      };
  
      request.onsuccess = function(event) {
        resolve(event.target.result);
      };
  
      request.onerror = function(event) {
        indexedDB.deleteDatabase("HonestTaskersDB")
        saveLocationsToDB(null)
        console.log("Could not open db, old db deleted")
        reject(event.target.error);
      };
    });
  }

async function getLocationsFromDB() {
    const db = await openDatabase();
    return await new Promise((resolve, reject) => {
        try {
            if (db.objectStoreNames.contains("locationData")) {
                const transaction = db.transaction("locationData", "readonly");
                const store = transaction.objectStore("locationData");
                const getRequest = store.get("locations");

                getRequest.onsuccess = function () {
                    if (getRequest.result) {
                        resolve(JSON.parse(getRequest.result.json));
                    } else {
                        resolve(null);
                    }
                };
                getRequest.onerror = function () {
                    reject("Error retrieving data");
                };
            } else {
                console.error("Object store 'locationData' not found.");
                indexedDB.deleteDatabase("HonestTaskersDB")
                resolve(null);
            }
        } catch (error) {
            console.log(error);
            resolve(null);
        }
    });
}

async function saveLocationsToDB(largeDataObject) {
    const db = await openDatabase();
    return await new Promise((resolve, reject) => {
        if (db.objectStoreNames.contains("locationData")) {
            const transaction = db.transaction("locationData", "readwrite");
            const store = transaction.objectStore("locationData");
            const data = {
                id: "locations",
                json: JSON.stringify(largeDataObject) // Your large JSON object
            };
            store.put(data);
            transaction.oncomplete = () => resolve("Locations stored successfully!");
        } else {
            console.log("Object store 'locationData' does not exist.");
            reject(null);
        }
    });
} 